import axios from "axios";

import { api } from "../Api";
import { deviceConfigApiUrl } from "Enviroment";
import { ISalesByDayReportService } from "modules/SaleForDayPage/domain/interface/ISalesByDayReportService";
import { IGetEquipmentsResponse } from "modules/SaleForDayPage/domain/dto/IGetEquipmentsResponse";
import { IGetPosResponse } from "modules/SaleForDayPage/domain/dto/IGetPosResponse";
import { IGetSalesByDaysResponse } from "modules/SaleForDayPage/domain/dto/IGetSalesByDayResponse";

const SalesByDayReportApi = (): ISalesByDayReportService => {

  const getEquipments = async (localId: string): Promise<IGetEquipmentsResponse[]> => {
    const response = await api.get(`/Proprietario/BuscarEquipamentosQueJaTrabalharamNoLocal/${localId}`);
    return response.data;
  }

  const getPos = async (localId: string, params: any): Promise<IGetPosResponse> => {
    const response = await axios.get(`${deviceConfigApiUrl}/locals/${localId}/profiles`, { params });
    return response.data;
  }

  const getSalesByDay = async (payload: any): Promise<IGetSalesByDaysResponse> => {
    const response = await api.post('/Relatorio/VendasPorDia', payload);
    return response.data;
  }

  return {
    getEquipments,
    getPos,
    getSalesByDay,
  };
}

export default SalesByDayReportApi;
