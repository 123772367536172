import { FC, Fragment, RefObject, useCallback, useMemo, useState } from "react";
import styles from "./Menu.module.scss";
import MenuItem from "./menuItem/MenuItem";
import packageJson from "../../../../../package.json";
import UseDimension from "components/dimension/UseDimension";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import { ExitToApp } from "@mui/icons-material";
import { useAuth } from "modules/auth/presentation/context/AuthContext";
import { useMenuContext } from "./context/MenuContext";
import { Icon } from "@material-ui/core";
import { Skeleton } from "components/skeleton/Skeleton";
import SimpleTrialModal from "modules/plans/presentation/components/simpleTrialModal/SimpleTrialModal";
import { IconButton } from "@mui/material";

export interface IMenuProps {
  opened?: boolean;
  onOpenItem?: () => void;
  setOpenMenu?: React.Dispatch<React.SetStateAction<boolean>>;
  onLoadFirstItem?: (divRef:RefObject<HTMLDivElement>) => void;
  isSaasSimpleRegister?: boolean;
}

const Menu: FC<IMenuProps> = ({ opened, onOpenItem, setOpenMenu, onLoadFirstItem, isSaasSimpleRegister = false }) => {
  const { logout } = useAuth();
  const { isMobile } = UseDimension();
  const { menuPosition, setMenuPosition, menuListContext, menuLoading } = useMenuContext();

  const menuList = useMemo(() => menuListContext ?? [], [menuListContext]);

  const { push } = useHistory();
  const { path } = useRouteMatch();
  const { listId, guestListId } = useParams<{ listId: string, guestListId: string }>();

  const [openSimpleTrialModal, setOpenSimpleTrialModal] = useState(false);

  const onClickRoute = useCallback(
    (route: string, title?: string) => {
      if (isSaasSimpleRegister && title === "Fiscal") {
        return setOpenSimpleTrialModal(true);
      }
      if (menuPosition === 1 && path.includes("private/event/") && title?.toLowerCase() !== "favoritos") {
        if (listId) {
          return push(`${route.trim()}/${listId}`);
        }
        if (guestListId) {
          return push(`${route.trim()}/${guestListId}`);
        }
        const eventId = localStorage.getItem("@eventId");

        if (route === "/private/event/dashboard/Customer" && eventId) {
          return push(`${route.trim()}/${eventId}`);
        }

        return push(`${route.trim()}/${eventId}`);
      }
      push(route.trim());
    },
    [isSaasSimpleRegister, menuPosition, path, push, listId, guestListId]
  );

  return (
    <>
      <div id={styles.adminMenu}>
        <div>
          {menuPosition === 1 && opened && (
            <IconButton
              className={styles.backLocalMenu}
              onClick={() => {
                setMenuPosition(2);
                push("/private/eventos/list");
              }}
            >
              <Icon fontSize="medium">keyboard_arrow_left</Icon>
              <span className={styles.backLocalMenuText}>Voltar</span>
            </IconButton>
          )}
          {menuLoading
            ? <Skeleton items={15} cardStyle={styles.skeletonMenu} />
            : menuList.map((menuItem, index) => (
              <Fragment key={menuItem.id}>
                {
                  <MenuItem
                    key={menuItem.id}
                    opened={opened}
                    onOpen={onOpenItem}
                    menuItem={menuItem}
                    onClickRoute={onClickRoute}
                    setOpenMenu={setOpenMenu}
                    onLoad={menuItem.title.toLowerCase().includes("vender agora") ? onLoadFirstItem : undefined}
                  />
                }
              </Fragment>
            ))}
          {isMobile && (
            <div className={styles.logout}>
              <span onClick={logout}>
                <ExitToApp /> Sair
              </span>
            </div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            opacity: 0.8,
            color: "#333",
            padding: "10px 0px 20px",
            fontSize: "0.75rem",
          }}
        >
          v{packageJson.version}
        </div>
      </div>

      <SimpleTrialModal open={openSimpleTrialModal} onClose={() => setOpenSimpleTrialModal(false)} />
    </>
  );
};

export default Menu;
