import { useCallback, useEffect, useState } from "react";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import GetLocalMainDataUseCase from "modules/config/integrationsConfig/application/useCases/GetLocalMainDataUseCase";
import IntegrationApi from "services/api/config/integration/IntegrationApi";
import { usePlans } from "modules/plans/presentation/hooks/PlansContext";

const integrationService = IntegrationApi();

export const UseSaasAlert = (checkLocalDocument?: boolean) => {
    const { currentLocal } = useLocal();
    const { subscription } = usePlans();

    const [localDocument, setLocalDocument] = useState("");

    const getLocalMainData = useCallback(async () => {
        if (currentLocal) {
          const response = await GetLocalMainDataUseCase(integrationService, currentLocal.id);
          setLocalDocument(response.document);
        }
    }, [currentLocal]);

    useEffect(() => {
        if (subscription?.isTrial || checkLocalDocument) {
            getLocalMainData();
        }
    }, [checkLocalDocument, getLocalMainData, subscription?.isTrial]);

    return {
        localDocument
    }
}
