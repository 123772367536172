import { FC, useEffect } from 'react'
import styles from './ProfilePage.module.scss'
import { TextField } from '@material-ui/core'
import { UseProfilePage } from './UseProfilePage'
import InputMasked from 'components/inputMasked/InputMasked'
import { UseBackPage } from '../../hooks/UseBackPage'
import SaasPublicLayout from '../SaasPublicLayout'
import UseProspect from '../../hooks/UseProspect'
import { ProspectType } from 'modules/saas/domain/interfaces/ProspectTypeEnum'
import { IProfileSchema, profileSchema } from 'modules/saas/domain/schema/profileSchema'
import { zodResolver } from '@hookform/resolvers/zod'
import { Controller, useForm } from 'react-hook-form'
import Button from 'components/ui/Button/Button'
import { CircularProgress } from '@mui/material'

export interface IProfilePageProps {
    //propertys
}

const ProfilePage: FC<IProfilePageProps> = () => {
    const {
        isLoading,
        defaultValues,
        blockForm,
        progressBarColor,
        backgroundColorTop,
        backgroundColorBottom,
        handleSubmit: submitForm
    } = UseProfilePage();
    const { onBack } = UseBackPage();
    const { type } = UseProspect();

    const { register, handleSubmit, formState: { errors }, setValue, control, clearErrors, setError } = useForm<IProfileSchema>({
        resolver: zodResolver(profileSchema)
    });

    useEffect(() => {
        setValue("name", defaultValues.name ?? "");
        setValue("document", defaultValues.document ?? "");
        setValue("document", defaultValues.document ?? "");
        setValue("birthday", defaultValues.birthday ?? "");
        setValue("phoneNumber", defaultValues.phoneNumber ?? "");
    }, [defaultValues, setValue]);

    return (
        <SaasPublicLayout
            step={type === ProspectType.CieloEvent || type === ProspectType.CieloEstablishment ? 4 : 3}
            totalSteps={type === ProspectType.CieloEvent || type === ProspectType.CieloEstablishment ? 6 : 5}
            progressBarColor={progressBarColor}
            backgroundColorTop={backgroundColorTop}
            backgroundColorBottom={backgroundColorBottom}
        >
            <div className={styles.container}>
                <div className={styles.header}>
                    <h1>Nos conte um pouco sobre você</h1>
                    <span>Não se preocupe, seus dados estarão seguros!</span>
                </div>

                <form
                    onSubmit={handleSubmit((data) => submitForm(data, clearErrors, setError))}
                >
                    <div className={styles.column}>
                        <label htmlFor="name" className={styles.required}>Nome e sobrenome</label>
                        <TextField
                            variant="outlined"
                            fullWidth
                            size="small"
                            placeholder="Digite seu nome"
                            {...register("name")}
                            error={!!errors.name}
                            helperText={errors.name?.message}
                            disabled={blockForm?.name || isLoading}
                            style={blockForm?.name ? { backgroundColor: "#F0F0F0" } : {}}
                        />
                    </div>

                    <div className={styles.column}>
                        <label htmlFor="phoneNumber" className={styles.required}>Telefone</label>
                        <Controller
                            name="phoneNumber"
                            control={control}
                            render={({ field }) => (
                                <InputMasked
                                    name="phoneNumber"
                                    variant="outlined"
                                    fullWidth
                                    mask="(99) 99999-9999"
                                    placeholder="Digite seu telefone"
                                    value={field.value}
                                    onChange={(e: any) => field.onChange(e.target.value.replace(/\D/g, ""))}
                                    helperText={errors.phoneNumber?.message}
                                    error={!!errors.phoneNumber}
                                    disabled={blockForm?.phoneNumber || isLoading}
                                    style={blockForm?.phoneNumber ? { backgroundColor: "#F0F0F0" } : {}}
                                    type="tel"
                                    overridePaste
                                />
                            )}
                        />
                    </div>

                    <div className={styles.row}>
                        <div className={styles.column}>
                            <label htmlFor="birthday" className={styles.required}>Data de nascimento</label>
                            <Controller
                                name="birthday"
                                control={control}
                                render={({ field }) => (
                                    <InputMasked
                                        name="birthday"
                                        variant="outlined"
                                        fullWidth
                                        mask="99/99/9999"
                                        placeholder="dd/mm/aaaa"
                                        value={field.value}
                                        onChange={(e: any) => field.onChange(e.target.value)}
                                        helperText={errors.birthday?.message}
                                        error={!!errors.birthday}
                                        disabled={blockForm?.birthday || isLoading}
                                        style={blockForm?.birthday ? { backgroundColor: "#F0F0F0" } : {}}
                                        type="tel"
                                        overridePaste
                                    />
                                )}
                            />
                        </div>

                        <div className={styles.column}>
                            <label htmlFor="document" className={styles.required}>CPF</label>
                            <Controller
                                name="document"
                                control={control}
                                render={({ field }) => (
                                    <InputMasked
                                        name="document"
                                        variant="outlined"
                                        fullWidth
                                        mask="999.999.999-99"
                                        placeholder="Digite seu CPF"
                                        value={field.value}
                                        onChange={(e: any) => field.onChange(e.target.value.replace(/\D/g, ""))}
                                        helperText={errors.document?.message}
                                        error={!!errors.document}
                                        disabled={blockForm?.document || isLoading}
                                        style={blockForm?.document ? { backgroundColor: "#F0F0F0" } : {}}
                                        type="tel"
                                        overridePaste
                                    />
                                )}
                            />
                        </div>
                    </div>

                    <div className={styles.footerButtons}>
                        <Button variant="text" color="secondary" onClick={() => onBack("register")} style={{ color: backgroundColorTop }}>
                            Voltar
                        </Button>
                        <Button
                            color="secondary"
                            style={{ backgroundColor: backgroundColorTop }}
                            type="submit"
                        >
                            {isLoading ? <CircularProgress size={16} color="inherit" /> : "Continuar"}
                        </Button>
                    </div>
                </form>
            </div>
        </SaasPublicLayout>
    )
}
export default ProfilePage