import { IGetRankingCustomersResponse } from "modules/generalDashboard/domain/dto/IGetRankingCustomersResponse";
import { IGeneralDashboardApiService } from "modules/generalDashboard/domain/interfaces/IGeneralDashboardApiService";
import { IFilterValue } from "modules/generalDashboard/presentation/interfaces/IFilter";

const GetRankingCustomersUseCase = async (service: IGeneralDashboardApiService, localId: string,  filter?: IFilterValue): Promise<IGetRankingCustomersResponse> => {

    const response = await service.getRankingCustomers(localId, filter);

    return response;

}
export default GetRankingCustomersUseCase;