import { useLocal } from "modules/local/presentation/context/LocalContext";
import { MutableRefObject, useCallback, useState } from "react";
import GeneralDashboardApi from "services/api/generalDashboard/GeneralDashboardApi";
import GetAverageTicketUseCase from "modules/generalDashboard/application/useCases/GetAverageTicketUseCase";
import { IFilterValue } from "../interfaces/IFilter";
import { IAverageTickets } from "../interfaces/IAverageTickets";
import { ICashierEvent } from "../interfaces/ICashierEvent";

const UseAverageTicket = (filter: IFilterValue | undefined, isLoadingCashier: boolean,  lastCashierOpenData: MutableRefObject<ICashierEvent | undefined>, isLoadingFinancialInfo: boolean | undefined) => {

  const [isLoading, setIsLoading] = useState<boolean>();
  const [averageTicketData, setAverageTicketData] = useState<IAverageTickets>()
  const { currentLocal } = useLocal();

  const getAverageTicket = useCallback(
    async () => {
      try {
        const serviceGeneral = GeneralDashboardApi();
        setIsLoading(true);
        if (!isLoadingCashier && !isLoadingFinancialInfo && currentLocal) {
          const cashierIds = filter?.cashierIds && filter.cashierIds.length > 0
            ? filter.cashierIds
            : lastCashierOpenData.current?.id
            ? [lastCashierOpenData.current.id]
            : undefined;

          if (!cashierIds) return;

          const response = await GetAverageTicketUseCase(serviceGeneral,
            currentLocal.id,     
            {
              ...filter,
              cashierIds: cashierIds,
            });

          setAverageTicketData(response);
          return response;
        }
      } finally {
        setIsLoading(false);
      }
    },
    [currentLocal, filter, isLoadingCashier, isLoadingFinancialInfo, lastCashierOpenData]
  );

  return {
    averageTicketData,
    isLoading,
    getAverageTicket,
    setAverageTicketData,
  };
};

export default UseAverageTicket;
