import Sidesheet from "components/sidesheet/Sidesheet";
import { IOperatorItem } from "modules/operators/domain/models/IOperatorItem";
import { IOperatorFormValue } from "modules/operators/presentation/components/operatorForm/IOperatorValue";
import OperatorFragment from "modules/operators/presentation/fragment/OperatorFragment";
import { useCallback, useEffect, useMemo, useState } from "react";

interface IOperatorsSidesheetProps {
    openOperator: boolean | "add";
    setOpenOperator: React.Dispatch<React.SetStateAction<boolean | "add">>;
    decrypt?: (encryptedMessage: string) => string | null | undefined;
}
const OperatorsSidesheet = ({ openOperator, setOpenOperator, decrypt }: IOperatorsSidesheetProps) => {
    const [title, setTitle] = useState("Operadores");
    const [openAddOperator, setOpenAddOperator] = useState<boolean>(false);
    const [selectedOperatorToEdit, setSelectedOperatorToEdit] = useState<IOperatorFormValue>();
    const [selectedOperatorToDelete, setSelectedOperatorToDelete] = useState<IOperatorItem>();

    useEffect(() => {
        if (openOperator === "add") setOpenAddOperator(true);
    }, [openOperator]);

    const isOperatorsList = useMemo(
      () =>
        !openAddOperator &&
        !selectedOperatorToEdit &&
        !selectedOperatorToDelete,
      [openAddOperator, selectedOperatorToDelete, selectedOperatorToEdit]
    );

    const handleCustomizedCancelButton = useCallback(() => {
        if (isOperatorsList) {
            return setOpenOperator(false);
        }
        setTitle("Operadores");
        setOpenAddOperator(false);
        setSelectedOperatorToEdit(undefined);
        setSelectedOperatorToDelete(undefined);
    }, [isOperatorsList, setOpenOperator]);

    return (
        <Sidesheet
            open={openOperator}
            onClose={() => setOpenOperator(false)}
            title={<h2>{title}</h2>}
            content={
                <OperatorFragment
                    setTitle={setTitle}
                    openAddOperator={openAddOperator}
                    setOpenAddOperator={setOpenAddOperator}
                    selectedOperatorToEdit={selectedOperatorToEdit}
                    setSelectedOperatorToEdit={setSelectedOperatorToEdit}
                    selectedOperatorToDelete={selectedOperatorToDelete}
                    setSelectedOperatorToDelete={setSelectedOperatorToDelete}
                    setOpenOperator={setOpenOperator}
                    decrypt={decrypt}
                />
            }
            currentStep={0}
            totalSteps={0}
            notTotalHeight
            arrowBack={!isOperatorsList}
            handleArrowBack={handleCustomizedCancelButton}
        />
    );
}

export default OperatorsSidesheet;