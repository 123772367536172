import React, { FC, useState } from 'react';
import styles from './TooltipInfo.module.scss';
import MUITooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import { IconButton } from '@mui/material';
import CloseIcon from '@material-ui/icons/Close';
import { HelpOutline } from '@mui/icons-material';

interface ITooltipProps {
  title?: string;
  text: string | string[];
  buttonClassName?: string;
};

export const TooltipInfo: FC<ITooltipProps> = ({ text, title, buttonClassName }) => {

  const [open, setOpen] = useState(false);

  const toggleTooltip = () => {
    setOpen(!open)
  }

  return (
    <MUITooltip 
      arrow
      open={open}
      onClose={toggleTooltip}
      disableFocusListener
      disableTouchListener
      TransitionComponent={Zoom} 
      placement="top-start"
      classes={{ popper: styles.popup }}
      title={
        <div>
          <IconButton size='small' onClick={toggleTooltip}>
            <CloseIcon/>  
          </IconButton>
          <h2>{title}</h2>
          <p>
            {typeof text === 'string' ? text : (
              text.map(x => (
                <span className={styles.lineRow}>
                  {x.indexOf(':') > -1 ? (
                    <>
                      <strong>{x.split(':')[0]}: </strong>
                      {(x.split(':')?.[1] || x) + ' '}
                    </>
                  ) : {x}}
                </span>
              ))
            )}
          </p>
        </div>
      }
    >
      <IconButton size="small" onClick={toggleTooltip} className={buttonClassName || "tooltip-button"}>
       <HelpOutline style={{width: "17px", height: "17px", color: "#A2A2A2"}}/>
      </IconButton>
    </MUITooltip>
  );
};