import styles from "./SalesByDayHeader.module.scss";

const titleHeader = ['Data', 'Cashless', 'Cancelado', 'Boleto', 'Débito', 'Débito (NT)', 'Crédito', 'Crédito (NT)', 'Dinheiro', 'Desconto', 'Voucher', 'Outros', 'Em aberto', 'Total Transacionado', 'Total não transacionado', 'Total'];

const SalesByDayHeader = () => {
  return (
    <div id={styles.salesByDayHeader}>
      {titleHeader.map((item, index) => (
        <div key={item} className={styles.salesByDayHeaderCel} style={{ textAlign: index === 0 ? 'left' : 'right' }}>
          {item}
        </div>
      ))}
    </div>
  );
};
export default SalesByDayHeader;
