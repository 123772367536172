import { Icon, IconButton, Radio, RadioGroup } from '@material-ui/core';
import { EditOutlined, Publish } from "@mui/icons-material";
import Input from 'components/ui/input/Input';
import FormItemContainer from 'components/ui/inputContainer/FormItemContainer';
import { useUi } from 'contexts/userInterface/UserInterfaceContext';
import React, { ChangeEvent, FormEvent, useCallback, useEffect, useRef, useState } from 'react';
import { ReactComponent as Add } from "res/assets/icons/add.svg";
import { ReactComponent as EmptyList } from "res/assets/icons/empty_images.svg";
import { v4 as uuid, validate } from "uuid";
import FooterButtons from '../../../footerButtons/FooterButtons';
import { IImage, IImageErrors, IUserInterfaceValues } from './IUserInterfaceValues';
import styles from './UserInterfaceForm.module.scss';

export interface IUserInterfaceFormProps {
    defaultValue?: IUserInterfaceValues;
    onModified: (modified: boolean) => void;
    onSubmit: (value: IUserInterfaceValues) => void
    onClickBack: (value: IUserInterfaceValues) => void
}

const UserInterfaceForm: React.FC<IUserInterfaceFormProps> = ({ defaultValue, onModified, onSubmit, onClickBack }) => {
    const refInput = useRef<HTMLInputElement>(null);

    const { toast } = useUi();
    
    const [values, setValues] = useState<IUserInterfaceValues>(defaultValue ?? {} as IUserInterfaceValues);
    const [showEditImage, setShowEditImage] = useState(false);
    const [showSelectImage, setShowSelectImage] = useState(false);
    const [image, setImage] = useState<IImage>({
        name: '',
        imageBase64: '',
        isSelected: false
    });
    const [imageErrors, setImageErrors] = useState<IImageErrors>({});

    const validateImage = useCallback(
      (imageValues: {name?: string, imageBase64?: string}) => {
        let errors: IImageErrors = {};
        let hasError = false;

        if (!imageValues.name) {
            errors = { ...errors, name: "Campo obrigatório" };
            hasError = true;
        }

        if (!imageValues.imageBase64) {
            errors = { ...errors, imageBase64: "Campo obrigatório" };
            hasError = true;
        }
        setImageErrors(errors);
        return !hasError;
      },
      [],
    );
    

    useEffect(() => {
        if (defaultValue) {
            setValues(defaultValue);
        }
    }, [defaultValue]);

    const onChangeHandle = useCallback((property: string, value: any) => {
        const properties = property.split('.');
        const fullObj = values[properties[0] as keyof IUserInterfaceValues] as object;

        if (properties.length === 1) {
            setValues((prev) => ({ ...prev, [properties[0]]: value }));
        } else {
            setValues((prev) => ({ ...prev, [properties[0]]: { ...fullObj, [properties[1]]: value } }));
        }

        onModified(true);
    },[onModified, values])

    const handleSelectImage = useCallback(
      (id: string) => {
        setValues(prev => ({
            ...prev,
            wallpapers: prev.wallpapers?.map(it => ({
                ...it,
                isSelected: it.id === id
            }))
        }))
      },
      [],
    )
    
    const submitHandle = useCallback((ev: FormEvent<HTMLFormElement>) => {
        ev.preventDefault();
        if (showSelectImage) {
            if(validateImage(image)){
                setValues(prev => {
                    let imagesList = prev?.wallpapers?.map(it => (
                        {
                            id: it.id,
                            name: it.name, 
                            isSelected: false, 
                            imageBase64: it.imageBase64, 
                        }
                    )) ?? [];
                    imagesList.push({
                        id: uuid().slice(-5),
                        imageBase64: image.imageBase64,
                        name: image.name,
                        isSelected: true
                    })
                    return {
                        ...prev,
                        wallpapers: imagesList
                    }
                })
                setImage({
                    imageBase64: '',
                    name: '',
                    isSelected: false
                })
                setShowSelectImage(false)
            }
        } else {
            onSubmit({
                ...values,
                wallpapers: values.wallpapers?.map(it => ({
                    ...it,
                    id: validate(it?.id ?? '') ? it.id : '',
                 }))
            });
        }
    }, [image, onSubmit, showSelectImage, validateImage, values]);

    const fileToBase64 = useCallback((file: File) => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onload = function (event) {
                resolve(event.target?.result);
            };
        
            reader.readAsDataURL(file);
        });
    }, []);

    const handleChangeImg = useCallback(async (ev: ChangeEvent<HTMLInputElement>) => {
        const files = ev.currentTarget.files;
        // eslint-disable-next-line prefer-const
        let images: string[] = [];
        if (files?.length) {
            for (let i = 0; i < files.length; i++) {
                const _file = files?.item(i);
        
                const base64 = _file && (await fileToBase64(_file));
                images.push(String(base64));
            }
        }
        setImage(prev => ({
            ...prev,
            imageBase64: images[0]
        }));
    }, [fileToBase64]);

    const handleImageName = useCallback((ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setImage(prev => ({
            ...prev,
            name: ev.target.value
        }))
    }, []);
    

    const onClickImage = useCallback((ev?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        ev?.preventDefault();
        refInput.current?.click();
    }, []);

    const onClickAddImage = useCallback((ev?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        ev?.preventDefault();
        setShowSelectImage(true);
    }, []);

    const handleClickBack = useCallback(() => {
        if (showSelectImage) {
            setImage({
                imageBase64: '',
                name: '',
                isSelected: false
            });
            setShowSelectImage(false);
        } else {
            onClickBack(values);
        }
    }, [onClickBack, showSelectImage, values]);

    const handleDeleteImage = useCallback(
      (img: IImage) => {
        if (img.isSelected){
            toast('Imagem selecionada não pode ser deletada.', 'error');
        } else {
            setValues(prev => ({
                ...prev,
                wallpapers: prev.wallpapers?.filter(it => it?.id !== img?.id)
            }))
        }
      },
      [toast],
    )
    

    return (
        <form id={styles.UserInterfaceForm} onSubmit={submitHandle}>
            <div className={styles.container}>
                {!showSelectImage && (
                    <>
                        <h4>Mensagem de pagamento</h4>
                        <FormItemContainer className={styles.formItemContainer} label={"Mensagem após o pagamento"}>
                            <Input 
                                placeholder='Pagamento realizado com sucesso!'
                                variant={"outlined"} 
                                value={values?.labels?.paymentSuccess || ''} 
                                onChange={ev => onChangeHandle("labels.paymentSuccess", ev.target.value)} 
                            />
                        </FormItemContainer>
                        <div className={styles.divisor}/>
                    </>
                )}
              

                {
                    showSelectImage ? (
                        <>
                            <FormItemContainer className={styles.formItemContainer} label={"Nome da imagem"}>
                                <Input 
                                    placeholder='Totem pista'
                                    variant={"outlined"} 
                                    value={image.name} 
                                    onChange={handleImageName} 
                                    error={!!imageErrors?.name}
                                    helperText={imageErrors?.name}
                                />
                            </FormItemContainer>
                            <div className={styles.contentImg}>
                                <input
                                    name="imageBase64"
                                    ref={refInput}
                                    id="input-file"
                                    accept=".jpg, .jpeg, .png"
                                    type="file"
                                    style={{ display: "none" }}
                                    onChange={handleChangeImg}
                                />
                                {image?.imageBase64 ? (
                                    <div
                                        className={styles.boxImg}
                                        onClick={onClickImage}
                                        onMouseOver={() => setShowEditImage(true)}
                                        onMouseLeave={() => setShowEditImage(false)}
                                    >
                                        <img
                                            src={
                                                image.imageBase64
                                            }
                                            alt="upload_img"
                                            style={{ filter: showEditImage ? "brightness(0.5)" : undefined }}
                                        />
                                        {showEditImage && (
                                            <div className={styles.editButton}>
                                                <EditOutlined />
                                                Editar
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <div>
                                        <div
                                            className={styles.uploadImg}
                                            onClick={onClickImage}
                                            style={{ border: imageErrors?.imageBase64 ? "1px solid #f44336" : undefined }}
                                        >
                                            <Publish fontSize="large" />
                                            <button className={styles.publishButton}>Enviar</button>
                                            {imageErrors?.imageBase64 && <div className={styles.imgError}>{imageErrors?.imageBase64}</div>}
                                        </div>
                                    </div>
                                )}
                                <ul>
                                    <li>
                                        O tamanho da imagem deve ser compatível com o tamanho do totem: <b>1080x1920px</b>
                                    </li>
                                    <li>
                                        Certifique-se que a imagem esteja em <b>RGB</b>
                                    </li>
                                    <li>
                                        Preferência em formato <b>.jpg</b>
                                    </li>
                                    <li>
                                        Preferência em formato <b>.jpg</b>
                                    </li>
                                    <li>
                                        <a 
                                            target='_blank' 
                                            rel="noreferrer" 
                                            href="https://arquivos.meep.cloud/downloads/docs/Guia-De-Criacao-De-Arte-para-Totem.pdf"
                                        >
                                            Clique aqui e baixe nosso guia
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className={styles.addButton} onClick={onClickAddImage}>
                                <Add />
                                Adicionar nova imagem
                            </div>
                            <div style={{marginTop: 8}}>
                                {values?.wallpapers && values.wallpapers?.length > 0 ? (
                                    <RadioGroup >
                                        {
                                            values.wallpapers.map((it, key) => (
                                                <div key={key} className={styles.imageItem}>
                                                    <Radio checked={it.isSelected} onClick={() => handleSelectImage(it?.id ?? '')} />
                                                    <div className={styles.imageContainer}>
                                                        <img 
                                                            src={
                                                                validate(it.id ?? '') ? `${process.env.REACT_APP_URL_IMAGEM}wallpapers/${it.id}.png`
                                                                : it.imageBase64
                                                            }
                                                            alt={`Papel de parede ${key}`} 
                                                        />
                                                    </div>
                                                    <div className={styles.imgName}>{it.name}</div>
                                                    <IconButton onClick={() => handleDeleteImage(it)}><Icon>delete_outlined</Icon></IconButton>
                                                </div>
                                            ))
                                        }
                                    </RadioGroup>
                                ) : (
                                    <div className={styles.emptyList}>
                                        <EmptyList />
                                        <p>Você <b>não possui imagem</b> cadastrada!</p>
                                    </div>
                                )}
                            </div>
                        </>
                    )
                }
            </div>
            <>
                <FooterButtons onClickBack={handleClickBack} labelNext={"Salvar"} nextType='submit'></FooterButtons>
            </>
        </form>
    )
}
export default UserInterfaceForm