import Sidesheet from "components/sidesheet/Sidesheet";
import styles from "./SidesheetHorario.module.scss";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import AddIcon from "@mui/icons-material/Add";
import { useCallback, useEffect, useState } from "react";
import { Close } from "@mui/icons-material";
import Utils from "services/utils/Utils";
import { IAvailableDay } from "../../pages/interfaces/IGestaoVendas";
import { getWeekDayFromNumber } from "services/utils/Date";
import { v4 } from "uuid";
import InputMasked from "components/inputMasked/InputMasked";
import { isValid, parse } from "date-fns";

interface SideshetProps {
  setOpenSidesheet: React.Dispatch<React.SetStateAction<boolean>>;
  openSidesheet: boolean;
  dateItens: IAvailableDay[];
  handleChangeDate: (days: IAvailableDay[]) => void;
}

const SidesheetHorario = ({ 
  openSidesheet,
  setOpenSidesheet,
  dateItens,
  handleChangeDate,
}: SideshetProps) => {
  const initialHour = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0);

  const [days, setDays] = useState<{ dayOfWeek: number, checked: boolean }[]>([]);
  const [values, setValues] = useState<IAvailableDay[]>(dateItens);
  const [errors, setErrors] = useState<{ listId: string, type: 'start' | 'end', message: string }[]>([]);

  useEffect(() => {
    dateItens && setValues(JSON.parse(JSON.stringify(dateItens)));
  }, [dateItens])

  useEffect(() => {
    if (!days.length) {
      [0, 1, 2, 3, 4, 5, 6].forEach(x => {
        let checked = false;

        if (dateItens?.find(y => y.dayOfWeek === x && y.checked)) {
          checked = true;
        }
        setDays(prev => [...prev, { dayOfWeek: x, checked }])
      });
    }
  }, [dateItens, days.length]);

  // useEffect(() => {
  //   handleChangeDate((prevDate: IAvailableDay[]) =>
  //     prevDate.map((dia) => ({
  //       ...dia,
  //       startHour: Utils.toInputDateString(initialHour),
  //       endHour: Utils.toInputDateString(initialHour),
  //       checked: false,
  //     }))
  //   );
  // }, []);

  const handleButtonClick = (index: number) => {
    const updateDate: IAvailableDay[] = [...values];

    updateDate.push({
      startHour: Utils.toInputDateString(initialHour),
      endHour: Utils.toInputDateString(initialHour),
      dayOfWeek: index,
      listId: v4(),
      checked: true
    });

    setValues(updateDate);
  };

  const handleTimeChange = (newTime: string, indexDay: number, indexHour: number, value: "start" | "end") => {
    const updatedDays = [...values];

    if (value === "start") {
      updatedDays.filter(x => x.dayOfWeek === indexDay)[indexHour].startHour = newTime;
    } else {
      updatedDays.filter(x => x.dayOfWeek === indexDay)[indexHour].endHour = newTime;
    }

    updatedDays.filter(x => x.dayOfWeek === indexDay)[indexHour].checked = true;

    setValues(updatedDays);
  };

  const handleChecked = (index: number, dayOfWeek: number) => {
    setDays(prev => prev.map(x => x.dayOfWeek === dayOfWeek ? { ...x, checked: !x.checked } : x));
    setValues(values.map(x => x.dayOfWeek === dayOfWeek ? { ...x, checked: !x.checked } : x));
  };

  const excluir = (listId: string) => {
    setValues(values.filter(x => x.listId !== listId));
  };

  const classSectionHour = (indexHoras: number, checked: boolean | undefined) => {
    if (indexHoras > 0 && checked) {
      return styles.section_hour
    } else if (indexHoras > 0 && !checked) {
      return styles.section_hour_disabled
    } else if (indexHoras === 0 && checked) {
      return styles.section_hour_margim
    } else {
      return styles.section_hour_margim_disabled
    }
  }

  const getTime = (val: string) => {
    if (val.indexOf('T') > -1) return val.split('T')[1];

    return val;
  }

  const handleContinue = useCallback(() => {
    setErrors([]);
    let hasError = false;

    values.filter(x => x.checked).forEach(x => {
      let start = x.startHour;
      if (start.indexOf('T') > -1) start = start.split('T')[1];
      if (start.length > 5) start = start.substring(0, 5);
      if (!isValid(parse(start, 'HH:mm', new Date()))) {
        setErrors(prev => [...prev, {
          listId: x.listId!,
          type: 'start',
          message: 'Horário inválido'
        }]);
        hasError = true;
      }

      let end = x.endHour;
      if (end.indexOf('T') > -1) end = end.split('T')[1];
      if (end.length > 5) end = end.substring(0, 5);
      if (!isValid(parse(end, 'HH:mm', new Date()))) {
        setErrors(prev => [...prev, {
          listId: x.listId!,
          type: 'end',
          message: 'Horário inválido'
        }]);
        hasError = true;
      }
    });

    if (!hasError) {
      handleChangeDate(values.filter(x => x.checked));
      setOpenSidesheet(false);
    }
  }, [handleChangeDate, setOpenSidesheet, values]);

  const handleOnClose = () => {
    setValues(JSON.parse(JSON.stringify(dateItens)));
    setDays([]);

    [0, 1, 2, 3, 4, 5, 6].forEach(x => {
      let checked = false;

      if (dateItens?.find(y => y.dayOfWeek === x && y.checked)) {
        checked = true;
      }
      setDays(prev => [...prev, { dayOfWeek: x, checked }])
    });

    setOpenSidesheet(false);
  }

  return (
    <Sidesheet
      open={openSidesheet}
      onClose={handleOnClose}
      title={<h1>Horários de funcionamento</h1>}
      content={
        <div id={styles.SidesheetHorario}>
          <p>Selecione os dias que o seu estabelecimento fica aberto: </p>
          <div className={styles.errorContent}>
            {!!errors.length &&
              <p>Os campos selecionados estão com horário inválido. Corrija antes de continuar.</p>
            }
          </div>
          {days.map((itemDay, indexDay) => {
            return (
              <div className={styles.section}>
                <div className={styles.section_day}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={itemDay.checked}
                        onChange={() => handleChecked(indexDay, itemDay.dayOfWeek)}
                      />
                    }
                    label={getWeekDayFromNumber(itemDay.dayOfWeek)}
                  />
                </div>
                <div className={styles.section_time}>
                  {values?.filter(x => x.dayOfWeek === itemDay.dayOfWeek).map((itemHoras, indexHoras) => {
                    return (
                      <>
                        <div className={itemDay.checked ? styles.section_hour : styles.section_hour_disabled}>
                          {/* <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            locale={ptBR}
                          >
                            <DesktopTimePicker
                              ampm={false}
                              readOnly={!itemDay.checked}
                              renderInput={(props) => (
                                <TextField
                                  {...props}
                                  className={styles.datePicker}
                                  size="small"
                                />
                              )}
                              value={itemHoras.startHour || null}
                              onChange={(newTime) =>
                                newTime && handleTimeChange(
                                  newTime,
                                  indexDay,
                                  indexHoras,
                                  "start"
                                )
                              }
                            />
                          </LocalizationProvider> */}
                          <InputMasked
                            name={'startHour'}
                            value={getTime(itemHoras.startHour)}
                            variant='outlined'
                            mask="99:99"
                            className={`${styles.datePicker} ${!!errors.find(x => x.listId === itemHoras.listId && x.type === 'start') ? styles.error : ''}`}
                            onChange={(ev: any) => handleTimeChange(
                              ev.target.value,
                              indexDay,
                              indexHoras,
                              "start"
                            )}
                          />
                        </div>
                        <span>às</span>
                        <div
                          className={
                            classSectionHour(indexHoras, itemDay.checked)
                          }
                        >
                          {/* <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            locale={ptBR}
                          >
                            <DesktopTimePicker
                              ampm={false}
                              readOnly={!itemDay.checked}
                              renderInput={(props) => (
                                <TextField
                                  {...props}
                                  className={styles.datePicker}
                                  size="small"
                                />
                              )}
                              value={itemHoras.endHour || null}
                              onChange={(newTime) =>
                                newTime && handleTimeChange(
                                  newTime,
                                  indexDay,
                                  indexHoras,
                                  "end"
                                )
                              }
                            />
                          </LocalizationProvider> */}
                          <InputMasked
                            name={'endHour'}
                            value={getTime(itemHoras.endHour)}
                            variant='outlined'
                            mask="99:99"
                            className={`${styles.datePicker} ${!!errors.find(x => x.listId === itemHoras.listId && x.type === 'end') ? styles.error : ''}`}
                            onChange={(ev: any) => handleTimeChange(
                              ev.target.value,
                              indexDay,
                              indexHoras,
                              "end"
                            )}
                          />
                        </div>
                        {indexHoras > 0 && (
                          <Close
                            className={styles.close}
                            onClick={() => excluir(itemHoras.listId!)}
                          />
                        )}
                      </>
                    );
                  })}
                </div>
                {itemDay.checked && (
                  <button
                    className={styles.add_interval}
                    onClick={() => handleButtonClick(indexDay)}
                  >
                    <AddIcon />
                    <p>Adicionar intervalo</p>
                  </button>
                )}
              </div>
            );
          })}
        </div>
      }
      cancelButton
      continueButton={"Continuar"}
      handleContinueButton={handleContinue}
      currentStep={1}
      totalSteps={1}
      notTotalHeight={true}
    />
  );
};

export default SidesheetHorario;
