import { useEffect, useRef, useState, useCallback } from 'react';
import { IFilterValue } from '../interfaces/IFilter';
import UseCashiersEvents from '../hooks/UseCashiersEvents';
import UseFinancialInformation from '../hooks/UseFinancialInformation';
import UseAllMovements from '../hooks/UseAllMovements';
import UsePosPaid from '../hooks/UsePosPaid';
import UseCashless from '../hooks/UseCashless';
import UsePreRecharge from '../hooks/UsePreRecharge';
import UseAverageTicket from '../hooks/UseAverageTicket';
import UseTicket from '../hooks/UseTicket';
import UseRankingProducts from '../hooks/UseRankingProducts';
import UseRankingOperators from '../hooks/UseRankingOperators';
import UseRankingCustomers from '../hooks/UseRankingCustomers';
import UseSalesCategories from '../hooks/UseSalesCategories';
import UseTurnoverTable from '../hooks/UseTurnoverTable';
import UseProductsWithoutSplit from '../hooks/UseProductsWithoutSplit';

export const UseGeneralDashboardPage = () => {
  const [filterType, setFilterType] = useState("cashier");
  const [filterSearch, setFilterSearch] = useState();
  const [filter, setFilter] = useState<IFilterValue>();
  const lastCashierOpen = useRef<string | undefined>(undefined);
  const [isProductLoaded, setIsProductLoaded] = useState<boolean>(false);

  const { cashierEvents, getCashiersEvents, lastCashierOpenData, setIsLoadingCashier, isLoadingCashier, isLoadingCashierCurrent } = UseCashiersEvents(filter);
  const financialInformation = UseFinancialInformation(filter, isLoadingCashier, lastCashierOpenData);
  const allMovements = UseAllMovements(filter, isLoadingCashier, lastCashierOpenData, financialInformation.isLoadingFinancialInfo);
  const posPaidData = UsePosPaid(filter, isLoadingCashier, lastCashierOpenData, financialInformation.isLoadingFinancialInfo);
  const cashlessData = UseCashless(filter, isLoadingCashier, lastCashierOpenData, financialInformation.isLoadingFinancialInfo);
  const preRechargeData = UsePreRecharge(filter, isLoadingCashier, lastCashierOpenData, financialInformation.isLoadingFinancialInfo);
  const avarageTicketData = UseAverageTicket(filter, isLoadingCashier, lastCashierOpenData, financialInformation.isLoadingFinancialInfo);
  const ticketData = UseTicket(filter, isLoadingCashier, lastCashierOpenData, financialInformation.isLoadingFinancialInfo);
  const rankingProducts = UseRankingProducts(filter, isLoadingCashier, lastCashierOpenData, financialInformation.isLoadingFinancialInfo, setIsProductLoaded);
  const rankingOperators = UseRankingOperators(filter, isLoadingCashier, lastCashierOpenData, financialInformation.isLoadingFinancialInfo);
  const rankingCustomers = UseRankingCustomers(filter, isLoadingCashier, lastCashierOpenData, financialInformation.isLoadingFinancialInfo);
  const rankingCategories = UseSalesCategories(filter, isLoadingCashier, lastCashierOpenData, financialInformation.isLoadingFinancialInfo);
  const turnoverData = UseTurnoverTable(filter, isLoadingCashier, lastCashierOpenData, financialInformation.isLoadingFinancialInfo);
  const productsWithoutSplit = UseProductsWithoutSplit();

  useEffect(() => {
    getCashiersEvents();
  }, [getCashiersEvents]);

  useEffect(() => {
    const cashierOpening = cashierEvents?.cashiers[0];

    if (cashierOpening) {
      lastCashierOpen.current = cashierOpening.id;
    }
  }, [cashierEvents?.cashiers]);

  const onChangeFilterHandle = useCallback((value: IFilterValue) => {
    const { cashierIds, ...rest } = value;
    setIsLoadingCashier(true);
    setIsProductLoaded(false);
    financialInformation.setIsLoadingFinancialInfo(true);
    setFilter({
      cashierIds: cashierIds,
      ...rest,
    });
  }, [setIsLoadingCashier, financialInformation]);

  useEffect(() => {
    if (!isLoadingCashier) {
      financialInformation.getFinancialInformation();
    }
  }, [isLoadingCashier]);

  useEffect(() => {
    if (!isLoadingCashier && !financialInformation.isLoadingFinancialInfo) {
      allMovements.getAllMovements();
    }
  }, [financialInformation.isLoadingFinancialInfo, isLoadingCashier]);

  useEffect(() => {
    if (!isLoadingCashier && !financialInformation.isLoadingFinancialInfo) {
      posPaidData.getPosPaid();
    }
  }, [financialInformation.isLoadingFinancialInfo, isLoadingCashier]);

  useEffect(() => {
    if (!isLoadingCashier && !financialInformation.isLoadingFinancialInfo) {
      ticketData.getTicket();
    }
  }, [financialInformation.isLoadingFinancialInfo, isLoadingCashier]);

  useEffect(() => {
    if (!isLoadingCashier && !financialInformation.isLoadingFinancialInfo) {
      cashlessData.getCashless();
    }
  }, [isLoadingCashier, financialInformation.isLoadingFinancialInfo]);

  useEffect(() => {
    if (!isLoadingCashier && !financialInformation.isLoadingFinancialInfo) {
      preRechargeData.getPreRecharge();
    }
  }, [isLoadingCashier, financialInformation.isLoadingFinancialInfo]);

  useEffect(() => {
    if (!isLoadingCashier && !financialInformation.isLoadingFinancialInfo) {
      avarageTicketData.getAverageTicket();
    }
  }, [financialInformation.isLoadingFinancialInfo, isLoadingCashier]);

  useEffect(() => {
    if (!isLoadingCashier && !financialInformation.isLoadingFinancialInfo) {
      turnoverData.getTurnoverTable();
    }
  }, [financialInformation.isLoadingFinancialInfo, isLoadingCashier]);

  useEffect(() => {
    if (!isLoadingCashier && !financialInformation.isLoadingFinancialInfo && isProductLoaded) {
      rankingCategories.getSalesCategories();
    }
  }, [financialInformation.isLoadingFinancialInfo, isLoadingCashier, isProductLoaded]);

  useEffect(() => {
    productsWithoutSplit.getProductsWithoutSplit();
  }, []);

  return {
    onChangeFilterHandle,
    filter,
    setFilterSearch,
    filterSearch,
    setFilterType,
    filterType,
    cashierEvents,
    isLoadingCashier,
    getCashiersEvents,
    isLoadingCashierCurrent,
    lastCashierOpenData,
    financialInformation,
    allMovements,
    posPaidData,
    cashlessData,
    preRechargeData,
    avarageTicketData,
    ticketData,
    rankingProducts,
    rankingOperators,
    rankingCustomers,
    rankingCategories,
    turnoverData,
    isProductLoaded,
    productsWithoutSplit,
  };
};
