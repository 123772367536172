import { Divider } from '@material-ui/core';
import styles from './CatalogCodes.module.scss';
import { ExpandLessRounded, InfoOutlined, LinkOutlined } from '@material-ui/icons';
import UseDimension from 'components/dimension/UseDimension';
import Button from 'components/ui/Button/Button';
import { QrCodeOutlined } from '@mui/icons-material';
import { UseGestaoVendas } from '../../hooks/UseGestaoVendas';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useLocal } from 'modules/local/presentation/context/LocalContext';
import Sidesheet from 'components/sidesheet/Sidesheet';
import QRCode from "qrcode.react";

export interface ICatalogCodesProps {
    isQrCodeVisible: boolean;
    setIsQrCodeVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const CatalogCodes = ({ isQrCodeVisible, setIsQrCodeVisible }: ICatalogCodesProps) => {
    const { dimensions } = UseDimension();
    const { currentLocal } = useLocal();
    const { getConfigData, getCatalogList, dataConfig, catalogList } = UseGestaoVendas(true);

    const [openQrcodeSidesheet, setOpenQrcodeSidesheet] = useState<string | null>(null);
    const [sidesheetTitle, setSidesheetTitle] = useState<string | null>(null);
    const qrCodeRef = useRef<any>("");


    const catalogUrl = useMemo(() => dataConfig?.catalogUrl ?? "", [dataConfig?.catalogUrl]);
    const registeredCatalogs = useMemo(() => catalogList ?? [], [catalogList]);
    const currentLocalName = useMemo(() => currentLocal?.name.split(" ").join("") ?? "", [currentLocal]);

    useEffect(() => {
        getConfigData();
        getCatalogList();
    }, [getCatalogList, getConfigData]);

    const handleDownloadQrcode = () => {
        const canvas = document.querySelector('canvas');

        if (canvas) {
            const dataUrl = canvas.toDataURL('image/png');

            const link = document.createElement('a');
            link.href = dataUrl;
            link.download = 'qrcode.png';

            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
        }
    };

    return (
        <>
            <div id={styles.CatalogCodes}>
                <div className={styles.header}>
                    <h1>Cardápios digitais</h1>
                    <div
                        onClick={() => setIsQrCodeVisible(!isQrCodeVisible)}
                        style={{
                            transform: isQrCodeVisible ? "rotate(0deg)" : "rotate(180deg)",
                        }}
                    >
                        <ExpandLessRounded style={{ width: "35px", height: "35px" }} />
                    </div>
                </div>

                {isQrCodeVisible && (
                    <>
                        {registeredCatalogs.map((catalog) => {
                            const currentQrcodeLink = `https://mepay.meep.cloud/${catalogUrl}?listId=${catalog.id}`;
                            const currentCatalog = catalog.description;

                            return (
                                <div className={styles.menuCard} key={catalog.id}>
                                    <div>
                                        <span className={styles.catalogTitle}>{catalog.description}</span>
                                        {dimensions.width < 650 && (
                                            <div className={styles.catalogLink} style={{ marginTop: "10px", gridGap: "10px", fontSize: "14px" }}>
                                                <div
                                                    className={styles.icon}
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => navigator.clipboard.writeText(currentQrcodeLink)}
                                                >
                                                    <LinkOutlined style={{ width: "18px" }} />
                                                </div>
                                                <a
                                                    href={currentQrcodeLink}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    {currentQrcodeLink}
                                                </a>
                                            </div>
                                        )}
                                    </div>
                                    <div>
                                        {dimensions.width >= 650 && (
                                            <>
                                                <div className={styles.catalogLink}>
                                                    <div
                                                        className={styles.icon}
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => navigator.clipboard.writeText(currentQrcodeLink)}
                                                    >
                                                        <LinkOutlined />
                                                    </div>
                                                    <a
                                                        href={currentQrcodeLink}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        {currentQrcodeLink}
                                                    </a>
                                                </div>
                                                <Divider
                                                    orientation="vertical"
                                                    flexItem
                                                    style={{ width: "2px", height: "30px", alignSelf: "center" }}
                                                />
                                            </>
                                        )}
                                        <Button
                                            variant="outlined"
                                            className={styles.qrCodeButton}
                                            startIcon={<QrCodeOutlined />}
                                            onClick={() => {
                                                setSidesheetTitle(currentCatalog);
                                                setOpenQrcodeSidesheet(currentQrcodeLink);
                                            }}
                                        >
                                            QR Code
                                        </Button>
                                    </div>
                                </div>
                            )
                        })}
                    </>
                )}
            </div>

            <Sidesheet
                open={openQrcodeSidesheet}
                onClose={() => setOpenQrcodeSidesheet(null)}
                title={
                    <h2>
                        <b>QR Code</b>
                    </h2>
                }
                content={
                    <div className={styles.sidesheetContent}>
                        <div>
                            <p>
                                Esse é o QR Code do seu cardápio. <b>Disponibilize-os para os seus clientes</b> e torne a experiência deles ainda melhor!
                            </p>
                            <div>{sidesheetTitle}</div>
                        </div>
                        <div>
                            <QRCode
                                ref={qrCodeRef}
                                size={200}
                                value={openQrcodeSidesheet ?? ""}
                            />
                            <span onClick={handleDownloadQrcode}>Salvar imagem</span>
                        </div>
                    </div>
                }
                currentStep={1}
                totalSteps={1}
                continueButton="Fechar"
                handleContinueButton={() => setOpenQrcodeSidesheet(null)}
            />
        </>
    )
}

export default CatalogCodes;