import React, { FC, useCallback, useMemo } from 'react'
import styles from './SupplyStockMovementList.module.scss'
import { IGetSupplyStockMovementItem, IGetSupplyStockMovementListRequest, IGetSupplyStockMovementListResponse } from 'modules/meepErp/application/dtos/supplyStockMovement/ISupplyStockMovementDtos'
import { SupplyStockMovementType } from 'modules/meepErp/models/stockMovement/_SupplyStockMovementType'
import { ColumnConfigs, List } from '../../ui/list/List'
import SupplyStockMovementFilter, { ISupplyStockMovementFilterValues } from '../supplyStockMovementFilter/SupplyStockMovementFilter'
import { IAutocompletePaginatedRequest, IAutocompletePaginatedListResponse } from '../../autocomplete/AutocompleteInput'
import { IGetSupplySelectItemResponse } from 'modules/meepErp/application/dtos/supply/IGetLSupplyListResponse'
import { ISupplyFilterValue } from '../../supply/supplyList/supplyFilter/ISupplyFilter'
import { format, parseISO } from 'date-fns'
export interface ISupplyStockMovementListProps {
    //propertys
    supplyStockMovements?: IGetSupplyStockMovementListResponse,
    filter: IGetSupplyStockMovementListRequest & {
        page?: number;
        pageSize?: number;
        orderBy?: string;
    },
    onChangeFilter: (filterWithPagination: ISupplyStockMovementFilterValues & {
        page?: number;
        pageSize?: number;
        orderBy?: string;
    }) => void
    isLoading: boolean,
    getSupply: (request: ISupplyFilterValue) => Promise<IAutocompletePaginatedListResponse<IGetSupplySelectItemResponse>>
    getOperation: (request: IAutocompletePaginatedRequest) => Promise<IAutocompletePaginatedListResponse>
    getSuplyStorageLocals: (request: IAutocompletePaginatedRequest) => Promise<IAutocompletePaginatedListResponse>
}

export interface IQuantiyProps {
    supplyStockMovements?: IGetSupplyStockMovementListResponse
    value: number
}

const SupplyStockMovementList: FC<ISupplyStockMovementListProps> = ({
    supplyStockMovements,
    filter,
    onChangeFilter,
    isLoading,
    getSupply,
    getOperation,
    getSuplyStorageLocals
}) => {

    const columnsConfig: ColumnConfigs<IGetSupplyStockMovementItem> = {

        createdAt: {
            title: 'Data e hora',
            sortable: true,
            width: 150,
            renderRow: (value) => <span>{(new Date(value).toLocaleDateString())} - {format(parseISO(value + 'Z'), 'HH:mm')}</span>
        },
        supplyCode: {
            title: 'Código',
            sortable: true,
            width: 100,
        },

        supplyName: {
            title: 'Insumo',
            sortable: true,
        },
        supplyGroupName: {
            title: 'Grupo de insumo',
            sortable: true,
        },
        invoiceOperationName: {
            title: 'Tipo de operação',
            sortable: true,
        },
        measurementUnitSymbol: {
            title: 'Unidade',
            sortable: true,
            width: 150,
        },
        quantity: {
            title: 'Quantidade',
            sortable: true,
            width: 150,
            renderRow: (value, item) => (
                item.movementType === SupplyStockMovementType.Input ? <span style={{ color: '#399C54' }}>{value}</span> : <span style={{ color: '#F44336' }}>-{value}</span>
            )
        },
        balance: {
            title: 'Saldo',
            sortable: true,
        },
        stockUnitCost: {
            title: 'Custo unitário',
            sortable: true,
            width: 150,
            renderRow: (value) => (value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }))
        },
        stockTotalCost: {
            title: 'Custo total',
            sortable: true,
            width: 150,
            renderRow: (value) => (value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }))
        },

    }

    const onChangeFilterHandler = useCallback((filter: ISupplyStockMovementFilterValues) => {
        onChangeFilter({
            ...filter,
            page: 1,
            pageSize: 10
        })
    }, [onChangeFilter])


    const pagination = useMemo(() => ({
        page: filter.page ?? 1,
        pageSize: filter.pageSize ?? 10,
        total: supplyStockMovements?.total ?? 1,
        count: supplyStockMovements?.totalPages ?? 1
    }), [supplyStockMovements, filter.page, filter.pageSize])
    return (
        <div id={styles.SupplyStockMovementList} >
            <SupplyStockMovementFilter
                filter={filter}
                onSubmmit={onChangeFilterHandler}
                getSupply={getSupply}
                getOperation={getOperation}
                getSuplyStorageLocals={getSuplyStorageLocals}
            />
            {supplyStockMovements ? (
                <List
                    loading={isLoading}
                    columnsConfigs={columnsConfig}
                    list={supplyStockMovements?.items}
                    onChangePage={(page, size) => onChangeFilter({ ...filter, page, pageSize: size })}
                    onClickSort={orderBy => onChangeFilter({ ...filter, orderBy: orderBy.property, ascending: orderBy.ascending })}
                    pagination={pagination}
                />
            ) : (
                <div className={styles.emptyList}>
                    <img src="/assets/img/empty-box.png" alt="" />
                    <span>Filtre para exibir os resultados.</span>
                </div>
            )}
        </div>
    )
}
export default SupplyStockMovementList

