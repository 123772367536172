import React from "react";
import styles from "./Cashless.module.scss";
import { ICashless } from "../../interfaces/ICashless";
import { CircularProgress } from "@material-ui/core";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { moneyMaskNumber } from "services/utils/Money";
import { TooltipInfo } from "components/tooltipInfo/TooltipInfo";

interface ICashlessProps {
  cashlessData: ICashless | undefined
  isLoading: boolean | undefined
}

const Cashless: React.FC<ICashlessProps> = ({
  cashlessData, 
  isLoading, 
}) => {
  const {isMobile} = useUi();

  return (
    <div id={styles.Cashless}>
      <div className={styles.topTitle}>
        <p>Cashless</p>
      </div>
      <div className={!isMobile ? styles.container : styles.containerMobile }>
        <div className={styles.containerLeft}>
          <div className={styles.top}>
            <p>Total recarregado</p>
            {
              isLoading ? 
              <div className={styles.loading}><CircularProgress  size={16} color="inherit" /> </div>
              :
              <span>{moneyMaskNumber(cashlessData?.totalRechargered)}</span>
            }
          </div>
          <div className={styles.bottom}>
            <div>
              <p>Total consumido</p>
              {
              isLoading ? 
              <div className={styles.loading}><CircularProgress  size={16} color="inherit" /> </div>
              :
              <span>{moneyMaskNumber(cashlessData?.totalConsumed)}</span>
              }
            </div>
            <div>
              <p>Taxa de serviço</p>
              {
              isLoading ? 
              <div className={styles.loading}><CircularProgress  size={16} color="inherit" /> </div>
              :
              <span>{moneyMaskNumber(cashlessData?.totalServiceFee)}</span>
              }
            </div>
            <div>
              <p>Desconto</p>
              {
              isLoading ? 
              <div className={styles.loading}><CircularProgress  size={16} color="inherit" /> </div>
              :
              <span>{moneyMaskNumber(cashlessData?.discount)}</span>
              }
            </div>
            <div>
              <p className={styles.residual}>Residual <TooltipInfo text={"Total Recarregado - Total Consumido - Taxa de Serviço"} /></p>
              {
              isLoading ? 
              <div className={styles.loading}><CircularProgress  size={16} color="inherit" /> </div>
              :
              <span>{moneyMaskNumber(cashlessData?.residual)}</span>
              }
            </div>          
          </div>
        </div>
        {
          !isMobile &&
          <div className={styles.separator}></div>
        }
        <div  className={styles.containerRight}>
          <div  className={styles.top}>
            <p>Total de cartões</p>
            {
              isLoading ? 
              <div className={styles.loading}><CircularProgress  size={16} color="inherit" /> </div>
              :
              <span className={styles.valueTop}>{cashlessData?.totalCards ?? 0}</span>
            }
          </div>
          <div  className={styles.bottom}>
            <div>
              <p className={styles.totalText}>Cartões ativos</p>
              {
              isLoading ? 
                <div className={styles.loading}><CircularProgress  size={16} color="inherit" /> </div>
                :
                <p className={styles.totalValue}>{cashlessData?.activeCards ?? 0}</p>
              }
               {
              isLoading ? 
                <div className={styles.loading}><CircularProgress  size={16} color="inherit" /> </div>
                :
                <span className={styles.valueActivation}>(valor ativação: {moneyMaskNumber(cashlessData?.totalAmountActiveCards)})</span>
               }
            </div>
            <div>
              <p className={styles.totalText}>Cartões desativados</p>
              {
              isLoading ? 
                <div className={styles.loading}><CircularProgress  size={16} color="inherit" /> </div>
                :
                <p className={styles.totalValue}>{cashlessData?.deactivatedCards ?? 0}</p>
              }
               {
                isLoading ? 
                <div className={styles.loading}><CircularProgress  size={16} color="inherit" /> </div>
                :
                <span className={styles.valueDeactivation}>(valor desativação: {moneyMaskNumber(cashlessData?.totalDeactivatedCards)})</span>
               }
            </div> 
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cashless;
