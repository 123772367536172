import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { Accordion, AccordionDetails, AccordionSummary, MenuItem, Select, Switch, TextField } from '@mui/material';

import styles from './SaasOnlineSale.module.scss';
import UseConfigValues from '../../hooks/UseConfigValues';
import { UseGestaoVendas } from '../../hooks/UseGestaoVendas';

const useStyles = makeStyles({
    root: {
        '&.MuiAccordion-root': {
            border: "none",
            borderRadius: "10px",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
            "&.Mui-expanded": {
                margin: 0,
            },
            "&::before": {
                display: "none",
            },
            '&.MuiAccordion-root:first-of-type': {
                borderRadius: "10px",
            },
            '&.MuiAccordion-root:last-of-type': {
                borderRadius: "10px",
            },
            '@media (max-width: 400px)': {
                boxShadow: "none",
                border: "1px solid #DFCDFF"
            }
        },
    },
});

const SaasOnlineSale = () => {
    const accordionStyle = useStyles();
    const { onConfigChangeHandle, getConfigData, getCatalogList, catalogList, dataConfig } = UseGestaoVendas(true);
    const { configValues } = UseConfigValues();

    const [expand, setExpand] = useState(false);

    useEffect(() => {
        getConfigData();
        getCatalogList();
    }, [getCatalogList, getConfigData]);

    return (
        <Accordion
            elevation={0}
            expanded={expand}
            className={`${accordionStyle.root} ${styles.rootMobile}`}>
            <AccordionSummary style={{ cursor: "default" }}>
                <div id={styles.accordionSummary}>
                    <div className={styles.header}>
                        <div className={styles.start}>
                            <div className={styles.iconContainer}>
                                <img src="/assets/icon/saas/icon-venda-online.svg" alt="ícone venda online" />
                            </div>
                            <span>Venda online</span>
                        </div>

                        <div className={styles.end}>
                            <button onClick={() => setExpand(!expand)}>
                                {expand ? <ExpandLess /> : <ExpandMore />}
                            </button>
                        </div>
                    </div>
                </div>
            </AccordionSummary>

            <AccordionDetails>
                <div style={{ width: '100%' }}>
                    <div className={styles.onlineSalesConfig}>
                        <div>
                            <div className={styles.onlineSalesConfigItem}>
                                <div className={styles.onlineSalesConfigItemTexts}>
                                    <span className={styles.onlineSalesConfigItemTitle}>Permitir consumir online</span>
                                    <span className={styles.onlineSalesConfigItemSubtitle}>Permite que o cliente adicione itens no carrinho de compra</span>
                                </div>
                                <div>
                                    <Switch
                                        checked={configValues.enableShoppingCartOnVirtualMenu}
                                        name="enableShoppingCartOnVirtualMenu"
                                        onChange={(ev) => onConfigChangeHandle(ev.target.name, ev.target.checked)}
                                    />
                                </div>
                            </div>

                            <div className={styles.onlineSalesConfigItem}>
                                <div className={styles.onlineSalesConfigItemTexts}>
                                    <span className={styles.onlineSalesConfigItemTitle}>Habilitar pagamento online</span>
                                    <span className={styles.onlineSalesConfigItemSubtitle}>Permite que o cliente use a forma de pagamento crédito para pagar pelos produtos</span>
                                </div>
                                <div>
                                    <Switch
                                        checked={configValues.allowOnlinePaymentWeb}
                                        name="allowOnlinePaymentWeb"
                                        onChange={(ev) => onConfigChangeHandle(ev.target.name, ev.target.checked)}
                                        disabled={!dataConfig?.allowOnlinePayment}
                                    />
                                </div>
                            </div>

                            <div className={styles.onlineSalesConfigItem}>
                                <div className={styles.onlineSalesConfigItemTexts}>
                                    <span className={styles.onlineSalesConfigItemTitle}>Habilitar taxa de serviço</span>
                                    <span className={styles.onlineSalesConfigItemSubtitle}>Porcentagem paga pelos clientes sob o valor do pedido</span>
                                </div>
                                <div>
                                    <Switch
                                        checked={configValues.enableServiceTax}
                                        name="enableServiceTax"
                                        onChange={(ev) => onConfigChangeHandle(ev.target.name, ev.target.checked)}
                                    />
                                </div>
                            </div>

                            <div>
                                <label className={styles.onlineSalesConfigInputLabel}>Taxa de serviço</label>

                                <TextField
                                    variant='outlined'
                                    size="small"
                                    placeholder="Digite a porcentagem"
                                    value={`${!!configValues.serviceTaxPercentage ? configValues.serviceTaxPercentage : 0}%`}
                                    name="serviceTaxPercentage"
                                    onChange={(ev) => onConfigChangeHandle(ev.target.name, Number(ev.target.value.match(/\d+/g)?.join("")))}
                                    disabled={!configValues.enableServiceTax}
                                />
                            </div>
                        </div>

                        <div>
                            <label className={styles.onlineSalesConfigInputLabel}>Cardápio vinculado</label>

                            <Select
                                variant='outlined'
                                style={{ margin: 0, width: '100%' }}
                                size="small"
                                placeholder="Selecione um cardápio"
                                name="catalogWebId"
                                value={configValues?.catalogWebId ?? null}
                                onChange={(ev) => {
                                    if (ev.target.name) {
                                        const newValue = ev.target.value as string;
                                        onConfigChangeHandle(ev.target.name, newValue);
                                    }
                                }}>
                                {catalogList.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.description}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                    </div>
                </div>
            </AccordionDetails>
        </Accordion>
    )
}

export default SaasOnlineSale;