import { useLocal } from "modules/local/presentation/context/LocalContext";
import { MutableRefObject, useCallback, useState } from "react";
import GeneralDashboardApi from "services/api/generalDashboard/GeneralDashboardApi";
import { IFilterValue } from "../interfaces/IFilter";
import { ITicket } from "../interfaces/ITicket";
import GetTicketUseCase from "modules/generalDashboard/application/useCases/GetTicketUseCase";
import { ICashierEvent } from "../interfaces/ICashierEvent";

const UseTicket = (filter: IFilterValue | undefined, isLoadingCashier: boolean, lastCashierOpenData: MutableRefObject<ICashierEvent | undefined>, isLoadingFinancialInfo: boolean | undefined) => {

  const [isLoading, setIsLoading] = useState<boolean>();
  const [ticketData, setTicketData] = useState<ITicket>()
  const { currentLocal } = useLocal();

  const getTicket = useCallback(
    async () => {
      try {
        const serviceGeneral = GeneralDashboardApi();
        setIsLoading(true);
        if(!isLoadingCashier && !isLoadingFinancialInfo && currentLocal) {
          const cashierIds =
          filter?.cashierIds && filter.cashierIds.length > 0
            ? filter.cashierIds
            : lastCashierOpenData.current?.id
            ? [lastCashierOpenData.current.id]
            : undefined;
          
            if (!cashierIds) return;

          const response = await GetTicketUseCase(serviceGeneral,
            currentLocal.id,     
            {
              ...filter,
              cashierIds: cashierIds,
            });

          setTicketData(response);

          return response;
        }
      } finally {
        setIsLoading(false);
      }
    },
    [currentLocal, filter, isLoadingCashier, isLoadingFinancialInfo, lastCashierOpenData]
  );

  return {
    ticketData,
    isLoading,
    getTicket,
    setTicketData,
  };
};

export default UseTicket;
