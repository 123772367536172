import { ChangeEvent, useCallback, useState } from "react";
import { MenuItem, Select, TextField } from "@mui/material";
import { format } from "date-fns";
import { LocalizationProvider } from "@mui/lab";
import { ptBR } from "date-fns/locale";
import AdapterDateFns from "@mui/lab/AdapterDateFns";

import { UseSalesByDayPage } from "../../hooks/UseSaleForDayPage";
import { IGetSalesByDayParams } from "modules/SaleForDayPage/domain/dto/IGetSalesByDayParams";
import styles from "./SalesByDayFilter.module.scss";
import Button from "components/ui/Button/Button";
import Utils from 'services/utils/Utils';

const DEFAULT_FILTER = {
    dataFim: '',
    dataInicio: '',
    dateType: 1,
    equipamentoIds: [],
    localId: '',
    profileId: '',
    ShouldUseNewSchema: false,
}

export interface IForProductFilterProps {
    setParams: (params: IGetSalesByDayParams) => void;
}

const SalesByDayFilter = ({ setParams }: IForProductFilterProps) => {
    const { isLoading, equipmentList, posList } = UseSalesByDayPage();
    const [filters, setFilters] = useState<IGetSalesByDayParams>(DEFAULT_FILTER);

    const changeHandleDate = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
        if (!ev.target.value) {
            setFilters((prev) => ({ ...prev, [ev.target.name]: '' }));
            return;
        }

        const selectedDateTime = new Date(ev.target.value);
        const dateTimeInUTC = selectedDateTime.toISOString();
        setFilters((prev) => ({ ...prev, [ev.target.name]: dateTimeInUTC }));
    }, []);

    const handleFilterValue = (event: any) => {
        setFilters(prev => ({ ...prev, [event.target.name]: event.target.value }))
    }

    const handleFilters = useCallback(() => {
        setParams({
            ...filters,
            dataInicio: format(new Date(filters.dataInicio.substring(0, filters.dataInicio.length - 1)), 'yyyy-MM-dd'),
            dataFim: format(new Date(filters.dataFim.substring(0, filters.dataFim.length - 1)), 'yyyy-MM-dd'),
        });
    }, [filters, setParams]);

    return (
        <div id={styles.ForProductFilter}>
            <div className={styles.displayRow} style={{ justifyContent: "flex-start" }}>
                <div className={styles.input}>
                    <span>Equipamentos</span>
                    <Select
                        value={filters.equipamentoIds}
                        name="equipamentoIds"
                        size="small"
                        multiple
                        onChange={(ev) => handleFilterValue(ev)}
                    >
                        {equipmentList.map((option: any) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </div>

                <div className={styles.input}>
                    <span className={styles.required}>Inicio</span>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                        <TextField
                            size='small'
                            name="dataInicio"
                            onChange={changeHandleDate}
                            fullWidth
                            value={filters.dataInicio && Utils.toInputDateStringNoTime(filters.dataInicio)}
                            type="date"
                            inputProps={{ max: Utils.toInputDateStringNoTime(filters.dataFim) }}
                        />
                    </LocalizationProvider>
                </div>

                <div className={styles.input}>
                    <span className={styles.required}>Fim</span>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                        <TextField
                            size='small'
                            name="dataFim"
                            onChange={changeHandleDate}
                            fullWidth
                            value={filters.dataFim && Utils.toInputDateStringNoTime(filters.dataFim)}
                            inputProps={{ min: Utils.toInputDateStringNoTime(filters.dataInicio) }}
                            type="date" />
                    </LocalizationProvider>
                </div>

                <div className={styles.input}>
                    <span>Tipo de data</span>
                    <Select
                        value={filters.dateType}
                        name="dateType"
                        size="small"
                        onChange={(ev) => handleFilterValue(ev)}
                    >
                        <MenuItem value="1">Data da venda</MenuItem>
                        <MenuItem value="2">Data do faturamento</MenuItem>
                    </Select>
                </div>

                <div className={styles.input}>
                    <span>PDV</span>
                    <Select
                        value={filters.profileId}
                        name="profileId"
                        size="small"
                        onChange={(ev) => handleFilterValue(ev)}
                    >
                        {posList.map((option: any) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </div>

                <Button variant="text" onClick={() => setFilters(DEFAULT_FILTER)}>Limpar</Button>
                <Button color="secondary" onClick={handleFilters} disabled={(!filters.dataInicio || !filters.dataFim) || isLoading}>Buscar</Button>
            </div>
        </div>
    )
}

export default SalesByDayFilter;