import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import Sidesheet from "components/sidesheet/Sidesheet";
import TabView from "components/tab/TabView";
import SidesheetFeedback from "components/sidesheet/sidesheetFeedback/SidesheetFeedback";
import styles from "./AddPosFragment.module.scss";
import { InfoOutlined } from "@material-ui/icons";
import Input from "components/ui/input/Input";
import { UseAddPos } from "./UseAddPos";
import { Autocomplete, CircularProgress, FormControlLabel, Radio, TextField } from "@mui/material";
import { IDeviceTerminalItemResponse } from "modules/Terminal/application/dtos/GetDevicesTerminalResponse";
import { AcquirerTypeEnum } from "modules/config/deviceConfig/presentation/components/deviceProfile/profileForms/operationParamsForm/IOperationParamsValues";
import { usePlans } from "modules/plans/presentation/hooks/PlansContext";
import { ProspectType } from "modules/saas/domain/interfaces/ProspectTypeEnum";
import { useQuery } from "hooks/UseQuery";

export enum AddPosStepEnum {
  start = 0,
  posType = 1,
  form = 2,
  loading = 3,
  successCielo = 4,
  successExternalPos = 5,
  error = 6,
}

export enum CieloTypeEnum {
  smart = 1,
  simple = 2,
}

interface IAddPosProps {
    open: boolean;
    onClose: () => void;
    fromIntegrations?: "cieloSmart" | "cieloSimple" | AcquirerTypeEnum;
    terminalsList?: IDeviceTerminalItemResponse[];
}

const AddPosFragment = ({ open, onClose, fromIntegrations, terminalsList }: IAddPosProps) => {
  const {
    cieloSmartForm,
    error,
    cieloSimpleForm,
    setCieloSimpleForm,
    errorsSimpleForm,
    setErrorsSimpleForm,
    changeHandleSmart,
    handleSubmitSmart,
    changeHandleSimple,
    handleSubmitSimple,
    externalPosForm,
    errorsExternalPos,
    changeHandleExternal,
    handleSubmitExternalPos,
    handleClearErrors
  } = UseAddPos();
  const { subscription } = usePlans();
  const query = useQuery();
  
  const showAcquirerTypes = query.get("showAcquirerTypes") === "true";

  const [step, setStep] = useState(0);
  const [posType, setPosType] = useState<CieloTypeEnum>();
  const [selectedPosAcquirerType, setSelectedPosAcquirerType] = useState<AcquirerTypeEnum>();

  const changeHandleAcquirerType = useCallback((value: AcquirerTypeEnum) => {
    const changeEvent = {
      target: { name: "acquirerType", value: value.toString() } as EventTarget & HTMLInputElement,
    } as ChangeEvent<HTMLInputElement>;
    changeHandleExternal(changeEvent);
    setSelectedPosAcquirerType(Number(value));
  }, [changeHandleExternal]);
  
  useEffect(() => {
    if (fromIntegrations) {
      setStep(AddPosStepEnum.form);
      if (fromIntegrations === "cieloSmart" || fromIntegrations === "cieloSimple") {
        setPosType(fromIntegrations === "cieloSmart" ? CieloTypeEnum.smart : CieloTypeEnum.simple);
        return setSelectedPosAcquirerType(AcquirerTypeEnum.Cielo);
      }
      return changeHandleAcquirerType(fromIntegrations);
    }
  }, [changeHandleAcquirerType, fromIntegrations]);

  const handleOnClose = useCallback(() => {
    onClose();
    setStep(0);
  }, [onClose]);

  const showOtherAcquirerTypes = useMemo(() => 
    showAcquirerTypes &&
    subscription?.local.creationStep &&
    subscription.local.creationStep !== ProspectType.Cielo &&
    subscription.local.creationStep !== ProspectType.CieloEstablishment &&
    subscription.local.creationStep !== ProspectType.CieloEvent,
  [showAcquirerTypes, subscription]);

  const handleShowTabView = useCallback(() => {
    if (step === AddPosStepEnum.loading || step === AddPosStepEnum.successCielo || step === AddPosStepEnum.successExternalPos || step === AddPosStepEnum.error || fromIntegrations) {
      return undefined;
    }
    return (
      <TabView
        index={step || AddPosStepEnum.start}
        tabs={[
          {
            label: "Início",
            value: AddPosStepEnum.start,
          },
          {
            label: "Utilização",
            value: AddPosStepEnum.posType,
            hidden: selectedPosAcquirerType !== AcquirerTypeEnum.Cielo
          },
          {
            label: "Dados da máquina",
            value: AddPosStepEnum.form,
          },
        ]}
        onChangeTab={(tab) => {
          if (tab.value === AddPosStepEnum.form && ((selectedPosAcquirerType === AcquirerTypeEnum.Cielo && !posType) || !selectedPosAcquirerType)) return;
          setStep(tab.value)
        }}
      />
    );
  }, [step, fromIntegrations, selectedPosAcquirerType, posType]);

  const contentByStep = useMemo(() => {
    if (step === AddPosStepEnum.successCielo || step === AddPosStepEnum.successExternalPos) {
      return <div />;
    }
    switch (step) {
      case AddPosStepEnum.start:
        return (
          <div className={styles.startStep}>
            <p>Utilize o sistema PDV da Meep na sua máquina e centralize sua gestão. Para começar, selecione qual  você utiliza:</p>
            <button
              name="acquirerType"
              value={AcquirerTypeEnum.Cielo}
              onClick={() => changeHandleAcquirerType(AcquirerTypeEnum.Cielo)}
              style={{ borderColor: selectedPosAcquirerType === AcquirerTypeEnum.Cielo ? "#32008E" : "" }}
            >
              <img src="/assets/icon/logo-cielo.png" alt="" height={33}/>
            </button>
            {showOtherAcquirerTypes && (
              <div>
                <button
                  name="acquirerType"
                  value={AcquirerTypeEnum.Stone}
                  onClick={() => changeHandleAcquirerType(AcquirerTypeEnum.Stone)}
                  style={{ borderColor: selectedPosAcquirerType === AcquirerTypeEnum.Stone ? "#32008E" : "" }}
                >
                  <img src="/assets/icon/logo-stone.png" alt="" height={56}/>
                </button>
                <button
                  name="acquirerType"
                  value={AcquirerTypeEnum.PagSeguro}
                  onClick={() => changeHandleAcquirerType(AcquirerTypeEnum.PagSeguro)}
                  style={{ borderColor: selectedPosAcquirerType === AcquirerTypeEnum.PagSeguro ? "#32008E" : "" }}
                >
                  <img src="/assets/icon/logo-pagseguro.png" alt="" height={30}/>
                </button>
                <button
                  name="acquirerType"
                  value={AcquirerTypeEnum.GetNet}
                  onClick={() => changeHandleAcquirerType(AcquirerTypeEnum.GetNet)}
                  style={{ borderColor: selectedPosAcquirerType === AcquirerTypeEnum.GetNet ? "#32008E" : "" }}
                >
                  <img src="/assets/icon/logo-getnet-bigger.png" alt="" height={40}/>
                </button>
              </div>
            )}
          </div>
        );
      case AddPosStepEnum.posType:
        return (
          <div className={styles.typeStep}>
            <div className={styles.posOption}>
              <FormControlLabel
                  checked={posType === CieloTypeEnum.smart}
                  control={<Radio color="primary" />}
                  value={CieloTypeEnum.smart}
                  label={<p>Usar a máquina como PDV</p>}
                  labelPlacement="end"
                  onChange={() => setPosType(CieloTypeEnum.smart)}
              />
              <span className={styles.optionDetail}>Baixe o App da Meep na sua máquina. Depois disso, você poderá enviar pedidos e pagar diretamente pela máquina.</span>
              {posType === CieloTypeEnum.smart && (
                <img className={styles.posOptionImage} src="/assets/icon/saas/gif-pos-com-pdv.gif" alt=""/>
              )}
            </div>
            <div className={styles.posOption}>
              <FormControlLabel
                  checked={posType === CieloTypeEnum.simple}
                  control={<Radio color="primary" />}
                  value={CieloTypeEnum.simple}
                  label={<p>Usar a máquina integrada ao Terminal Online</p>}
                  labelPlacement="end"
                  onChange={() => setPosType(CieloTypeEnum.simple)}
              />
              <span className={styles.optionDetail}>Faça pedidos pelo Terminal Online e envie o valor a ser pago para sua máquina, de forma integrada.</span>
              {posType === CieloTypeEnum.simple && (
                <img className={styles.posOptionImage} src="/assets/icon/saas/gif-terminal.gif" alt=""/>
              )}
            </div>
          </div>
        );
      case AddPosStepEnum.form:
        return (
          <div className={styles.formStep}>
            {selectedPosAcquirerType === AcquirerTypeEnum.Cielo && (
              <>
                {posType === CieloTypeEnum.smart && (
                  <>
                    <div className={styles.formInfo}>
                      <InfoOutlined />
                      <span>Para encontrar as informações, baixe o App da Meep na loja de aplicativos e clique no ícone.</span>
                    </div>
                    <div>
                      <label htmlFor="imei" className={styles.required}>
                        Número de IMEI
                      </label>
                      <Input
                        name="imei"
                        variant="outlined"
                        placeholder="Esse número identifica sua POS"
                        value={cieloSmartForm.imei}
                        onChange={changeHandleSmart}
                        error={!!error}
                        helperText={error}
                        type="number"
                      />
                    </div>
                  </>
                )}
                {posType === CieloTypeEnum.simple && (
                  <>
                    <div className={styles.formInfo}>
                      <InfoOutlined />
                      <span>O ID do POS encontra-se no seu Portal Cielo.</span>
                    </div>
                    <div>
                      <label htmlFor="name" className={styles.required}>
                        Nome da POS
                      </label>
                      <Input
                        name="name"
                        variant="outlined"
                        placeholder="Esse é o nome da sua POS. Ele ficará visível para você."
                        value={cieloSimpleForm.name}
                        onChange={changeHandleSimple}
                        error={!!errorsSimpleForm.name}
                        helperText={errorsSimpleForm.name}
                      />
                    </div>
                    <div>
                      <label htmlFor="posId" className={styles.required}>
                        ID da POS
                      </label>
                      <Input
                        name="posId"
                        variant="outlined"
                        placeholder="Esse número identifica sua POS"
                        value={cieloSimpleForm.posId}
                        onChange={changeHandleSimple}
                        error={!!errorsSimpleForm.posId}
                        helperText={errorsSimpleForm.posId}
                      />
                      <a href="https://www.youtube.com/@MeepConnect" target="_blank" rel="noreferrer">Não encontrei esse dado, e agora?</a>
                    </div>
                    <div>
                      <label htmlFor="terminalId" className={styles.required}>
                        Em qual terminal a máquina será usada?
                      </label>
                        <Autocomplete
                          componentName="terminalId"
                          value={terminalsList?.find((terminal) => terminal.device.id === cieloSimpleForm.deviceId)}
                          onChange={(event: any, newValue: IDeviceTerminalItemResponse | null) => {
                            setErrorsSimpleForm((prev) => ({ ...prev, terminalId: "" }));
                            setCieloSimpleForm((prev) => ({ ...prev, deviceId: newValue?.device.id ?? "" }));
                          }}
                          options={terminalsList ?? []}
                          getOptionLabel={(option) => option.device.name}
                          noOptionsText="Nenhuma opção encontrada"
                          renderInput={(params) => (
                              <TextField
                                {...params}
                                name="terminalId"
                                placeholder="Selecione um terminal"
                                variant="outlined"
                                error={!!errorsSimpleForm.terminalId}
                                helperText={errorsSimpleForm.terminalId}
                              />
                          )}
                          fullWidth
                      />
                    </div>
                  </>
                )}
              </>
            )}
            {selectedPosAcquirerType === AcquirerTypeEnum.Stone && (
              <>
                <div className={styles.formInfo}>
                  <InfoOutlined />
                  <span>Você encontra essa informação na maquininha por aqui:  Ajuda {">"} Detalhes da máquina {">"} Stone Code.</span>
                </div>
                <div>
                  <label htmlFor="integrationCode" className={styles.required}>
                    Stone Code
                  </label>
                  <Input
                    name="integrationCode"
                    variant="outlined"
                    value={externalPosForm.integrationCode}
                    onChange={changeHandleExternal}
                    error={!!errorsExternalPos.integrationCode}
                    helperText={errorsExternalPos.integrationCode}
                  />
                </div>
              </>
            )}
            {selectedPosAcquirerType === AcquirerTypeEnum.GetNet && (
              <>
                <div className={styles.formInfo}>
                  <InfoOutlined />
                  <span>Para encontrar as informações, baixe o App da Meep na loja de aplicativos e clique no ícone.</span>
                </div>
                <div>
                  <label htmlFor="integrationCode" className={styles.required}>
                    Número de IMEI
                  </label>
                  <Input
                    name="integrationCode"
                    variant="outlined"
                    value={externalPosForm.integrationCode}
                    onChange={changeHandleExternal}
                    error={!!errorsExternalPos.integrationCode}
                    helperText={errorsExternalPos.integrationCode}
                  />
                </div>
              </>
            )}
            {selectedPosAcquirerType !== AcquirerTypeEnum.Cielo && (
              <>
                <div>
                  <label htmlFor="serialNumber" className={styles.required}>
                    Número de série
                  </label>
                  <Input
                    name="serialNumber"
                    variant="outlined"
                    placeholder="Esse número identifica sua POS"
                    value={externalPosForm.serialNumber}
                    onChange={changeHandleExternal}
                    error={!!errorsExternalPos.serialNumber}
                    helperText={errorsExternalPos.serialNumber}
                  />
                </div>
                <a href="#" target="_blank" rel="noreferrer" style={{ marginRight: "auto" }}>Como encontrar o número de série?</a>
              </>
            )}
          </div>
        );
      case AddPosStepEnum.loading:
        return (
          <div className={styles.loadingStep}>
            <CircularProgress color="inherit" />
        </div>
        );
      case AddPosStepEnum.error:
        return (
          <SidesheetFeedback
            text="Erro ao vincular máquina, confira os dados e tente novamente!"
          />
        );
      default:
        return <div />
    }
  }, [
    step,
    selectedPosAcquirerType,
    showOtherAcquirerTypes,
    posType,
    cieloSmartForm,
    changeHandleSmart,
    error,
    cieloSimpleForm,
    changeHandleSimple,
    errorsSimpleForm,
    terminalsList,
    externalPosForm,
    changeHandleExternal,
    errorsExternalPos,
    changeHandleAcquirerType,
    setErrorsSimpleForm,
    setCieloSimpleForm
  ]);
  
  const feedbackHandle = useMemo(() => {
    switch (step) {
      case AddPosStepEnum.successCielo:
        return (
          <SidesheetFeedback
            text="Integração realizada com sucesso!"
            success
          />
        );
      case AddPosStepEnum.successExternalPos:
        return (
          <SidesheetFeedback
            text="Máquina adicionada com sucesso!"
            helperText={
              <div className={styles.formInfo}>
                <InfoOutlined />
                <span>Em até 2 dias úteis sua maquininha será liberada para uso. Acompanhe pelo Portal Mee 😉</span>
              </div>
            }
            success
          />
        );
      default:
        return undefined;
    }
  }, [step]);

  const handleContinueButton = useCallback(async () => {
    if (step === AddPosStepEnum.start) {
      if (selectedPosAcquirerType === AcquirerTypeEnum.Cielo) return setStep(AddPosStepEnum.posType);
      return setStep(AddPosStepEnum.form);
    }
    if (step === AddPosStepEnum.posType && !posType) return;
    if (step === AddPosStepEnum.form) {
      setStep(AddPosStepEnum.loading);

      let res: void | "ok" | "error";

      if (selectedPosAcquirerType === AcquirerTypeEnum.Cielo) {
        if (posType === CieloTypeEnum.smart) {
          res = await handleSubmitSmart();
        } else {
          res = await handleSubmitSimple();
        }
      } else {
        res = await handleSubmitExternalPos();
      }

      if (res === "ok") {
        setStep(selectedPosAcquirerType === AcquirerTypeEnum.Cielo ? AddPosStepEnum.successCielo : AddPosStepEnum.successExternalPos);
      } else if (selectedPosAcquirerType !== AcquirerTypeEnum.Cielo && externalPosForm.integrationCode && externalPosForm.serialNumber) {
        setStep(AddPosStepEnum.error);
      } else {
        setStep(AddPosStepEnum.form);
      }
      return;
    }
    if (step === AddPosStepEnum.error) {
      return handleOnClose();
    }
    return setStep(step + 1);
  }, [externalPosForm, handleOnClose, handleSubmitExternalPos, handleSubmitSimple, handleSubmitSmart, posType, selectedPosAcquirerType, step]);

  return (
    <Sidesheet
      open={open}
      onClose={handleOnClose}
      title={<h2>Adicionar <b>máquina</b></h2>}
      content={contentByStep}
      currentStep={step}
      tabView={handleShowTabView()}
      continueButton={step === AddPosStepEnum.error ? "Tentar novamente" : "Continuar"}
      handleContinueButton={handleContinueButton}
      disableContinueButton={step === AddPosStepEnum.start && !selectedPosAcquirerType}
      cancelButton
      customizedCancelButton={!fromIntegrations ? "Voltar" : "Cancelar"}
      handleCustomizedCancelButton={() => {
        if (selectedPosAcquirerType !== AcquirerTypeEnum.Cielo && step === AddPosStepEnum.form) {
          handleClearErrors();
          return setStep(AddPosStepEnum.start);
        }
        if (step !== AddPosStepEnum.start && step !== AddPosStepEnum.error && !fromIntegrations) {
          return setStep(step - 1);
        }
        handleOnClose();
      }}
      feedback={feedbackHandle}
    />
  );
}

export default AddPosFragment;