import { FormControlLabel, MenuItem, Switch } from '@material-ui/core'
import React, { ChangeEvent, FormEvent, useCallback, useEffect, useState } from 'react';
import FooterButtons from '../../../footerButtons/FooterButtons'
import styles from './OperationParamsForm.module.scss'
import { IOperationParamsValues } from './IOperationParamsValues'
import Input from 'components/ui/input/Input';
import FormItemContainer from 'components/ui/inputContainer/FormItemContainer';
import { RefundType } from 'modules/config/deviceConfig/domain/enum/RefundType';
import { Tooltip } from 'components/graphs/components/container/components/tooltip/Tooltip';
import { PrinterType } from 'modules/config/deviceConfig/domain/enum/PrinterType';

export interface IOperationParamsFormProps {
    defaultValue?: IOperationParamsValues;
    onModified: (modified: boolean) => void;
    onSubmit: (value: IOperationParamsValues) => void
    onClickBack: (value: IOperationParamsValues) => void
}

const OperationParamsForm: React.FC<IOperationParamsFormProps> = ({ defaultValue, onModified, onSubmit, onClickBack }) => {
    const [values, setValues] = useState<IOperationParamsValues>(defaultValue ?? {} as IOperationParamsValues);

    useEffect(() => {
        if (defaultValue) {
            setValues(defaultValue);
        }
    }, [defaultValue]);

    const onChangeChecked = useCallback((property: string, checked) => {
        onModified(true);
        setValues(prev => ({ ...prev, [property]: checked }));
    }, [onModified]);

    const onChangeHandle = (property: string, value: any) => {
        const properties = property.split('.');
        const fullObj = values[properties[0] as keyof IOperationParamsValues] as object;

        if (properties.length === 1) {
            setValues((prev) => ({ ...prev, [properties[0]]: value }));
        } else {
            setValues((prev) => ({ ...prev, [properties[0]]: { ...fullObj, [properties[1]]: value } }));
        }

        onModified(true);
    }

    const submitHandle = useCallback((ev: FormEvent<HTMLFormElement>) => {
        ev.preventDefault();
        onSubmit(values);
    }, [onSubmit, values]);

    const formatMoney = (value: number) => {
        if (!value) return '';
        return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value/100);
    }

    const handleChangeMoney = useCallback((ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const onlyNumbers = ev.target.value.replace(/\D/g,'');
        setValues((prev) => ({ ...prev, [ev.target.name]: parseInt(onlyNumbers, 10) }));
    }, []);

    return (
        <form id={styles.OperationParamsForm} onSubmit={submitHandle}>
            <div className={styles.container}>
                <FormItemContainer className={styles.formItemContainer} label={"Consumo máximo"}>
                    <Input 
                        placeholder="R$ 150,00"
                        variant={"outlined"} 
                        value={formatMoney(values.maximumConsumption)}
                        name={"maximumConsumption"}
                        onChange={handleChangeMoney}
                    />
                </FormItemContainer>
            
                <FormItemContainer className={styles.formItemContainer} label={"Consumo mínimo"}>
                    <Input 
                        placeholder="R$ 150,00"
                        variant={"outlined"} 
                        value={formatMoney(values.minimunConsumption)}
                        name={"minimunConsumption"}
                        onChange={handleChangeMoney}
                    />
                </FormItemContainer>
                
                <FormItemContainer className={styles.formItemContainer} label={"Tipo de desconto"}>
                    <Input 
                        value={values.refundType?.value || RefundType.disabled}
                        select
                        variant={'outlined'}
                        name={"refundType"}
                        onChange={(e) => onChangeHandle('refundType.value', e.target.value)}
                    >
                        <MenuItem value={RefundType.disabled}>Desabilitado</MenuItem>
                        <MenuItem value={RefundType.Partial}>Parcial</MenuItem>
                        <MenuItem value={RefundType.Total}>Total</MenuItem>
                    </Input>
                </FormItemContainer>

                <FormItemContainer className={styles.formItemContainer}>
                    <h4>Operações Gerais</h4>
                
                    <FormControlLabel
                        className={styles.switch} 
                        checked={values.isRequestTableNumberEnabled || false}
                        onChange={(_, checked) => onChangeChecked("isRequestTableNumberEnabled", checked)}
                        control={<Switch color='secondary' />} label={"Solicitar mesa ao consumir"}
                    />        
                  
                    <FormControlLabel
                        className={styles.switch} 
                        checked={values.isSendToKDSEnabled || false}
                        onChange={(_, checked) => onChangeChecked("isSendToKDSEnabled", checked)}
                        control={<Switch color='secondary' />} label={"Enviar pedido para o KDS"}
                    />

                    <h4> Exclusivos do Totem</h4>
                
                    <FormControlLabel
                        className={styles.switch} 
                        checked={values.isTakeOutOrder || false}
                        onChange={(_, checked) => onChangeChecked("isTakeOutOrder", checked)}
                        control={<Switch color='secondary' />} label={<p>Pedido para viagem</p>}
                    />        
                    
                    <FormControlLabel
                        className={styles.switch} 
                        checked={values.isShouldUsePRISM || false}
                        onChange={(_, checked) => onChangeChecked("isShouldUsePRISM", checked)}
                        control={<Switch color='secondary' />} label={<p>Solicitar número PRISMA</p>}
                    />

                    <FormItemContainer className={styles.formItemContainer} label={"Tipo de impressão"} >
                        <Input 
                            value={values.printType}
                            select
                            variant={'outlined'}
                            name={"printType"}
                            onChange={(e) => onChangeHandle('printType', e.target.value)}
                        >
                            <MenuItem value={PrinterType.Ticket}>Ficha</MenuItem>
                            <MenuItem value={PrinterType.OrderSummary}>Resumo pedido</MenuItem>
                            <MenuItem value={PrinterType.NotPrint}>Não imprimir</MenuItem>
                            <MenuItem value={PrinterType.GroupByCatory}>Agrupar por categoria</MenuItem>
                        </Input>
                    </FormItemContainer>

                    <h4>Operações Cashless</h4>

                    <FormControlLabel
                        className={styles.switch} 
                        checked={values.isMeepCard || false}
                        onChange={(_, checked) => onChangeChecked("isMeepCard", checked)}
                        control={<Switch color='secondary' />} label={<p>Utilizar como cartão nominal (Meep Card)<Tooltip title="Observação" text="Habilitando este paramêtro o cartão rotativo será desabilitado" icon="info" /></p>}
                    />

                    <FormControlLabel
                        className={styles.switch} 
                        checked={values.isConsumptionEnabled}
                        onChange={(ev, checked) => onChangeChecked("isConsumptionEnabled", checked)}
                        control={<Switch color='secondary' />} label={"Habilitar consumo"}
                    />

                    <FormControlLabel
                        className={styles.switch} 
                        checked={values.isRechargeEnabled || false}
                        onChange={(_, checked) => onChangeChecked("isRechargeEnabled", checked)}
                        control={<Switch color='secondary' />} label={"Habilitar recarga de cartão"}
                    />

                    <FormControlLabel
                        className={styles.switch} 
                        checked={values.isPostPaidRecharge || false}
                        onChange={(_, checked) => onChangeChecked("isPostPaidRecharge", checked)}
                        control={<Switch color='secondary' />} label={"Habilitar recarga no Pós-pago"}
                    />
                
                    <FormControlLabel
                        className={styles.switch} 
                        checked={values.isConsumptionOnActivationEnabled || false}
                        onChange={(_, checked) => onChangeChecked("isConsumptionOnActivationEnabled", checked)}
                        control={<Switch color='secondary' />} label={<p>Habilitar consumo na ativação<Tooltip title="Sistema" text="PÓS-PAGO" icon="info" /></p>}
                    />
                
                    <FormControlLabel
                        className={styles.switch} 
                        checked={values.isRequiredConsumptionOnActivationEnabled || false}
                        onChange={(_, checked) => onChangeChecked("isRequiredConsumptionOnActivationEnabled", checked)}
                        control={<Switch color='secondary' />} label={<p>Habilitar consumo obrigatório na ativação<Tooltip title="Sistema" text="PÓS-PAGO" icon="info" /></p>}
                    />
                
                    <FormControlLabel
                        className={styles.switch} 
                        checked={values.isDeactivationEnabled || false}
                        onChange={(_, checked) => onChangeChecked("isDeactivationEnabled", checked)}
                        control={<Switch color='secondary' />} label={"Habilitar desativação do cartão"}
                    />
                
                    <FormControlLabel
                        className={styles.switch} 
                        checked={values.isPaymentAfterCloseAccountEnabled || false}
                        onChange={(_, checked) => onChangeChecked("isPaymentAfterCloseAccountEnabled", checked)}
                        control={<Switch color='secondary' />} label={<p>Habilitar pagamento <b>somente</b> após fechamento da conta<Tooltip title="Sistema" text="PÓS-PAGO Compartilhado" icon="info" /></p>}
                    />

                    <FormControlLabel
                        className={styles.switch} 
                        checked={values.isPreValidateOrderEnabled || false}
                        onChange={(_, checked) => onChangeChecked("isPreValidateOrderEnabled", checked)}
                        control={<Switch color='secondary' />} label={<p>Habilitar pré-validação do estoque<Tooltip title="Observação" text="Se o local estiver sem internet a venda será bloqueada" icon="info" /></p>}
                    />
                
                    <FormControlLabel
                        className={styles.switch} 
                        checked={values.isActivationEnabled || false}
                        onChange={(_, checked) => onChangeChecked("isActivationEnabled", checked)}
                        control={<Switch color='secondary' />} label={"Permitir ativação de cartão"}
                    />
                
                    <FormControlLabel
                        className={styles.switch} 
                        checked={values.isRequestConsumerNumberEnabled || false}
                        onChange={(_, checked) => onChangeChecked("isRequestConsumerNumberEnabled", checked)}
                        control={<Switch color='secondary' />} label={<p>Solicitar número de consumidores<Tooltip title="Sistema" text="PÓS-PAGO Compartilhado" icon="info" /></p>}
                    />
                
                    <FormControlLabel
                        className={styles.switch} 
                        checked={values.ignoreValueToActivateCard || false}
                        onChange={(_, checked) => onChangeChecked("ignoreValueToActivateCard", checked)}
                        control={<Switch color='secondary' />} label={<p>Ignorar valor de ativação Pré-Pago Cashless?</p>}
                    />

                    <h4>Bloqueios</h4>
                    
                    <FormControlLabel
                        className={styles.switch} 
                        checked={values.isReplaceCardEnabled || false}
                        onChange={(_, checked) => onChangeChecked("isReplaceCardEnabled", checked)}
                        control={<Switch color='secondary' />} label={"Desabilitar substituição de cartão"}
                    />

                    <FormControlLabel
                        className={styles.switch} 
                        checked={values.isCloseSessionEnabled || false}
                        onChange={(_, checked) => onChangeChecked("isCloseSessionEnabled", checked)}
                        control={<Switch color='secondary' />} label={"Desabilitar fechamento/saída de caixa"}
                    />

                    <FormControlLabel
                        className={styles.switch} 
                        checked={values.isBlockConsumptionAfterPaymentEnabled || false}
                        onChange={(_, checked) => onChangeChecked("isBlockConsumptionAfterPaymentEnabled", checked)}
                        control={<Switch color='secondary' />} label={<p>Bloquear consumo após pagamento<Tooltip title="Sistema" text="PÓS-PAGO Individual" icon="info" /></p>}
                    />
                
                    <FormControlLabel
                        className={styles.switch} 
                        checked={values.isBlockConsumptionAfterCloseEnabled || false}
                        onChange={(_, checked) => onChangeChecked("isBlockConsumptionAfterCloseEnabled", checked)}
                        control={<Switch color='secondary' />} label={<p>Bloquear consumo após fechamento de comanda<Tooltip title="Sistema" text="PÓS-PAGO Compartilhado" icon="info" /></p>}
                    />

                </FormItemContainer>
            </div>
            <>
                <FooterButtons onClickBack={() => onClickBack(values)} labelNext={"Salvar"} nextType='submit'></FooterButtons>
            </>
        </form>
    )
}
export default OperationParamsForm