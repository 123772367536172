import { useLocal } from "modules/local/presentation/context/LocalContext";
import { MutableRefObject, useCallback, useState } from "react";
import GeneralDashboardApi from "services/api/generalDashboard/GeneralDashboardApi";
import { ITurnoverTable } from "../components/turnoverTable/interfaces/ITurnoverTable";
import GetTurnoverTableUseCase from "modules/generalDashboard/application/useCases/GetTurnoverTableUseCase";
import { IFilterValue } from "../interfaces/IFilter";
import { ICashierEvent } from "../interfaces/ICashierEvent";

export interface IParamsUseTurnover {
  pagination?: {
    page?: number;
    pageSize?: number;
  };
  sortByTable?: boolean;
  localId?: string;
}

const UseTurnoverTable = (filter: IFilterValue | undefined, isLoadingCashier: boolean, lastCashierOpenData: MutableRefObject<ICashierEvent | undefined>, isLoadingFinancialInfo: boolean | undefined) => {

  const [isLoading, setIsLoading] = useState<boolean>();
  const [turnoverTableData, setTurnoverTableData] = useState<ITurnoverTable>()
  const { currentLocal } = useLocal();

  const getTurnoverTable = useCallback(
    async (params?: IParamsUseTurnover) => {
      try {
        const serviceGeneral = GeneralDashboardApi();
        setIsLoading(true);
        if (!isLoadingCashier && !isLoadingFinancialInfo && currentLocal) {
          const cashierIds = filter?.cashierIds && filter.cashierIds.length > 0
            ? filter.cashierIds
            : lastCashierOpenData.current?.id
            ? [lastCashierOpenData.current.id]
            : undefined;

          if (!cashierIds) return;

          const response = await GetTurnoverTableUseCase(serviceGeneral,
            currentLocal.id,   
            {
              ...filter,
              cashierIds: cashierIds,
            }  ,
            params?.sortByTable ?? true,
            params?.pagination);

          setTurnoverTableData(response);
          return response;
        }
      } finally {
        setIsLoading(false);
      }
    },
    [currentLocal, filter, isLoadingCashier, isLoadingFinancialInfo, lastCashierOpenData]
  );

  return {
    turnoverTableData,
    isLoading,
    getTurnoverTable,
    setTurnoverTableData,
  };
};

export default UseTurnoverTable;
