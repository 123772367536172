import Layout from 'components/layout/presentation/Layout';
import styles from './TaxTable.module.scss';
import { RightDrawer } from 'components/layout/presentation/rightDrawer/RightDrawer';
import { useCallback, useEffect, useState } from 'react';
import { ICompany } from '../interfaces/ICompany';
import InvoiceApi from 'services/api/invoice/InvoiceApi';
import { useLocal } from 'modules/local/presentation/context/LocalContext';
import { useUi } from 'contexts/userInterface/UserInterfaceContext';
import { GetTaxUseCase } from 'modules/invoice/application/useCases/GetTaxUseCase';
import { IPaginatedTax } from '../interfaces/IPaginatedTax';
import { TaxItem } from './TaxItem/TaxItem';
import { MenuItem, Select } from '@mui/material';
import { Icon, TextField } from '@material-ui/core';
import { ImportCsvModal } from '../components/ImportCsvModal/ImportCsvModal';
import { GetTaxFileUrlUseCase } from 'modules/invoice/application/useCases/GetTaxFileUseCase';
import { ImportInvoiceConfigFromCsvUseCase } from 'modules/invoice/application/useCases/ImportInvoiceConfigFromCsvUseCase';
import { useHistory } from 'react-router-dom';
import { Pagination } from '@material-ui/lab';
import { EditInvoiceModal } from '../components/EditInvoiceModal/EditInvoiceModal';
import { ChangeEnvironmentModal } from '../components/ChangeEnvironmentModal/ChangeEnvironmentModal';
import { EnvironmentEnum } from '../interfaces/EnvironmentEnum';
import { UpdateEnvironmentUseCase } from 'modules/invoice/application/useCases/UpdateEnvironmentUseCase';
import { GetEnvironmentUseCase } from 'modules/invoice/application/useCases/GetEnvironmentUseCase';
import { DeleteTaxUseCase } from 'modules/invoice/application/useCases/DeleteTaxUseCase';
import Button from 'components/ui/Button/Button';
import { AddCircle } from '@material-ui/icons';
import { Upload } from '@mui/icons-material';

interface TaxTableProps {
    company: ICompany;
}

const service = InvoiceApi();

export const TaxTable = ({ company }: TaxTableProps) => {
    const [editInvoiceModalOpened, setEditInvoiceModalOpened] = useState(false);
    const [editEnvironmentModalOpened, setEditEnvironmentModalOpened] = useState(false);
    const [environment, setEnvironment] = useState<EnvironmentEnum>(EnvironmentEnum.HOMOLOGATION);
    const [importCsvModalOpened, setImportCsvModalOpened] = useState(false);
    const [loading, setLoading] = useState(false);
    const [preLoading, setPreLoading] = useState(true);
    const [data, setData] = useState({} as IPaginatedTax);
    const [currentPage, setCurrentPage] = useState(0);
    const [filterType, setFilterType] = useState('NCM');
    const [filter, setFilter] = useState('');
    const [taxFileUrl, setTaxFileUrl] = useState('');
    const [uploadErrors, setUploadErrors] = useState<string[]>([]);

    const { currentLocal } = useLocal();
    const { toast } = useUi();
    const history = useHistory();

    const handleSubmitCsv = async (csv: string) => {
        try {
            setLoading(true);
            await ImportInvoiceConfigFromCsvUseCase(service, currentLocal!.id, csv);
            setImportCsvModalOpened(false);
            toast('Importação feita com sucesso', 'success');
            getData();
        } catch (err: any) {
            if (err.response.data.statusCode === 422) {
                const errors = err.response.data.message.split('<br />');
                setUploadErrors(errors);
            } else {
                toast('Erro ao importar o arquivo', 'error');
            }
        }
        finally {
            setLoading(false);
        }
    }

    const getData = useCallback(async (page?: number, keyword?: string) => {
        if (currentLocal) {
            try {
                setLoading(true);
                const requestPage = page ? page - 1 : 0;
                const response = await GetTaxUseCase(service, currentLocal.id, keyword, requestPage);
                setData(response);
                setCurrentPage(requestPage);
            } finally {
                setLoading(false);
                setPreLoading(false);
            }
        }
    }, [currentLocal]);

    useEffect(() => {
        (async () => {
            try {
                if (currentLocal) {
                    const [taxFileUrlResponse, environmentResponse] = await Promise.all([
                        GetTaxFileUrlUseCase(service, currentLocal.id),
                        GetEnvironmentUseCase(service, currentLocal!.id),
                        getData()
                    ]);

                    setTaxFileUrl(taxFileUrlResponse);
                    setEnvironment(environmentResponse);
                }
            } finally {
                setPreLoading(false);
            }
        })();
    }, [currentLocal, getData]);

    const handleFilter = () => {
        getData(0, filter);
    }

    const handleInvoice = () => {
        setEditInvoiceModalOpened(false);
    }

    const handleChangeEnvironment = async () => {
        try {
            setLoading(true);
            await UpdateEnvironmentUseCase(service, currentLocal!.id, environment);
            toast('Ambiente alterado com sucesso', 'success');
            setEditEnvironmentModalOpened(false);
        } catch {
            toast('Houve um erro ao alterar o ambiente. Tente novamente.', 'error');
            setEnvironment(prev => prev === EnvironmentEnum.HOMOLOGATION ? EnvironmentEnum.PRODUCTION : EnvironmentEnum.HOMOLOGATION)
        } finally {
            setLoading(false);
        }
    }

    const handleDelete = async (id: number) => {
        try {
            await DeleteTaxUseCase(service, currentLocal!.id, id.toString());
            setData(prev => ({ ...prev, data: prev?.data.filter(x => x.id !== id) }));
            toast('Registro excluído com sucesso.', 'success');
        } catch {
            toast('Ocorreu um erro ao deletar o registro.', 'error');
        }
    }

    if (preLoading !== false) {
        return (
            <Layout>
                <div className={styles.preLoading}>
                    <img src="/assets/icon/saas/loading.gif" alt="" />
                </div>
            </Layout>
        )
    }

    return (
        <Layout>
            <div className={styles.container}>
                <h1>Informações Fiscais</h1>
                <p>Com um conjunto de funcionalidades intuitivas e poderosas, estamos aqui para ajudá-lo a cumprir suas obrigações fiscais de forma eficiente e precisa.</p>

                <div className={styles.environmentContainer}>
                    <div>
                        <span>Escolha o seu <b>ambiente fiscal</b></span>
                        <span>Ambiente atual: <b>{environment === EnvironmentEnum.PRODUCTION ? 'Produção' : 'Homologação'}</b></span>
                    </div>

                    <Button variant="outlined" onClick={() => setEditEnvironmentModalOpened(true)}>
                        Alterar
                    </Button>
                </div>

                <div className={styles.headerButtons}>
                    <Button onClick={() => history.push('/private/invoice/tax/create')} endIcon={<AddCircle />}>
                        Adicionar dados fiscais
                    </Button>
                    <Button variant="outlined" onClick={() => setEditInvoiceModalOpened(true)}>
                        Editar informações do local
                    </Button>
                    <Button variant="text" className={styles.ghostButton} onClick={() => setImportCsvModalOpened(true)} endIcon={<Upload />}>
                        Importar
                    </Button>
                </div>

                <div className={styles.filter}>
                    <div className={styles.column}>
                        <label htmlFor="filterType">Filtrar por</label>
                        <Select
                            name="filterType"
                            value={filterType}
                            size="small"
                            onChange={(ev) => {
                                setFilterType(ev.target.value)
                                setFilter('');
                            }}
                        >
                            <MenuItem value={'NCM'}>
                                NCM
                            </MenuItem>
                            <MenuItem value={'CFOP'}>
                                CFOP
                            </MenuItem>
                        </Select>
                    </div>
                    <div className={styles.column}>
                        <label>{filterType}</label>
                        <TextField
                            variant="outlined"
                            fullWidth
                            size="small"
                            value={filter}
                            onChange={(event) => setFilter(event.target.value)}
                        />
                    </div>
                    <Button color="secondary" disabled={!filter} onClick={handleFilter}>
                        Buscar
                    </Button>
                </div>

                <div className={styles.tableContainer}>
                    <div className={styles.header}>
                        <div className={styles.column}>NCM</div>
                        <div className={styles.column}>CEST</div>
                        <div className={styles.column}>CFOP</div>
                        <div className={styles.column}>Origem</div>
                        <div className={styles.column}>ICMS</div>
                        <div className={styles.column}>CST PIS</div>
                        <div className={styles.column}>Alíquota PIS</div>
                        <div className={styles.column}>CST COFINS</div>
                        <div className={styles.column}>Alíquota COFINS</div>
                        <div className={styles.column}>CST IPI</div>
                        <div className={styles.column}>Alíquota IPI</div>
                        <div className={styles.column}>UF</div>
                        <div className={`${styles.column} ${styles.fixed}`} />
                        <div className={`${styles.column} ${styles.fixed}`} />
                    </div>

                    <div className={styles.body}>
                        {
                            !data?.data?.length ?
                                <span className={styles.noData}>Nenhum dado encontrado</span> :
                                data?.data?.map(item => (
                                    <TaxItem key={item.id} item={item} onDelete={handleDelete} />
                                ))
                        }
                    </div>
                </div>

                <Pagination
                    page={currentPage + 1}
                    color="primary"
                    className={styles.pagination}
                    defaultPage={data?.currentPage ? data?.currentPage + 1 : 1}
                    count={data?.totalPage}
                    onChange={(ev, page) => getData(page, filter)}
                />
            </div>

            <RightDrawer titleBold='Importar' open={importCsvModalOpened} onClose={() => setImportCsvModalOpened(false)}>
                <ImportCsvModal url={taxFileUrl} onSave={handleSubmitCsv} onClose={() => setImportCsvModalOpened(false)} loading={loading} errors={uploadErrors} />
            </RightDrawer>

            <RightDrawer title="Editar" titleBold='informações fiscais' open={editInvoiceModalOpened} onClose={() => setEditInvoiceModalOpened(false)}>
                <EditInvoiceModal company={company} onSave={handleInvoice} onClose={() => setEditInvoiceModalOpened(false)} />
            </RightDrawer>

            <RightDrawer title="Alterar" titleBold='ambiente' open={editEnvironmentModalOpened} onClose={() => setEditEnvironmentModalOpened(false)}>
                <ChangeEnvironmentModal
                    onSave={handleChangeEnvironment}
                    onClose={() => setEditEnvironmentModalOpened(false)}
                    environment={environment}
                    setEnvironment={setEnvironment}
                    loading={loading}
                />
            </RightDrawer>
        </Layout>
    )
}