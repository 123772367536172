import { IGetTicketResponse } from "modules/generalDashboard/domain/dto/IGetTicketResponse";
import { IGeneralDashboardApiService } from "modules/generalDashboard/domain/interfaces/IGeneralDashboardApiService";
import { IFilterValue } from "modules/generalDashboard/presentation/interfaces/IFilter";

const GetTicketUseCase = async (service: IGeneralDashboardApiService, localId: string,  filter?: IFilterValue): Promise<IGetTicketResponse> => {
    const response = await service.getTicket(localId, filter);

    return response;
}

export default GetTicketUseCase;