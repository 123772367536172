import { format } from "date-fns";
import styles from "./SalesByDayItem.module.scss";
import { moneyMaskNumber } from "services/utils/Money";
import { IGetSalesByDaysVenda } from "modules/SaleForDayPage/domain/dto/IGetSalesByDayResponse";

interface ISalesByDayItem {
  item: IGetSalesByDaysVenda;
}

const SalesByDayItem = ({ item }: ISalesByDayItem) => {

  return (
    <div id={styles.salesByDayItem}>
      <div className={styles.saleItem}>
        <span style={{ textAlign: 'left' }}>{format(new Date(item.DataVendas), 'dd/MM/yyyy')}</span>
        <span>{moneyMaskNumber(item.Cashless ?? 0)}</span>
        <span>{moneyMaskNumber(item.Cancelado ?? 0)}</span>
        <span>{moneyMaskNumber(item.Boleto ?? 0)}</span>
        <span>{moneyMaskNumber(item.Debito ?? 0)}</span>
        <span>{moneyMaskNumber(item.DebitoNT ?? 0)}</span>
        <span>{moneyMaskNumber(item.Credito ?? 0)}</span>
        <span>{moneyMaskNumber(item.CreditoNT ?? 0)}</span>
        <span>{moneyMaskNumber(item.Dinheiro ?? 0)}</span>
        <span>{moneyMaskNumber(item.Desconto ?? 0)}</span>
        <span>{moneyMaskNumber(item.Voucher ?? 0)}</span>
        <span>{moneyMaskNumber(item.Outros ?? 0)}</span>
        <span>{moneyMaskNumber(item.EmAberto ?? 0)}</span>
        <span>{moneyMaskNumber(item.ValorTotalTransacionado ?? 0)}</span>
        <span>{moneyMaskNumber(item.ValorTotalNaoTransacionado ?? 0)}</span>
        <span>{moneyMaskNumber(item.Total ?? 0)}</span>
      </div>
    </div>
  );
};
export default SalesByDayItem;
