import { IGetAverageTicketResponse } from "modules/generalDashboard/domain/dto/IGetAverageTicketResponse";
import { IGeneralDashboardApiService } from "modules/generalDashboard/domain/interfaces/IGeneralDashboardApiService";
import { IFilterValue } from "modules/generalDashboard/presentation/interfaces/IFilter";

const GetAverageTicketUseCase = async (service: IGeneralDashboardApiService, localId: string,  filter?: IFilterValue): Promise<IGetAverageTicketResponse> => {
    const response = await service.getAverageTicket(localId, filter);

    return response;
}

export default GetAverageTicketUseCase;