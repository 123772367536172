import { FC, useEffect, useState } from "react";
import styles from "./LoginPage.module.scss";
import { TextField } from "@material-ui/core";
import { UseLoginPage } from "./UseLoginPage";
import ShowPassword from "../../components/showPassword/ShowPassword";
import SaasPublicLayout from "../SaasPublicLayout";
import { useForm } from "react-hook-form";
import { ILoginSchema, loginSchema } from "modules/saas/domain/schema/loginSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import { CircularProgress } from "@mui/material";
import { useQuery } from "hooks/UseQuery";
import Button from "components/ui/Button/Button";
import { ProspectType } from "modules/saas/domain/interfaces/ProspectTypeEnum";

export interface ILoginPageProps {
    //propertys
}

const LoginPage: FC<ILoginPageProps> = () => {
    const {
        isLoading,
        type,
        progressBarColor,
        backgroundColorTop,
        backgroundColorBottom,
        handleSubmit: submitForm,
        handleForgetPassword,
    } = UseLoginPage();
    const query = useQuery();

    const [showPassword, setShowPassword] = useState(false);

    const { register, handleSubmit, formState: { errors }, setValue, } = useForm<ILoginSchema>({
        resolver: zodResolver(loginSchema)
    });

    useEffect(() => {
        setValue("email", query.get("email") ?? "");

        const fromBait = query.get("bait");
        if (fromBait) {
            setTimeout(() => {
                localStorage.setItem('@baitFirstLogin', fromBait);
            }, 100);
        }

    }, [query, setValue]);

    return (
        <SaasPublicLayout
            step={1}
            totalSteps={5}
            progressBarColor={progressBarColor}
            backgroundColorTop={backgroundColorTop}
            backgroundColorBottom={backgroundColorBottom}
            terms
        >
            <div className={styles.container}>
                <h1>Seja bem vindo, usuário {Number(type) === ProspectType.Cielo || Number(type) === ProspectType.CieloEvent || Number(type) === ProspectType.CieloEstablishment ? "Mee" : "Meep"}!</h1>
                <h1 style={{ marginBottom: "10px" }}>Use seu email e senha para continuar:</h1>
                <form
                    onSubmit={handleSubmit(submitForm)}
                >
                    <div className={styles.column}>
                        <label htmlFor="email" className={styles.required}>E-mail</label>
                        <TextField
                            variant="outlined"
                            fullWidth
                            size="small"
                            {...register("email")}
                            helperText={errors.email?.message}
                            error={!!errors.email}
                            disabled={isLoading}
                        />
                    </div>
                    <div className={`${styles.column} ${styles.inputPass}`}>
                        <label htmlFor="password" className={styles.required}>Senha</label>
                        <TextField
                            variant="outlined"
                            type={showPassword ? "text" : "password"}
                            fullWidth
                            size="small"
                            {...register("password")}
                            helperText={errors.password?.message}
                            error={!!errors.password}
                            disabled={isLoading}
                        />
                        <ShowPassword showPassword={showPassword} setShowPassword={setShowPassword} />
                    </div>

                    <Button type="submit" style={{ backgroundColor: backgroundColorTop }}>
                        {isLoading ? <CircularProgress size={16} color="inherit" /> : "Continuar"}
                    </Button>
                    <Button
                        type="button"
                        style={{ color: backgroundColorTop }}
                        variant="text"
                        onClick={handleForgetPassword}
                    >
                        Esqueci minha senha
                    </Button>
                </form>
            </div>
        </SaasPublicLayout>
    )
}
export default LoginPage