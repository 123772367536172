import { useCallback, useEffect, useState } from "react";
import { IPlansItem } from "../../interfaces/plans/IPlans";
import { SubscriptionType } from "modules/saas/domain/interfaces/SubscriptionType";
import { SaasApi } from "services/api/saas/SaasApi";
import { GetPlansUseCase } from "modules/saas/application/useCases/GetPlansUseCase";
import { ICheckoutForm } from "../../interfaces/checkout/ICheckoutForm";
import { CheckoutUseCase } from "modules/saas/application/useCases/CheckoutUseCase";
import { useQuery } from "hooks/UseQuery";
import { useHistory, useLocation } from "react-router-dom";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { getParamsString } from "../../utils/params";
import { IProspect } from "../../interfaces/prospect/IProspect";
import { ProspectSaasUseCase } from "modules/saas/application/useCases/ProspectSaasUseCase";
import UseProspect from "../../hooks/UseProspect";
import { ProspectType } from "modules/saas/domain/interfaces/ProspectTypeEnum";
import CheckoutCieloEventUseCase from "modules/saas/application/useCases/CheckoutCieloEventUseCase";
import CheckoutCieloEstablishmentUseCase from "modules/saas/application/useCases/CheckoutCieloEstablishmentUseCase";

const service = SaasApi();

interface Error {
    string: string;
}

export const UsePaymentPage = () => {
    const [selectedType, setSelectedType] = useState<SubscriptionType>(SubscriptionType.ANNUAL);
    const [selectedPlan, setSelectedPlan] = useState<IPlansItem>();
    const [plans, setPlans] = useState<IPlansItem[]>([]);
    const [wPos, setWPos] = useState(false);
    const [form, setForm] = useState<ICheckoutForm>({ creditCardPayment: { billingAddress: {}, installment: 12 } } as ICheckoutForm);
    const [errors, setErrors] = useState({} as Error);
    const [formLoading, setFormLoading] = useState(false);
    const [prospect, setProspect] = useState({} as IProspect);
    const [type, setType] = useState<ProspectType>();
    const [amount, setAmount] = useState<number>(0);
    const [count, setCount] = useState(0);
    const [posPriceValues, setPosPriceValues] = useState({
        price: "R$ 50,00",
        fullPrice: "",
        discount: ""
    });
    
    const query = useQuery();
    const history = useHistory();
    const { toast, showLoading, hideLoading } = useUi();
    const { type: prospectType } = UseProspect();
    const location = useLocation<{ type: string }>();

    const prospectId = query.get('prospectId');
    const key = query.get('key');

    useEffect(() => {
        if (!prospectId || !key) {
            history.push("/public/saas/register?" + getParamsString(query));
            toast('A url está incompleta. Refaça o processo.', 'error');
        }
    }, [history, key, query, toast, type, prospectId]);

    useEffect(() => {
        (async () => {
            try {
                showLoading();
                if (prospectId && key) {
                    const response = await ProspectSaasUseCase(service, prospectId, key);
                    setProspect(response);
                    setType(response.type);

                    if (response.type === ProspectType.Balance) {
                        const advancedPLan = plans.find(x => x.subscriptionType === selectedType && x.name === "Avançado");
                        setSelectedPlan(advancedPLan);
                    }

                    if (response.type === ProspectType.CieloEvent) {
                        const eventPLan = plans.find(x => x.subscriptionType === SubscriptionType.CIELOLICENSES && x.name === "Evento");
                        setSelectedPlan(eventPLan);
                        setSelectedType(SubscriptionType.CIELOLICENSES);
                        setAmount(50);
                        setCount(1);
                    }
                }
            } catch (err: any) {
                if (err?.response?.status === 401) {
                    toast('A chave fornecida está inválida ou expirou.', 'error');
                    const currentType = location.state?.type ?? prospectType;
                    history.push("/public/saas/register", { type: currentType });
                } else {
                    toast('Ocorreu um erro ao fazer a busca dos dados. Tente novamente.', 'error');
                }
            } finally {
                hideLoading();
            }
        })();
    }, [hideLoading, history, key, location.state?.type, plans, prospectId, prospectType, query, selectedType, showLoading, toast, type]);

    useEffect(() => {
        (async () => {
            try {
                showLoading();
                const response = await GetPlansUseCase(service);
                setPlans(response);
                if (response.length) {
                    setSelectedPlan(response.find(x => x.subscriptionType === SubscriptionType.ANNUAL && x.index === 2))
                }
            } finally {
                hideLoading();
            }
        })();
    }, [hideLoading, showLoading]);

    useEffect(() => {
        if (selectedType === SubscriptionType.MONTHLY) setWPos(false);
    }, [selectedType]);

    const posPriceCalc = useCallback((quantity: number) => {
        setCount(quantity);

        let price = type === ProspectType.CieloEvent ? 50 : 0;

        if (type === ProspectType.CieloEstablishment) {
            if (selectedPlan?.name === "Avançado") {
                price = quantity * 29.9;
            } else if (selectedPlan?.name === "Intermediário") {
                price = quantity * 39.9;
            } else {
                price = quantity * 49.9;
            }
        } else if (type === ProspectType.CieloEvent) {
            if (quantity <= 5) {
                price = quantity * 50;
            } else if (quantity >= 6 && quantity <= 10) {
                price = quantity * 40;
            } else if (quantity >= 11 && quantity <= 15) {
                price = quantity * 35;
            } else if (quantity >= 16) {
                price = quantity * 30;
            }
        }

        setAmount(price);

        const fullPrice = quantity * 50;
        const discount = fullPrice - price;
        setPosPriceValues({
            price: !!price ? Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(price) : "",
            fullPrice: fullPrice > price ? Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(fullPrice) : "",
            discount: fullPrice > price ? Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(discount) : ""
        });
    }, [selectedPlan, type]);

    const handleSubmit = useCallback(async (useSameAddress: boolean) => {
        try {
            setFormLoading(true);
            let formData = form;
            
            formData.planSelected = {
                ...form.planSelected,
                planId: selectedPlan!.id,
            };
            
            if (selectedType === SubscriptionType.MONTHLY || selectedType === SubscriptionType.CIELOLICENSES) {
                formData.creditCardPayment.installment = 1;
            }
            
            if (useSameAddress) {
                formData.creditCardPayment.billingAddress = prospect.establishment.address;
            }
            
            if (wPos) {
                formData.planSelected.featureIds = [selectedPlan?.features[0]?.id!];
            }

            if (type === ProspectType.CieloEvent) {
                await CheckoutCieloEventUseCase(service, prospectId!, key!, {
                    ...formData,
                    planSelected: {
                        planId: selectedPlan!.id,
                        amount: amount,
                        discountCoupon: form.planSelected.discountCoupon,
                        features: [
                            {
                                id: selectedPlan!.additionalFeatures.find(feature => feature.name === "CieloEventLicenses")!.id,
                                amount: amount / count,
                                quantity: count,
                                initialAmount: 50,
                            }
                        ]
                    }
                });
            } else if (type === ProspectType.CieloEstablishment) {
                await CheckoutCieloEstablishmentUseCase(service, prospectId!, key!, {
                    ...formData,
                    planSelected: {
                        planId: selectedPlan!.id,
                        amount: selectedPlan!.price + ((amount ?? 0) * (selectedPlan!.subscriptionType === SubscriptionType.ANNUAL ? 12 : 1)),
                        discountCoupon: form.planSelected.discountCoupon,
                        features: count ? [
                            {
                                id: selectedPlan!.additionalFeatures.find(feature => feature.name === "CieloEstablishmentLicenses")!.id,
                                amount: selectedPlan!.name === "Avançado" ? 29.9 : selectedPlan!.name === "Intermediário" ? 39.9 : 49.9,
                                quantity: count,
                                initialAmount: 49.9,
                            }
                        ] : undefined
                    }
                });
            } else {
                await CheckoutUseCase(service, prospectId!, formData, key!);
            }

            if (wPos) history.push(`/public/saas/success-pos?prospectId=${prospectId}&key=${key}`);
            else history.push(`/public/saas/success?email=${prospect.email}`, { type: type });
        } catch {
            console.log('Ocorreu um erro ao processar o pagamento.');
        } finally {
            setFormLoading(false);
        }
    }, [amount, count, form, history, key, prospect, prospectId, selectedPlan, selectedType, type, wPos]);

    return {
        type,
        selectedType,
        setSelectedType,
        selectedPlan,
        setSelectedPlan,
        plans,
        wPos,
        setWPos,
        form, 
        setForm,
        errors, 
        setErrors,
        handleSubmit,
        formLoading,
        count,
        posPriceValues,
        posPriceCalc,
        setAmount
    }
}