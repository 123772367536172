import { AddCircleOutline, Moped, Print, WhatsApp } from '@mui/icons-material';
import { Button, CircularProgress, Icon, IconButton } from '@mui/material';
import { INFCeResponse } from 'modules/kds/domain/dto/nfe/NFCeResponse';
import { useLocal } from 'modules/local/presentation/context/LocalContext';
import { QRCodeSVG } from 'qrcode.react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { moneyMaskNumber } from 'services/utils/Money';
import { IKDSItem, IKDSItemStep, KDSStatusStep } from '../KDSItem/IKDSItem';
import { KDSStepAction } from '../KDSItem/KDSItem';
import styles from './KDSSideSheetOrder.module.scss';
import { toPng } from 'html-to-image';
import { IIssueNFCe } from '../../interfaces/IIssueNFE';
import { useUi } from 'contexts/userInterface/UserInterfaceContext';
import { useAuth } from 'modules/auth/presentation/context/AuthContext';
import UseDimension from 'components/dimension/UseDimension';
import UseSocketPrinter from 'modules/printer/presentation/hooks/UseSocketPrinter';
import InvoicePrinterLayout from 'modules/printer/presentation/layoutPrinter/invoice/InvoicePrinterLayout';
import { IPrinter } from '../../interfaces/IPrinter';

export interface IKDSSideSheetOrderProps {
    kdsItem: IKDSItem;
    onClickActionHandle: (action: KDSStepAction, item: IKDSItem) => Promise<void>;
    onClickPrintHandle: () => Promise<void>;
    setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
    openDrawer: boolean;
    openAlertReceivedHandle: () => void;
    isLoading: boolean;
    colorsStatus: (nameStep: IKDSItemStep) => "#ED0029" | "#6C2EE0" | "#399C54" | "#FF5C78" | "#F44336" | "#399C54" | "#BDBDBD" | "#333333";
    openRefuseModal: () => void;
    openGiveBackModal: () => void;
    issueNFCe: INFCeResponse[];
    postIssueNfe: (item: IIssueNFCe) => Promise<INFCeResponse[] | undefined>;
    setOpenDrownSelectedDelete?: React.Dispatch<React.SetStateAction<boolean>>;
    printerList?: IPrinter[];
    selectedPrinter?: IPrinter | null
    sentInvoiceToPrinterNFC?: (nfc: INFCeResponse) => Promise<void>
    isPrinterOrder?: React.MutableRefObject<boolean>
}

const KDSSideSheetOrder: React.FC<IKDSSideSheetOrderProps> = ({ kdsItem: item, onClickActionHandle, onClickPrintHandle, openRefuseModal, isPrinterOrder,
    openGiveBackModal, isLoading, setOpenDrawer, openDrawer, colorsStatus, openAlertReceivedHandle, issueNFCe, postIssueNfe, printerList, setOpenDrownSelectedDelete, selectedPrinter, sentInvoiceToPrinterNFC }) => {

    const { hasAccessRole } = useAuth();
    const { currentLocal } = useLocal();
    const { toast } = useUi();
    const { dimensions } = UseDimension();
    const {showLoading, hideLoading} = useUi()
    const {sentToPrinter, getElementHtml} = UseSocketPrinter();

    const [isLoadingPrinter, setIsLoadingPrinter] = useState(false);
    
    const ref = useRef<HTMLDivElement>(null)
   

    const onClickPrintIssueNFCe = useCallback(async () => {
        setIsLoadingPrinter(true)
        try {
            const response = await postIssueNfe({ pedidoId: item.id, documento: item.customer?.document })                  
            if (response && response.length > 0) {
                await sentInvoiceToPrinterNFC?.(response[0]);
            } else {
                console.error('Nenhuma nota encontrada para enviar à impressora.');
            }
            toast("Impressão realizada com sucesso", "success")
    
        } catch (err) {
            toast("Falha em imprimir cupom fiscal", "error")
        } finally {
            setIsLoadingPrinter(false)
        }
    
    
    }, [item.customer?.document, item.id, postIssueNfe, sentInvoiceToPrinterNFC, toast]);

    
    const handleOpenWhatsapp = (phone: string) => {
        window.open(`https://api.whatsapp.com/send?phone=55${phone}`)
    }

    const buttons = useMemo(() => {
        switch (item.currentStep.name) {
            case KDSStatusStep.PENDING:
                return <div className={styles.actions}>
                    <Button variant={'outlined'} className={styles.recuse} disabled={isLoading} onClick={openRefuseModal}>Recusar</Button>
                    <Button variant={'contained'} className={styles.accept} disabled={isLoading} onClick={() => onClickActionHandle?.(KDSStepAction.Accept, item)}>Aceitar pedido</Button>
                </div>
            case KDSStatusStep.ACCEPTED:
                return <div className={styles.actions}>
                    <Button variant={'contained'} className={styles.accept} disabled={isLoading} onClick={() => onClickActionHandle?.(KDSStepAction.Start, item)}>Iniciar</Button>
                </div>
            case KDSStatusStep.PREPARING:
                return <div className={styles.actions}>
                    <Button variant={'contained'} className={styles.accept} disabled={isLoading} onClick={() => onClickActionHandle?.(KDSStepAction.Transport, item)}>Despachar pedido <Moped style={{ marginLeft: 10 }} /></Button>
                </div>
            case KDSStatusStep.TRANSPORT:
                return <div className={styles.actions}>
                    <Button variant={'outlined'} className={styles.recuse} disabled={isLoading} onClick={openGiveBackModal}>Devolver</Button>
                    <Button variant={'contained'} className={styles.accept} disabled={isLoading} onClick={openAlertReceivedHandle}>Finalizar</Button>
                </div>
            default:
                return null
        }
    }, [isLoading, item, onClickActionHandle, openAlertReceivedHandle, openGiveBackModal, openRefuseModal])

    return (
        <>
            <div id={styles.KDSSideSheetOrder}>
                <div>
                    {isLoadingPrinter &&
                        <div className={styles.loading}>
                            <CircularProgress />
                        </div>
                    }
                </div>
                <div className={styles.container}> 

                    <div className={styles.header}>
                        <div className={styles.headerTop} >
                            <div className={styles.topInfo}>
                                <h2>Pedido: <span className={styles.code}> {item.code}</span></h2>
                                <div className={styles.more}>
                                    {dimensions.width > 460 && (
                                        <div className={styles.status} style={{ backgroundColor: colorsStatus(item.currentStep) }}>
                                            <span> {item.currentStep.name}</span>
                                        </div>
                                    )}
                                    <IconButton size='small' onClick={() => setOpenDrawer(false)}><Icon>close</Icon></IconButton>
                                </div>
                            </div>
                            {dimensions.width <= 460 && (
                                <div className={styles.status} style={{ backgroundColor: colorsStatus(item.currentStep) }}>
                                    <span> {item.currentStep.name}</span>
                                </div>
                            )}
                        </div>

                        {item.panelCode && <p className={styles.password}>Senha: <span> {item.panelCode.toString().padStart(4, '0')}</span></p>}
                        <div className={styles.buttonPrinter}>
                            {item.remotePrinter.address && 
                            item.orderOrigin === 2 && 
                            /* item.currentStep.name !== "Recusado" && 
                            item.currentStep.name !== "Pendente" && */ 
                            <Button size='small' 
                                onClick={() => {
                                if (!(printerList && printerList.length > 1)) {
                                    onClickPrintIssueNFCe();
                                } else if (setOpenDrownSelectedDelete) {
                                    setOpenDrownSelectedDelete(true);
                                }
                                }}                                
                            ><span>
                                Imprimir cupom fiscal</span><Print />
                            </Button>}

                            {item.remotePrinter.address && 
                                <Button 
                                    size='small' 
                                    onClick={() => {
                                        if (isPrinterOrder) {
                                            isPrinterOrder.current = true; // Safely assign value only if isPrinterOrder is defined
                                        }
                                
                                        if (!(printerList && printerList.length > 1)) {
                                            onClickPrintHandle();
                                        } else if (setOpenDrownSelectedDelete) {
                                            setOpenDrownSelectedDelete(true);
                                        }
                                    }}
                                >
                                <span>Imprimir Pedido</span>
                                <Print />
                              </Button>
                            }
                        </div>
                    </div>
                    <div className={styles.content}>
                        {item.customer && <div className={styles.customer}>
                            {

                                (item.orderOrigin === 2 ? <div className={styles.item}><img src='/assets/img/ifood.png' alt='Logo ifood' /></div> : item.table && <div className={styles.item}><span className={styles.table}>Mesa: <span>{item.table}</span></span></div>)
                            }
                            {item.customer?.name && <div className={styles.item}><label>Cliente:</label><span>{item.customer?.name}</span></div>}
                            {item.customer?.document && <div className={styles.item}><label>Documento:</label><span>{item.customer?.document}</span></div>}
                            {item.customer?.phoneNumber && (
                                <div className={styles.item}>
                                    <label>Contato:</label>

                                    <WhatsApp className={styles.linkItem} fontSize='small' onClick={() => handleOpenWhatsapp(item.customer?.phoneNumber ?? "")} />
                                    <span>{item.customer?.phoneNumber}</span>
                                </div>
                            )}
                            {item.customer?.address && <div className={styles.item}><label>Endereço:</label><span>{item.customer?.address}</span></div>}
                            {item.paymentType && <div className={styles.paymentItem}><label>Pagamento:</label><span>{item.paymentType.description}</span></div>}
                        </div>}
                        {
                            item.observation &&
                            <div className={styles.observation}>
                                <span>{item.observation}</span>
                            </div>
                        }

                        <div className={styles.order}>
                            <div className={styles.list}>
                                {item.order.items.map((orderItem, index) => (
                                    <div key={index} className={styles.orderItem}>
                                        <span className={styles.categoryItem}>{orderItem.nameCategory}</span>
                                        <div className={styles.top}>
                                            <span>{orderItem.quantity}x {orderItem.name}</span>
                                            {
                                                hasAccessRole("KDSVisualizarValores") && <span>{moneyMaskNumber(orderItem.value)}</span>
                                            }
                                        </div>
                                        {orderItem.compositions?.filter(item => !item.options.length).map((compositionItem, index) => (
                                            !!compositionItem.description &&
                                            <div className={styles.compositionItem}>
                                                {index === 0 && <p className={styles.textObservation}>Observação:</p>}
                                                <span className={styles.observationComposition}>{compositionItem.description}</span>
                                            </div>
                                        ))}

                                        {!!orderItem.compositions?.length && <div className={styles.compositions}>
                                            {orderItem.compositions?.filter(item => item.options.length).map((compositionItem, index) => (
                                                <>
                                                    <div className={styles.compositionItem}>
                                                        {index === 0 && <p className={styles.additional}>Adicionais:</p>}
                                                        <span className={styles.observationComposition}><AddCircleOutline />{compositionItem.description}</span>
                                                        {

                                                            <div className={styles.compositionItem} key={index}>
                                                                <span>
                                                                    {
                                                                        compositionItem.options.map((option, index) =>
                                                                            <ul>
                                                                                <li key={index}> - {option}</li>
                                                                            </ul>
                                                                        )
                                                                    }
                                                                </span>
                                                            </div>
                                                        }
                                                    </div>
                                                </>
                                            ))}
                                        </div>}
                                    </div>
                                ))}
                                {
                                    hasAccessRole("KDSVisualizarValores") &&
                                    <div className={styles.total} >
                                        <label>Valor total</label>
                                        <span>{moneyMaskNumber(item.amount)}</span>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div style={{ height: 0, overflow: 'hidden' }}>

                        {
                            issueNFCe[0] &&
                            <div id={styles.containerCupom} ref={ref}>
                                <div className={styles.headerMain}>
                                    {currentLocal && <p className={styles.nameLocal}> {currentLocal.name}</p>}
                                    {issueNFCe[0]?.cnpj && <span>CNPJ: {issueNFCe[0].cnpj}</span>}
                                    {issueNFCe[0]?.razaosocial && <span>Razão Social: {issueNFCe[0].razaosocial}</span>}
                                    <span>DANFE NFC-e - Documento Auxiliar da Nota Fiscal do Consumidor Eletrônica</span>
                                    {issueNFCe[0]?.['valor-total'] && <p className={styles.total}>VALOR: {moneyMaskNumber(Number(issueNFCe[0]['valor-total']))}</p>}
                                </div>
                                <div className={styles.products}>
                                    <span>Produtos:</span>
                                    {
                                        issueNFCe[0]?.itens && issueNFCe[0].itens.map(item => (

                                            item.ProdutoNome && item.Total && <span>{item.ProdutoNome} - {item.Total} {item.Ncm}</span>
                                        )

                                        )
                                    }
                                </div>
                                <div className={styles.contentCupom}>
                                    {issueNFCe[0]?.cliente && <span>Consumidor: {issueNFCe[0].cliente}</span>}
                                    {issueNFCe[0]?.serie && <span>Serie: {issueNFCe[0].serie}</span>}
                                    {issueNFCe[0]?.sefaz['data-emissao'] && <span>Data: {issueNFCe[0].sefaz['data-emissao']}</span>}
                                </div>
                                <div className={styles.footer}>
                                    <span>Consulte pela chave de acesso no SEFAZ do seu estado ou QRcode</span>
                                    <div>
                                        <QRCodeSVG size={150} value={issueNFCe[0].sefaz['url-danfe']} />
                                    </div>
                                    <span>NOTA EM CONTIGÊNCIA</span>
                                    {issueNFCe[0]?.sefaz.protocolo && <span>Protocolo de autorização: <span>{issueNFCe[0].sefaz.protocolo}</span> </span>}
                                </div>
                            </div>
                        }
                    </div>
                </div>

                <div className={styles.footer}>

                    {buttons}
                </div>

            </div>
        </>
    )
}

export default KDSSideSheetOrder