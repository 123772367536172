import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import styles from './OperatorAndTableFragment.module.scss'
import CustomButton from '../custombutton/CustomButton'
import { useUi } from 'contexts/userInterface/UserInterfaceContext';
import { useLocal } from 'modules/local/presentation/context/LocalContext';
import GetOperatorByNameUseCase from 'modules/operators/application/useCases/GetOperatorByNameUseCase';
import { VisibilityOutlined, VisibilityOffOutlined } from '@mui/icons-material';
import GetMesas from 'modules/gestaoVendas/application/useCases/GetMesasUseCase';
import { IGetOperadorByName } from 'modules/operators/application/dtos/IGetOperadorByName';
import { GestaoVendasApi } from 'services/api/gestaoVendas/gestaoVendasApi';
import OperatorApi from 'services/api/operator/OperatorApi';
import OperatorsSidesheet from '../operatorsSidesheet/OperatorsSidesheet';
import TablesSidesheet from '../tablesSidesheet/TablesSidesheet';
import { IReponseMesas } from 'modules/gestaoVendas/application/interfaces/IGestaoVendasService';
import { decryptIv, decryptKey } from 'Enviroment';
import CryptoJS from 'crypto-js';
import UseDimension from 'components/dimension/UseDimension';
export interface IOperatorAndTableFragmentProps {
    //propertys
}

const gestaoService = GestaoVendasApi();
const operatorService = OperatorApi();

const OperatorAndTableFragment: FC<IOperatorAndTableFragmentProps> = () => {

    const { showLoading, hideLoading } = useUi();
    const { currentLocal } = useLocal();
    const { dimensions } = UseDimension();

    const [openOperator, setOpenOperator] = useState<boolean | "add">(false);
    const [openTable, setOpenTable] = useState<boolean | "add">(false);
    const [showPassword, setShowPassword] = useState(false);
    const [responseMesas, setResponseMesas] = useState<IReponseMesas[] | undefined>();

    const [adminOperator, setAdminOperator] = useState<IGetOperadorByName>();

    const getAdminOperator = useCallback(
        async () => {
            if (currentLocal) {
                try {
                    showLoading();
                    const response = await GetOperatorByNameUseCase(operatorService, currentLocal.id, "Operador Admin");
                    setAdminOperator(response[0]);
                    return response[0];
                } finally {
                    hideLoading()
                }
            }
        },
        [currentLocal, hideLoading, showLoading]
    );

    const getMesas = useCallback(async () => {
        if (currentLocal) {
            try {
                showLoading()
                const response = await GetMesas(gestaoService, currentLocal.id);
                setResponseMesas(response);
            } finally {
                hideLoading()
            }
        }
    }, [currentLocal, hideLoading, showLoading]);


    useEffect(() => {
        getMesas();
        getAdminOperator();
    }, [getAdminOperator, getMesas]);


    const handleOnCloseTableSidesheet = useCallback(() => {
        setOpenTable(false);
        getMesas();
    }, [getMesas]);

    const encryptedPass = useMemo(() => adminOperator?.Senha ?? "", [adminOperator]);

    return (
        <div id={styles.OperatorAndTableFragment} >
            <CustomButton
                title="Operadores"
                onClickAdd={() => setOpenOperator("add")}
                onClickEdit={() => setOpenOperator(true)}
            >
                <div className={styles.buttonChildren}>
                    <span>Admin | senha: {showPassword ? decrypt(encryptedPass) : "****"}</span>
                    <button onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? <VisibilityOutlined fontSize="small" /> : <VisibilityOffOutlined fontSize="small" />}
                    </button>
                </div>
            </CustomButton>

            {dimensions.width <= 600 && <div className={styles.divider}/>}
            
            <CustomButton
                title="Mesas cadastradas"
                onClickAdd={() => setOpenTable("add")}
                onClickEdit={() => setOpenTable(true)}
            >
                <div className={styles.buttonChildren}>
                    <span>Você tem {responseMesas?.length} mesas.</span>
                </div>
            </CustomButton>
            {openOperator && <OperatorsSidesheet openOperator={openOperator} setOpenOperator={setOpenOperator} decrypt={decrypt} />}
            {openTable && <TablesSidesheet openTable={openTable} setOpenTable={setOpenTable} onClose={handleOnCloseTableSidesheet} />}
        </div>
    )
}






const getSHA256 = (pass: string) => {
    if (!pass) {
        return null;
    }

    const hash = CryptoJS.SHA256(pass);
    return hash.toString(CryptoJS.enc.Hex);
}

const getSHA128 = (pass: string) => {
    if (!pass) {
        return null;
    }

    const hash = CryptoJS.SHA256(pass);
    return hash.toString(CryptoJS.enc.Hex).slice(0, 32);
}


const decrypt = (encryptedMessage: string) => {
    try {
        const keyHex = getSHA256(decryptKey ?? "");
        const ivHex = getSHA128(decryptIv ?? "");

        if (keyHex && ivHex) {
            const decrypted = CryptoJS.AES.decrypt(
                encryptedMessage,
                CryptoJS.enc.Hex.parse(keyHex),
                { iv: CryptoJS.enc.Hex.parse(ivHex), mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
            );

            const decryptedMessage = decrypted.toString(CryptoJS.enc.Utf8);

            return decryptedMessage;
        }
    } catch (error) {
        console.error("Erro ao descriptografar:", error);
        return null;
    }
}
export default OperatorAndTableFragment