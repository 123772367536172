import { ImportExport } from "@mui/icons-material";
import styles from "./ForProductHeader.module.scss";
import { useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import { IPostSaleForProductParams } from "modules/saleForProduct/domain/dto/IPostSaleForProductParams";

export interface IForProductHeaderProps {
  params: IPostSaleForProductParams;
  setParams: React.Dispatch<React.SetStateAction<IPostSaleForProductParams>>;
}

const titleHeader = [
  {
    title: "Categoria",
    value: "categoriaNome",
  },
  {
    title: "Produto",
    value: "produtoNome",
  },
  {
    title: "Quantidade",
    value: "quantidade",
  },
  {
    title: "Unidade",
    value: "unidade",
  },
  {
    title: "Cashless",
    value: "cashless",
  },
  {
    title: "Débito",
    value: "debito",
  },
  {
    title: "Crédito",
    value: "credito",
  },
  // {
  //   title: "PIX",
  //   value: "",
  // },
  {
    title: "Dinheiro",
    value: "dinheiro",
  },
  {
    title: "Voucher",
    value: "voucher",
  },
  {
    title: "Divisão",
    value: "divisão"
  },
  {
    title: "Outros",
    value: "outros",
  },
  {
    title: "Desconto",
    value: "desconto",
  },
  {
    title: "Valor",
    value: "valor",
  },
];

const ForProductHeader = ({ params, setParams }: IForProductHeaderProps) => {
  const [orderBy, setOrderBy] = useState({ Type: "categoriaNome" } as {
    Orientation: "asc" | "desc",
    Type: string,
  });

  useEffect(() => {
    if (orderBy.Type && orderBy.Orientation) {
      setParams((prev) => ({
        ...prev,
        Sort: {
          Orientation: orderBy.Orientation,
          Type: orderBy.Type,
        }
      }));
    }
  }, [orderBy, setParams]);
  

  return (
    <div id={styles.TransactionsHeader}>
      {titleHeader.map((item) => {
        return (
          <div 
            key={item.value}
            className={styles.container}
            style={{ width: item.title === "Produto" ? "100px" : "" }}
          >
            {item.title}
            <IconButton
              disableRipple
              onClick={() => {
                setOrderBy({
                  Orientation: params.Sort?.Orientation === "asc" ? "desc" : "asc",
                  Type: item.value ?? "",
                });
              }}
            >
              <ImportExport />
            </IconButton>
          </div>
        );
      })}
    </div>
  );
};
export default ForProductHeader;
