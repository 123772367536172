import { AxiosInstance } from "axios";
import { IGetProductsListRequest } from "modules/products/domain/dto/IGetProductsListRequest";
import { IGetSimpleProductsListResponse } from "modules/products/domain/dto/IGetProductsListResponse";

const ObterListaSimplesDeProdutos = async (
  api: AxiosInstance,
  request: IGetProductsListRequest
): Promise<IGetSimpleProductsListResponse> => {
  const params = {
    Keyword: request.keyword,
    localClienteId: request.localId,
    Page: request?.page ? (request.page - 1) : 0,
    PageSize: request.pageSize ?? 10,
    IgnoreActivationAndDeactivation: true,
  };
  const response = await api.get<IObterProdutoResponse>(
    "/Produto/GetProductsByName",
    { params }
  );
  const parsedResponse: IGetSimpleProductsListResponse = {
    items: response.data.records.map((produtoItem) => ({
      id: produtoItem.id,
      name: produtoItem.name,
      value: produtoItem.price,
      isVariable: produtoItem.isVariable,
    })),
    total: response.data.totalRecords,
    page: request?.page ? (request.page - 1) : 0,
    pageSize: request?.pageSize ?? 10,
    totalPages: response.data.totalRecords
  };

  return parsedResponse;
};

export default ObterListaSimplesDeProdutos;

interface IObterProdutoResponse {
  totalRecords: number;
  records: IProduto[];
}

interface IProduto {
  id: string;
  name: string;
  price: number;
  isVariable: boolean;
}
