import React from "react";
import styles from "./TicketGeneral.module.scss";
import { CircularProgress } from "@material-ui/core";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { moneyMaskNumber } from "services/utils/Money";
import { ITicket } from "../../interfaces/ITicket";

interface ITicketGeneralProps {
  ticketData: ITicket | undefined
  isLoading: boolean | undefined
}

const TicketGeneral: React.FC<ITicketGeneralProps> = ({
  ticketData, 
  isLoading, 
}) => {
  const {isMobile} = useUi();
  
  return (
    <div id={styles.TicketGeneral}>
      <div className={styles.topTitle}>
        <p>Ficha</p>
      </div>
      <div className={!isMobile ? styles.container : styles.containerMobile }>
        <div className={styles.content}>
          <div className={styles.top}>
            <p>Total de pedidos</p>
            {
              isLoading ? 
              <div className={styles.loading}><CircularProgress  size={16} color="inherit" /> </div>
              :
              <span>{ticketData?.total ?? 0}</span>
            }
            {
              isLoading ? 
              <div className={styles.loading}><CircularProgress  size={16} color="inherit" /> </div>
              :
              <span className={styles.amount}>{moneyMaskNumber(ticketData?.amount)}</span>
            }
          </div>
          <div className={styles.bottom}>
            <div>
              <p>Valor total desconto</p>
              {
              isLoading ? 
              <div className={styles.loading}><CircularProgress  size={16} color="inherit" /> </div>
              :
              <span>{moneyMaskNumber(ticketData?.totalDiscount)}</span>
              }
            </div>
            <div>
              <p>Total consumido</p>
              {
              isLoading ? 
              <div className={styles.loading}><CircularProgress  size={16} color="inherit" /> </div>
              :
              <span>{moneyMaskNumber(ticketData?.totalConsumed)}</span>
              }
            </div>
            <div>
              <p>Taxa de serviço</p>
              {
              isLoading ? 
              <div className={styles.loading}><CircularProgress  size={16} color="inherit" /> </div>
              :
              <span>{moneyMaskNumber(ticketData?.totalServiceFee)}</span>
              }
            </div>                
          </div>
        </div>    
      </div>
    </div>
  );
};

export default TicketGeneral;
