import { useLocal } from "modules/local/presentation/context/LocalContext";
import { MutableRefObject, useCallback, useState } from "react";
import { IFilterValue } from "../interfaces/IFilter";
import { ISalesCategories } from "../interfaces/ISalesCategories";
import GeneralDashboardApi from "services/api/generalDashboard/GeneralDashboardApi";
import GetSalesCategoriesUseCase from "modules/generalDashboard/application/useCases/GetSalesCategoriesUseCase";
import { ICashierEvent } from "../interfaces/ICashierEvent";

export interface IParamsUseSalesCategories {
  localId?: string,
  sessionId?: string,
  period?: number,
}

const UseSalesCategories = (filter: IFilterValue | undefined, isLoadingCashier: boolean, lastCashierOpenData: MutableRefObject<ICashierEvent | undefined>, isLoadingFinancialInfo: boolean | undefined) => {

  const [isLoading, setIsLoading] = useState<boolean>();
  const [salesCategories, setSalesCategories] = useState<ISalesCategories>()
  const { currentLocal } = useLocal();

  const getSalesCategories = useCallback(
    async (params?: IParamsUseSalesCategories) => {
      try {
        const serviceGeneral = GeneralDashboardApi();
        setIsLoading(true);
        if(!isLoadingCashier && !isLoadingFinancialInfo && currentLocal) {
          const cashierIds = filter?.cashierIds && filter.cashierIds.length > 0
            ? filter.cashierIds
            : lastCashierOpenData.current?.id
            ? [lastCashierOpenData.current.id]
            : undefined;
          
            if (!cashierIds) return;

          const response = await GetSalesCategoriesUseCase(serviceGeneral,
            currentLocal.id,
            {
              ...filter,
              cashierIds: cashierIds,
            });

          setSalesCategories(response);
          return response;
        }
      } finally {
        setIsLoading(false);
      }
    },
    [currentLocal, filter, isLoadingCashier, isLoadingFinancialInfo, lastCashierOpenData]
  );

  return {
    salesCategories,
    isLoading,
    getSalesCategories,
    setSalesCategories,
  };
};

export default UseSalesCategories;
