import { TextField } from "@mui/material";
import React from "react";
import InputMask from 'react-input-mask';

export interface ParametersInputProps {
  mask: string | (string | RegExp)[],
  disabled?: boolean,
  maskPlaceholder?: string | null,
  value?: string | number,
  onChange?: any,
  helperText?: string,
  error?: boolean,
  name: string,
  label?: string,
  variant?: "standard" | "filled" | "outlined",
  fullWidth?: boolean,
  className?: string,
  placeholder?: string,
  style?: React.CSSProperties;
  type?: string;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  overridePaste?: boolean;
}

const InputMasked: React.FC<ParametersInputProps> = ({
  mask,
  disabled,
  maskPlaceholder,
  value,
  onChange,
  name,
  label,
  variant,
  fullWidth,
  className,
  helperText,
  error,
  placeholder,
  style,
  type,
  onBlur,
  overridePaste = false
}) => {
  return (
    <InputMask
      className={className}
      mask={mask}
      value={value}
      disabled={disabled}
      onChange={onChange}
      maskPlaceholder={maskPlaceholder ?? null}
      style={style}
      type={type}
      onBlur={onBlur}
      onPaste={overridePaste ? (ev) => {
        ev.preventDefault();
        onChange({ target: { value: ev.clipboardData.getData('Text') } });
      } : () => null}
    >
      {() =>
        <TextField
          className={className}
          fullWidth={fullWidth}
          label={label}
          name={name}
          variant={variant as any || "outlined"}
          size="small"
          helperText={helperText}
          error={error}
          placeholder={placeholder}
          disabled={disabled}
          style={style}
          type={type === 'number' ? 'text' : type}
          onBlur={onBlur}
        />
      }
    </InputMask>

  );
};

export default InputMasked;
