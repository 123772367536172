import { useCallback, useEffect, useState } from "react";

import { useLocal } from "modules/local/presentation/context/LocalContext";
import GetEquipmentsUseCase from "modules/SaleForDayPage/application/GetEquipmentsUseCase";
import GetPosUseCase from "modules/SaleForDayPage/application/GetPosUseCase";
import SalesByDayReportApi from "services/api/salesByDayReport/SalesByDayReportApi";
import GetSalesByDayUseCase from "modules/SaleForDayPage/application/GetSalesByDayUseCase";

const salesByDayReportApi = SalesByDayReportApi();

export const UseSalesByDayPage = () => {
  const { currentLocal } = useLocal();

  const [isLoading, setIsLoading] = useState(false);
  const [equipmentList, setEquipmentList] = useState([]);
  const [posList, setPosList] = useState([]);
  const [reportData, setReportData] = useState<any>();

  useEffect(() => {
    getEquipmentList();
    getPosList();
  }, []);

  const getEquipmentList = useCallback(async () => {
    if (currentLocal) {
      try {
        const response = await GetEquipmentsUseCase(salesByDayReportApi, currentLocal.id);
        const equipmentHandled: any = await response.map((item) => ({ label: item.nome, value: item.id }))
        setEquipmentList(equipmentHandled);
      } catch (error: any) {
        console.log(error.response);
      }
    }
  }, [currentLocal]);

  const getPosList = useCallback(async () => {
    if (currentLocal) {
      try {
        const params = { IncludeDeletedProfiles: true, limit: 500 };
        const response = await GetPosUseCase(salesByDayReportApi, currentLocal.id, params);
        const posHandled: any = await response.items.map((item) => ({ label: item.name, value: item.id }));
        setPosList(posHandled);
      } catch (error: any) {
        console.log(error.response);
      }
    }
  }, [currentLocal]);

  const getSalesByDayList = useCallback(async (payload) => {
    if (currentLocal) {
      try {
        setIsLoading(true);
        payload.localId = currentLocal.id;
        payload.ShouldUseNewSchema = currentLocal.systemIdentifier === 1;
        const response = await GetSalesByDayUseCase(salesByDayReportApi, payload);
        setReportData(response);
      } catch (error: any) {
        console.log(error.response);
      } finally {
        setIsLoading(false);
      }
    }
  }, [currentLocal]);

  return {
    isLoading,
    equipmentList,
    posList,
    reportData,
    getSalesByDayList
  }
}