import { FC } from "react";
import styles from "./TurnoverTable.module.scss";
import { TurnoverTableItem } from "./components/turnoverTableItem/TurnoverTableItem";
import { ITurnoverTable } from "../../interfaces/ITurnOver";
import { IGetTurnoverTableResponse } from "modules/generalDashboard/domain/dto/IGetTurnoverTableResponse";
import { IParamsUseTurnover } from "../../hooks/UseTurnoverTable";
import { UseTurnoverTableItem } from "./UseTurnoverTableItem";

export interface ITurnoverTableProps {
  getTurnoverTable: (params?: IParamsUseTurnover) => Promise<IGetTurnoverTableResponse | undefined>;
  turnoverTableData: ITurnoverTable | undefined;
  isLoading: boolean | undefined;
}

const TurnoverTable: FC<ITurnoverTableProps> = ({ getTurnoverTable, isLoading, turnoverTableData }) => {

  const {
    sorting,
    handleSort,
  } = UseTurnoverTableItem(getTurnoverTable);

  return (
    <div id={styles.TurnoverTable}>
      <div className={styles.topTitle}>
        <p>Giro de mesa</p>
      </div>
      <TurnoverTableItem 
        getTurnoverTable={getTurnoverTable} 
        isLoading={isLoading} 
        averageTurnover={turnoverTableData} 
        turnoverItems={turnoverTableData?.tableToration || []}
        handleSort={handleSort}
        sorting={sorting}
      />
    </div>
  );
};

export default TurnoverTable;
