import React, { useEffect } from 'react'
import styles from './MyAccount.module.scss'
import { AttachMoney, Launch, MoneyOff, Schedule } from '@mui/icons-material'
import { moneyMaskNumber } from 'services/utils/Money'
import { CircularProgress } from '@mui/material'
import { IMyAccount } from '../../interfaces/IMyAccount'
import NoBalanceIcon from '../../../../../res/assets/icons/iconGeneralDash/no-balance.png';


interface IMyAccountProps {
    getMyAccount: () => Promise<IMyAccount | undefined>
    myAccount: IMyAccount | undefined   
    goMyAccount: () => void 
    isLoading: boolean | undefined
}

export const MyAccount: React.FC<IMyAccountProps> = ({goMyAccount, getMyAccount, myAccount, isLoading }) => {

    useEffect(() => {
        getMyAccount()
    }, [getMyAccount])


    return (
        <div id={styles.MyAccount}>
            <div className={styles.top}>
                <p>Minha conta</p>
                <p onClick={goMyAccount}>Mais detalhes <Launch /></p>
            </div>
            <div className={styles.bottom}>
                <div>
                    <div>
                            <AttachMoney /> 
                         <p>Saldo <b>disponível</b> </p>
                    </div>
                    {
                        isLoading ? 
                        <div className={styles.loading}><CircularProgress  size={16} color="inherit" /> </div>
                        :
                        <p className={styles.availableValue}>{ moneyMaskNumber(myAccount?.balanceAvailable)}</p>
                    }
                </div>
                <div className={styles.separator}></div>
                <div>
                    <div>
                        <Schedule />
                        <p>Saldo <b>a liberar</b></p>
                    </div>
                    {
                        isLoading ?
                         <div className={styles.loading}><CircularProgress  size={16} color="inherit" /> </div>
                         :
                        <p>{moneyMaskNumber(myAccount?.balanceToBeReleased)}</p>
                    }
                </div>
                <div className={styles.separator}></div>
                <div>
                    <div>   
                        <img src={NoBalanceIcon} alt="Pix_Icon" style={{width: '32px', height: '22px'}}/>                    
                        <p>Saldo <b>retido</b></p>
                    </div>
                    {
                        isLoading ?
                         <div className={styles.loading}><CircularProgress  size={16} color="inherit" /></div>
                          :
                        <p>{moneyMaskNumber(myAccount?.retainedBalance)}</p>
                    }
                </div>
            </div>
        </div>
    )
}
