import React, { FC, useEffect } from 'react'
import styles from './SupplyStockMovementPage.module.scss'
import UseQueryState from '../../hook/UseQueryFilter';
import { IGetSupplyStockMovementListRequest } from 'modules/meepErp/application/dtos/supplyStockMovement/ISupplyStockMovementDtos';
import SupplyStockMovementsService from 'modules/meepErp/services/api/SupplyStockMovements/SupplyStockMovementsService';
import { useMutation, useQuery } from 'react-query';
import { useLocal } from 'modules/local/presentation/context/LocalContext';
import SupplyStockMovementList from '../../components/supplyStockMovement/supplyStockMovementList/SupplyStockMovementList';
import { IAutocompletePaginatedRequest, } from '../../components/autocomplete/AutocompleteInput';
import { getSuppliesListUseCase } from 'modules/meepErp/application/useCases/supply/SupplyUseCases';
import { getStockOperationsUseCase } from 'modules/meepErp/application/useCases/operations/StockOperationsUseCase';
import { getSupplyStorageLocalsUseCase } from 'modules/meepErp/application/useCases/supplyLocals/SupplyLocalsUseCase';
import { ISupplyFilterValue } from '../../components/supply/supplyList/supplyFilter/ISupplyFilter';
import TabsErp from '../../components/ui/tabs/TabsErp';
import { tabsStock } from '../../ErpRoutes';
import { useBreadcumbs } from 'components/breadcumbs/BreadcumbsContext';
export interface ISupplyStockMovementPageProps {
    //propertys
}
const SupplyStockMovementPage: FC<ISupplyStockMovementPageProps> = () => {

    const { currentLocal } = useLocal();
    const { updateRouters } = useBreadcumbs();

    useEffect(() => {
        updateRouters([
            {
                title: "Gestão de estoque",
            },
            {
                title: "Estoque",
            },
            {
                title: "Movimento de estoque",
            },
        ]);

        return () => {
            updateRouters([]);
        };
    }, [updateRouters]);


    const { state: filter, updateState: updateFilter } = UseQueryState<IGetSupplyStockMovementListRequest>();


    const getList = useQuery(['supplyStockMovements', currentLocal, filter], async () => {
        if (!currentLocal)
            throw new Error("Local não encontrado");
        if (filter.dateStart && filter.storageLocationId) {
            return await GetSupplyStockMovementListUseCase(currentLocal.id, filter);
        }

    }, {
        enabled: !!currentLocal
    });


    const getSupplyMutation = useMutation(async (request: ISupplyFilterValue) => {
        if (!currentLocal)
            throw new Error("Local não encontrado");
        const response = await getSuppliesListUseCase(currentLocal.id, { ...request });
        return response;
    });

    const getOperation = useMutation(async (request: IAutocompletePaginatedRequest) => {
        if (!currentLocal)
            throw new Error("Local não encontrado");
        const response = await getStockOperationsUseCase(currentLocal.id, { ...request, name: request.keyword });
        return response;
    });

    const getSuplyStorageLocals = useMutation(async (request: IAutocompletePaginatedRequest) => {
        if (!currentLocal)
            throw new Error("Local não encontrado");
        return await getSupplyStorageLocalsUseCase(request);
    });

    return (
        <div id={styles.SupplyStockMovementPage} >
            <TabsErp data={tabsStock} />
            <SupplyStockMovementList
                supplyStockMovements={getList.data}
                filter={filter}
                onChangeFilter={updateFilter}
                isLoading={getList.isLoading}
                getSupply={getSupplyMutation.mutateAsync}
                getOperation={getOperation.mutateAsync}
                getSuplyStorageLocals={getSuplyStorageLocals.mutateAsync}
            />
        </div>
    )
}
export default SupplyStockMovementPage

function GetSupplyStockMovementListUseCase(localId: string, filter: any) {
    const service = SupplyStockMovementsService();
    const filterApply = {
        ...filter,
        orderBy: filter.orderBy ?? 'createdAt',
        ascending: filter.orderBy ? filter.ascending : true
    }
    return service.getSupplyStockMovements(localId, filterApply);
}
