import { useLocal } from "modules/local/presentation/context/LocalContext";
import { MutableRefObject, useCallback, useState } from "react";
import GeneralDashboardApi from "services/api/generalDashboard/GeneralDashboardApi";
import { ICashless } from "../interfaces/ICashless";
import GetCashlessUseCase from "modules/generalDashboard/application/useCases/GetCashlessUseCase";
import { IFilterValue } from "../interfaces/IFilter";
import { ICashierEvent } from "../interfaces/ICashierEvent";

const UseCashless = (filter: IFilterValue | undefined, isLoadingCashier: boolean,  lastCashierOpenData: MutableRefObject<ICashierEvent | undefined>, isLoadingFinancialInfo: boolean | undefined) => {

  const [isLoading, setIsLoading] = useState<boolean>();
  const [cashlessData, setCashlessData] = useState<ICashless>()
  const { currentLocal } = useLocal();

  const getCashless= useCallback(
    async () => {
      try {
        const serviceGeneral = GeneralDashboardApi();
        setIsLoading(true);
        if (!isLoadingCashier && !isLoadingFinancialInfo && currentLocal) {
          const cashierIds = filter?.cashierIds && filter.cashierIds.length > 0
            ? filter.cashierIds
            : lastCashierOpenData.current?.id
            ? [lastCashierOpenData.current.id]
            : undefined;

          if (!cashierIds) return;
          
          const response = await GetCashlessUseCase(serviceGeneral,
            currentLocal.id, 
            {
              ...filter,
              cashierIds: cashierIds,
            });

          setCashlessData(response);
          return response;
        }
      } finally {
        setIsLoading(false);
      }
    },
    [currentLocal, filter, isLoadingCashier, isLoadingFinancialInfo, lastCashierOpenData]
  );

  return {
    cashlessData,
    isLoading,
    getCashless,
    setCashlessData,
  };
};

export default UseCashless;
