import React, { FC, PropsWithChildren, forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react'
import styles from './SelectSupplyParentGroup.module.scss'
import { Icon, Skeleton } from '@mui/material';
import { IconButton, Radio } from '@material-ui/core';
import { IPaginatedResponse } from 'modules/meepErp/application/dtos/paginated/IPaginatedResponse';
import { useQuery } from 'react-query';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { IGetSupplyItemNameResponse, IGetSupplyNameRequest, IGetSupplyNameResponse } from 'modules/meepErp/application/dtos/supply/supplyGroup/IGetSupplyNameDtos';
import AutocompleteInput from 'modules/meepErp/presentation/components/autocomplete/AutocompleteInput';

export interface ISupplyGroupItem {
    code: string;
    name: string;
    id: string,
    canUpdateInventory: boolean;
    parentId?: string;
    isActive: boolean,
    hasChildren: boolean,
    quantitySubGroups: number,
}
export interface ISupplyGroupList extends IPaginatedResponse<ISupplyGroupItem> {

}

export interface ISupplyGroupListRequest {
    parentId?: string;
    code?: string
}

export interface ISelectSupplyParentGroupProps {
    //propertys
    getSupplyGroupList?: (request: ISupplyGroupListRequest) => Promise<ISupplyGroupList>
    onSelect?: (item: ISupplyGroupItem) => void
    id?: string,
    value?: string
    code?: string
    GetSearchNameSupplyGroup?: (request: IGetSupplyNameRequest) => Promise<IGetSupplyNameResponse>
}
const SelectSupplyParentGroup: FC<ISelectSupplyParentGroupProps> = ({
    getSupplyGroupList,
    onSelect,
    id,
    code,
    value,
}) => {

    const {
        data: subGroupList,
        isLoading
    } = useQuery(['getSupplyGroup', id, code], () => getSupplyGroupList?.({ parentId: id, code: code }))

    const onSelectHandler = useCallback((item: ISupplyGroupItem) => {
        onSelect?.(item)
    }, [onSelect]);

    return (
        <div id={styles.SelectSupplyParentGroup} >
            <div className={styles.container} >
                {
                    isLoading ?
                        <>
                            <Skeleton height={36} style={{ marginRight: 12 }} />
                            <Skeleton height={36} style={{ marginRight: 12 }} />
                        </>
                        :
                        subGroupList && subGroupList?.items.map((item, index) => (
                            <SupplyGroupItem
                                key={index}
                                item={item}
                                onSelect={onSelectHandler}
                                selectedId={value}
                                sub={!id}
                            >
                                <SelectSupplyParentGroup
                                    getSupplyGroupList={getSupplyGroupList}
                                    onSelect={onSelectHandler}
                                    value={value}
                                    id={item.id}
                                />
                            </SupplyGroupItem>
                        ))
                }
            </div>
        </div>
    )
}
interface ISupplyGroupItemProps {
    item: ISupplyGroupItem
    onSelect: (item: ISupplyGroupItem) => void
    onOpen?: (item: ISupplyGroupItem) => void
    selectedId?: string
    sub?: boolean
}

interface ISupplyGroupItemRef {
    close: () => void
}
const SupplyGroupItem = forwardRef<ISupplyGroupItemRef, PropsWithChildren<ISupplyGroupItemProps>>(({ item, onSelect, selectedId, children, sub }, ref) => {
    const [open, setOpen] = useState(false)
    const close = () => {
        setOpen(false)
    }
    useImperativeHandle(ref, () => ({
        close,
    }), [])

    return (
        <div id={styles.SupplyGroupItem} style={{ boxShadow: !!sub ? "0px 0px 5px 0px rgba(0,0,0,0.1)" : "unset" }}>
            <div className={styles.itemContainer} >
                <div className={styles.item} >
                    <div className={styles.name}>
                        <Radio checked={item.id === selectedId} onChange={() => onSelect(item)} disabled={item.hasChildren}/>
                        <b>{item.name}</b> <span style={{ opacity: 0.5 }}>{item.code}</span>
                    </div>
                    {item.hasChildren && (
                        open ?
                            <IconButton onClick={() => setOpen(!open)} >
                                <Icon>expand_less</Icon>
                            </IconButton>
                            :
                            <IconButton onClick={() => setOpen(!open)} >
                                <Icon>expand_more</Icon>
                            </IconButton>
                    )}
                </div>
                {open && children}
            </div>
        </div>
    )
})

const SelectSupplyParentGroupWrapper: FC<ISelectSupplyParentGroupProps> = (props) => {
    const [searchNameSupplySelect, setSearchNameSupplySelect] = useState<IGetSupplyItemNameResponse>()

    const getSearchNameSupplyGroup = (request: IGetSupplyNameRequest) => {
        if (request.keyword.length > 0 && props.GetSearchNameSupplyGroup) {
            return props.GetSearchNameSupplyGroup(request);
        } else {
            return Promise.resolve({
                page: 1,
                pageSize: 0,
                totalPages: 1,
                items: []
            });
        }
    };

    return (
        <div>
            <div className={styles.description}>
                <b><InfoOutlinedIcon />Selecionar grupo pai</b>
                O grupo/subgrupo selecionado será o pai do que está sendo criado
            </div>
            <div className={styles.search}>
            <AutocompleteInput
                getOptionLabel={(option) => `${option?.name}`}
                textInput={searchNameSupplySelect?.name}
                getOptionDescription={(option) => option?.code ?? ''}
                getList={(request) => getSearchNameSupplyGroup(request)}
                onSelect={(value) => {
                    value && setSearchNameSupplySelect(value);
                }}
                value={searchNameSupplySelect}
                onChange={(value) => {
                    value.length === 0 && setSearchNameSupplySelect(value)
                }}
            />
            </div>
            <SelectSupplyParentGroup {...props} code={searchNameSupplySelect?.code}/>
        </div>
    );
}
export default SelectSupplyParentGroupWrapper