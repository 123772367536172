import React from "react";
import styles from "./PreRecharge.module.scss";
import { IPreRecharge } from "../../interfaces/IPreRecharge";
import { CircularProgress } from "@material-ui/core";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { moneyMaskNumber } from "services/utils/Money";

interface ICashlessProps {
  preRechargeData: IPreRecharge | undefined;
  isLoading: boolean | undefined;
}

const PreRecharge: React.FC<ICashlessProps> = ({ 
  isLoading, 
  preRechargeData, 
}) => {
  const {isMobile} = useUi();

  return (
    <div id={styles.PreRecharge}>
      <div className={styles.topTitle}>
        <p>Pré-recarga</p>
      </div>
      <div className={!isMobile ? styles.container : styles.containerMobile }>
        <div className={styles.containerLeft}>
          <div className={styles.top}>
            <p>Total</p>
            {isLoading ? (
              <div className={styles.loading}>
                <CircularProgress size={16} color="inherit" />{" "}
              </div>
            ) : (
              <span>{moneyMaskNumber(preRechargeData?.total)}</span>
            )}
          </div>
          <div className={styles.bottom}>
            <div>
              <p>Pix</p>
              {isLoading ? (
                <div className={styles.loading}>
                  <CircularProgress size={16} color="inherit" />{" "}
                </div>
              ) : (
                <span>{moneyMaskNumber(preRechargeData?.pix)}</span>
              )}
            </div>
            <div>
              <p>Crédito</p>
              {isLoading ? (
                <div className={styles.loading}>
                  <CircularProgress size={16} color="inherit" />{" "}
                </div>
              ) : (
                <span>{moneyMaskNumber(preRechargeData?.credit)}</span>
              )}
            </div>
            {
              (preRechargeData?.other !== undefined && preRechargeData?.other > 0) && (
                <div>
                  <p>Outros</p>
                  {isLoading ? (
                    <div className={styles.loading}>
                      <CircularProgress size={16} color="inherit" />{" "}
                    </div>
                  ) : (
                    <span>{moneyMaskNumber(preRechargeData?.other)}</span>
                  )}
                </div>
              )
            }
          </div>
        </div>
        {
          !isMobile &&
            <div className={styles.separator}></div>
        }
        <div className={styles.containerRight}>
          <div className={styles.bottom}>
            <div>
              <p className={styles.totalText}>Valor total ativado</p>
              {isLoading ? (
                <div className={styles.loading}>
                  <CircularProgress size={16} color="inherit" />{" "}
                </div>
              ) : (
                <p className={styles.totalValue}>{moneyMaskNumber(preRechargeData?.totalValueActivated)}</p>
              )}
            </div>
            <div>
              <p className={styles.totalText}>Valor residual</p>
              {isLoading ? (
                <div className={styles.loading}>
                  <CircularProgress size={16} color="inherit" />{" "}
                </div>
              ) : (
                <p className={styles.totalValue}>{moneyMaskNumber(preRechargeData?.residualValue)}</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreRecharge;
