import mqtt, { IClientOptions, MqttClient } from "mqtt"
import { v4 } from "uuid";

export let client: MqttClient | null = null;

export interface ISocketService {
    sendMessageAndWaitResponse: <ResponseType = object, RequestType = object>(request: { reponseTopic?: string, requestTopic: string, data: RequestType }, timeout?: number) => Promise<{ topic: string, data: ResponseType }>;
    setOnMensage: (callback: (topic: string, data: string) => void) => void;
    connect: () => Promise<MqttClient>;
    disconnect: () => Promise<void>;
}
const MqttService = () => {
    let onMensage: (topic: string, data: string) => void;
    const connect = async () => {
        try {
            const config: IClientOptions = {
                hostname: 'b-4bfafc79-9eac-4420-b241-73eba616c1f6-1.mq.us-east-2.amazonaws.com',
                // hostname: 'b-4bfafc79-9eac-4420-b241-73eba616c1f6-1.mq.us-east-2.amazonaws.com',
                port: 61619,
                protocol: 'wss',
                clientId: 'Terminal-' + v4(),
                username: 'Terminal',
                password: 'Terminal@Meep',
                // reconnectPeriod: 0,
            };
            console.log('Connecting to MQTT broker')
            client = await mqtt.connectAsync(config);
            console.log('Connected to MQTT broker');
            return client;
        } catch (err) {
            console.log(err)
            throw new Error('Error connecting to MQTT broker');
        }
    }

    const setOnMensage = (callback: (topic: string, message: string) => void) => {
        onMensage = callback;
    }
    const disconnect = async () => {
        try {
            await client?.endAsync(true)
        } catch (err) {
            console.log(err)
            throw new Error('Error connecting to MQTT broker');
        }
    }
    const sendMessageAndWaitResponse = async <ResponseType = object, RequestType = object>(request: { reponseTopic?: string, requestTopic: string, data: RequestType }, timeout?: number): Promise<{ topic: string, data: ResponseType }> => {
        const promise = new Promise<{ topic: string, data: ResponseType }>(async (resolve, reject) => {
            let disconnect = false;
            try {
                const responseTopic = request.reponseTopic ?? (`RESPONSE/${request.requestTopic}`);
                var timer = setTimeout(() => {
                    reject({ message: 'Timeout', error: 'Timeout' });
                    if (disconnect) {
                        client?.unsubscribeAsync(responseTopic);
                        // client?.endAsync();
                    }
                }, timeout ?? 60000);

                if (!client?.connected) {
                    await connect();
                    disconnect = true;
                }
                client?.on('message', async (topic, message) => {
                    if (topic === responseTopic) {

                        if (disconnect) {
                            client?.unsubscribeAsync(responseTopic);
                            // client?.endAsync();
                        }
                        var response = JSON.parse(message.toString());
                        if (response.error) {
                            reject(response.error);
                            clearTimeout(timer);
                            return;
                        }
                        resolve({ topic, ...JSON.parse(message.toString()) });
                        clearTimeout(timer);
                    } else {
                        if (disconnect) {
                            client?.unsubscribeAsync(responseTopic);
                            // client?.endAsync();
                        }
                        onMensage?.(topic, message.toString());
                    }
                });
                console.log('Subscribing to topic', responseTopic)
                await client?.subscribeAsync(responseTopic);
                console.log('Publishing message to topic', request.requestTopic)
                await client?.publishAsync(request.requestTopic, JSON.stringify({ ...request.data }));
            } catch (err) {
                console.log(err)
                reject({ message: 'Error sending message', error: err });
            }
        })
        return promise;
    }

    return ({
        sendMessageAndWaitResponse,
        setOnMensage,
        connect,
        disconnect
    })

}
export default MqttService