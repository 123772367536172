import SaasDigitalMenuPage from "modules/gestaoVendas/presentation/pages/saasDigitalMenu/SaasDigitalMenuPage";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { OnlineSolutionsPage } from "modules/gestaoVendas/presentation/pages/onlineSolutions/OnlineSolutionsPage";

const DigitalMenu = () => {
  const { currentLocal } = useLocal();

  return (
    <>
      {currentLocal?.systemIdentifier === 1 ? <SaasDigitalMenuPage /> : <OnlineSolutionsPage />}
    </>
  )
};

export default DigitalMenu;

