import { format } from "date-fns";
import styles from "./SalesByDayItemMobile.module.scss";
import { moneyMaskNumber } from "services/utils/Money";
import { IGetSalesByDaysVenda } from "modules/SaleForDayPage/domain/dto/IGetSalesByDayResponse";

interface ISalesByDayItem {
  item: IGetSalesByDaysVenda;
}

const SalesByDayItemMobile = ({ item }: ISalesByDayItem) => {
  return (
    <div id={styles.salesByDayItemMobile}>
      <div className={styles.saleItem}>
        <div className={styles.item}>
          <span className={styles.title}>Data:</span>
          <span>{format(new Date(item.DataVendas), 'dd/MM/yyyy')}</span>
        </div>
        <div className={styles.item}>
          <span className={styles.title}>Cashless</span>
          <span>{moneyMaskNumber(item.Cashless ?? 0)}</span>
        </div>
        <div className={styles.item}>
          <span className={styles.title}>Cancelado:</span>
          <span>{moneyMaskNumber(item.Cancelado ?? 0)}</span>
        </div>
        <div className={styles.item}>
          <span className={styles.title}>Boleto:</span>
          <span>{moneyMaskNumber(item.Boleto ?? 0)}</span>
        </div>
        <div className={styles.item}>
          <span className={styles.title}>Débito:</span>
          <span>{moneyMaskNumber(item.Debito ?? 0)}</span>
        </div>
        <div className={styles.item}>
          <span className={styles.title}>Débito (NT):</span>
          <span>{moneyMaskNumber(item.DebitoNT ?? 0)}</span>
        </div>
        <div className={styles.item}>
          <span className={styles.title}>Crédito:</span>
          <span>{moneyMaskNumber(item.Credito ?? 0)}</span>
        </div>
        <div className={styles.item}>
          <span className={styles.title}>Crédito (NT):</span>
          <span>{moneyMaskNumber(item.CreditoNT ?? 0)}</span>
        </div>
        <div className={styles.item}>
          <span className={styles.title}>Dinheiro:</span>
          <span>{moneyMaskNumber(item.Dinheiro ?? 0)}</span>
        </div>
        <div className={styles.item}>
          <span className={styles.title}>Desconto:</span>
          <span>{moneyMaskNumber(item.Desconto ?? 0)}</span>
        </div>
        <div className={styles.item}>
          <span className={styles.title}>Voucher:</span>
          <span>{moneyMaskNumber(item.Voucher ?? 0)}</span>
        </div>
        <div className={styles.item}>
          <span className={styles.title}>Outros:</span>
          <span>{moneyMaskNumber(item.Outros ?? 0)}</span>
        </div>
        <div className={styles.item}>
          <span className={styles.title}>Em aberto:</span>
          <span>{moneyMaskNumber(item.EmAberto ?? 0)}</span>
        </div>
        <div className={styles.item}>
          <span className={styles.title}>Total transacionado:</span>
          <span>{moneyMaskNumber(item.ValorTotalTransacionado ?? 0)}</span>
        </div>
        <div className={styles.item}>
          <span className={styles.title}>Total não transacionado:</span>
          <span>{moneyMaskNumber(item.ValorTotalNaoTransacionado ?? 0)}</span>
        </div>
        <div className={styles.item}>
          <span className={styles.title}>Total:</span>
          <span>{moneyMaskNumber(item.Total ?? 0)}</span>
        </div>
      </div>
    </div>
  );
};
export default SalesByDayItemMobile;
