 import React, { ChangeEvent, FC, FormEvent, useCallback, useEffect, useState } from "react";
import styles from "./OperatorForm.module.scss";
import { IOperatorFormValue, IOperatorRoleOption, OperatorRolesOptions } from "./IOperatorValue";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import Button from "components/ui/Button/Button";
import { CheckCircleOutlined, ErrorOutlineOutlined, VisibilityOffOutlined, VisibilityOutlined } from "@material-ui/icons";
import { useAuth } from "modules/auth/presentation/context/AuthContext";
export interface IOperatorFormProps {
    //propertys
    defaultValue?: IOperatorFormValue;
    onSubmit: (value: IOperatorFormValue) => void;
    isEdit?: boolean;
    isLoading?: boolean;
    handleGoBack: () => void;
    decrypt?: (encryptedMessage: string) => string | null | undefined;
}
const OperatorForm: FC<IOperatorFormProps> = ({ defaultValue, onSubmit, isEdit, isLoading, handleGoBack, decrypt }) => {
    const { hasAccessRole } = useAuth();

    const [isAdmin, setIsAdmin] = useState(false);
    const [value, setValue] = useState<IOperatorFormValue>(defaultValue ?? {
        name: "",
        password: "",
        confirmPassword: "",
        privilegedAccess: false,
        roles: [],
        commission: 0,
        useCashlessCardAsPassword: false,
        tag: ""
    });

    const [error, setError] = useState<{
        name?: string,
        password?: string,
        confirmPassword?: string,
        privilegedAccess?: string,
        roles?: string,
        commission?: string,
        useCashlessCardAsPassword?: string,
        tag?: string
    }>({});

    const [showValidationPass, setShowValidationPass] = useState(false);
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    useEffect(() => {
        if (defaultValue) {
            setValue({...defaultValue, password: "", confirmPassword: "" });

            if (hasAccessRole("VisualizarMenuTerminalDeVendas") && hasAccessRole("VisualizarMenuTerminalOnline") && hasAccessRole("VisualizarMenuMaquinaDeCartao")) {
                setIsAdmin(true);
            }
        }
    }, [defaultValue, hasAccessRole])

    const onChangeHandler = useCallback((ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setValue((prev) => ({ ...prev, [ev.target.name]: ev.target.value }));
    }, []);

    const onChangeOperatorRoles = useCallback((ev: React.SyntheticEvent<Element, Event>, value: IOperatorRoleOption[]) => {
        if (value.find((item) => item.value === "SelecionarTodas")) {
            const allOptions = OperatorRolesOptions.filter((item) => item.value !== "SelecionarTodas");
            return setValue((prev) => ({ ...prev, roles: allOptions }));
        }
        setValue((prev) => ({ ...prev, roles: value }));
    }, []);

    // const onChangePrivilegedAccess = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
    //     setValue((prev) => ({ ...prev, privilegedAccess: ev.target.checked }));
    // }, []);

    // const onChangeUseCashlessCardAsPassword = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
    //     setValue((prev) => ({ ...prev, useCashlessCardAsPassword: ev.target.checked }));
    // }, []);

    const validate = useCallback((values: IOperatorFormValue) => {
        if (!values.name) {
            setError(prev => ({ ...prev, name: "Nome é obrigatório" }))
            return false;
        }
        if (!values.password && !isEdit) {
            setError(prev => ({ ...prev, password: "Senha é obrigatória" }))
            return false;
        }
        if (!values.confirmPassword && !isEdit) {
            setError(prev => ({ ...prev, confirmPassword: "Confirmação de senha é obrigatória" }))
            return false;
        }
        if (values.password !== values.confirmPassword && !isEdit) {
            setError(prev => ({ ...prev, confirmPassword: "A senha e a confirmação de senha devem ser iguais" }))
            return false;
        }
        return true;
    }, [isEdit]);

    const handlePasswordValidations = useCallback(() => {
        if (
            !value.password.includes(" ") &&
            /^\d+$/.test(value.password) &&
            !(/(123|234|345|456|567|678|789|890|098|987|876|765|654|543|432|321|210)/.test(value.password)) &&
            value.password.length >= 6
        ) {
            return false;
        }
        return true;
    }, [value.password]);

    const onSubmitHandler = useCallback((ev: FormEvent<HTMLFormElement>) => {
        ev.preventDefault();
        if (!validate(value)) return;
        onSubmit(value);
    }, [validate, value, onSubmit]);

    return (
      <div id={styles.OperatorForm}>
        <form className={styles.container} onSubmit={onSubmitHandler}>
            <div className={styles.form}>
                <div className={styles.column}>
                    <label htmlFor="name">
                        Nome do operador
                    </label>
                    <TextField
                        variant="outlined"
                        name="name"
                        size="small"
                        value={value.name}
                        helperText={error.name}
                        error={!!error.name}
                        onChange={(ev) => {
                            setError({...error, name: undefined });
                            onChangeHandler(ev);
                        }}
                    />
                </div>
                {defaultValue && isAdmin && decrypt && (
                    <div className={styles.column}>
                        <label htmlFor="current-password">
                            Senha atual
                        </label>
                        <TextField
                            variant="outlined"
                            name="current-password"
                            size="small"
                            type={showCurrentPassword ? "text" : "password"}
                            value={decrypt(defaultValue.password)}
                            disabled
                            className={styles.disabled}
                        />
                        <div
                            className={styles.showPassword}
                            onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                        >
                            {!showCurrentPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                        </div>
                    </div>
                )}
                <div className={styles.column}>
                    <label htmlFor="password">
                        Senha
                    </label>
                    <TextField
                        variant="outlined"
                        name="password"
                        size="small"
                        type={showPassword ? "text" : "password"}
                        value={value.password}
                        helperText={error.password}
                        error={!!error.password}
                        onChange={(ev) => {
                            setError({...error, password: undefined });
                            onChangeHandler(ev);
                        }}
                        onFocus={() => setShowValidationPass(true)}
                    />
                    <div
                        className={styles.showPassword}
                        onClick={() => setShowPassword(!showPassword)}
                    >
                        {!showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                    </div>
                    {showValidationPass && (
                        <div className={styles.validatePassContainer}>
                            <div>
                                {value.password.includes(" ") 
                                    ? <ErrorOutlineOutlined style={{ color: "#F44336" }} /> 
                                    : <CheckCircleOutlined style={{ color: value.password ? "#399C54" : "#A2A2A2" }} />
                                }
                                <span>Não utilize espaços</span>
                            </div>
                            <div>
                                {!!value.password && /^\d+$/.test(value.password) 
                                    ? <CheckCircleOutlined style={{ color: value.password ? "#399C54" : "#A2A2A2" }} /> 
                                    : <ErrorOutlineOutlined style={{ color: "#F44336" }} />
                                }
                                <span>Use apenas números</span>
                            </div>
                            <div>
                                {/(123|234|345|456|567|678|789|890|098|987|876|765|654|543|432|321|210)/.test(value.password) 
                                    ? <ErrorOutlineOutlined style={{ color: "#F44336" }} /> 
                                    : <CheckCircleOutlined style={{ color: value.password ? "#399C54" : "#A2A2A2" }} />
                                }
                                <span>Não use números sequenciais (ex.: 131313, 123456, 102030)</span>
                            </div>
                            <div>
                                {value.password && value.password.length >= 6
                                    ? <CheckCircleOutlined style={{ color: value.password ? "#399C54" : "#A2A2A2" }} /> 
                                    : <ErrorOutlineOutlined style={{ color: "#F44336" }} />                                
                                }
                                <span>Mínimo de 6 caracteres</span>
                            </div>
                        </div>
                    )}
                </div>
                <div className={styles.column}>
                    <label htmlFor="confirmPassword">
                        {defaultValue ? "Confirmar alteração de senha" : "Confirmar senha"}
                    </label>
                    <TextField
                        variant="outlined"
                        name="confirmPassword"
                        size="small"
                        type={showConfirmPassword ? "text" : "password"}
                        value={value.confirmPassword}
                        helperText={error.confirmPassword}
                        error={!!error.confirmPassword}
                        onChange={(ev) => {
                            setError({...error, confirmPassword: undefined });
                            onChangeHandler(ev);
                        }}
                    />
                    <div
                        className={styles.showPassword}
                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    >
                        {!showConfirmPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                    </div>
                </div>
            
                {/* <TextField
                    variant="outlined"
                    label="Comissão"
                    name="commission"
                    size="small"
                    value={value.commission}
                    helperText={error.commission}
                    error={!!error.commission}
                    onChange={onChangeHandler}
                />
                <FormControlLabel
                    control={
                    <Checkbox
                        name="useCashlessCardAsPassword"
                        value={value.useCashlessCardAsPassword}
                        onChange={onChangeUseCashlessCardAsPassword}
                    />
                    }
                    label="Usar Cartão RFID como Senha"
                />
                {value.useCashlessCardAsPassword && (
                    <TextField
                    variant="outlined"
                    label="Tag"
                    name="tag"
                    value={value.tag}
                    helperText={error.tag}
                    error={!!error.tag}
                    onChange={onChangeHandler}
                    />
                )}
                <FormControlLabel
                    control={
                    <Checkbox
                        name="privilegedAccess"
                        value={value.privilegedAccess}
                        onChange={onChangePrivilegedAccess}
                    />
                    }
                    label="Acesso Privilegiado"
                /> */}

                <div className={styles.column}>
                    <label htmlFor="permissions">
                        Permissões
                    </label>
                    <Autocomplete
                        multiple
                        id="tags-standard"
                        size="small"
                        limitTags={4}
                        options={OperatorRolesOptions}
                        getOptionLabel={(option) => option.label}
                        value={value.roles}
                        isOptionEqualToValue={(option, value) =>
                            option.value === value.value
                        }
                        onChange={onChangeOperatorRoles}
                        renderInput={(params) => (
                            <TextField {...params} variant="outlined" />
                        )}
                    />
                </div>
                <a href="https://www.ajuda.meep.com.br/operadores-mee" target="_blank" rel="noreferrer">Entenda cada permissão</a>
            </div>
            <div className={styles.footerButtons}>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleGoBack}
                >
                    Voltar
                </Button>
                <Button
                    disabled={isLoading || handlePasswordValidations()}
                    color="primary"
                    type="submit"
                >
                    {isLoading ? <CircularProgress size={16} color="inherit" /> : "Salvar"}
                </Button>
            </div>
        </form>
      </div>
    );
}
export default OperatorForm

