import { useCallback, useEffect, useState } from "react";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import MenuApi from "services/api/menu/MenuApi";
import GetMenuUseCase from "components/layout/application/useCases/GetMenuUseCase";
import { IGetMenuResponse } from "components/layout/domain/dto/IGetMenuResponse";
import { useMenuContext } from "./context/MenuContext";
import PostMenuFavoriteUseCase from "components/layout/application/useCases/PostMenuFavoriteUseCase";
import { IPostMenuRequest } from "components/layout/domain/dto/IPostMenuRequest";
import DeleteMenuFavoriteUseCase from "components/layout/application/useCases/DeleteMenuFavoriteUseCase";
import PostMenuActivityUseCase from "components/layout/application/useCases/PostMenuActivityUseCase";
import GetIsMenuFavoriteUseCase from "components/layout/application/useCases/GetIsMenuFavoriteUseCase";
import { IGetIsMenuFavoriteResponse } from "components/layout/domain/dto/IGetIsMenuFavoriteResponse";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import GetMenuSearchUseCase from "components/layout/application/useCases/GetMenuSearchUseCase";
import { IGetMenuSearchResponse } from "components/layout/domain/dto/IGetMenuSearchResponse";
import GetMenuHistoryUseCase from "components/layout/application/useCases/GetMenuHistoryUseCase";
import { IGetMenuHistoryResponse } from "components/layout/domain/dto/IGetMenuHistoryResponse";
import { mockMenuStrings } from "../settings/MOCKSETTINGS";
import { useAuth } from "modules/auth/presentation/context/AuthContext";
import Utils from "services/utils/Utils";

const service = MenuApi();

export const UseMenuList = () => {
  const { showLoading, hideLoading, toast } = useUi();
  const { menuPosition, setMenuListContext, setMenuLoading } = useMenuContext();
  const { currentLocal } = useLocal();
  const [menuList, setMenuList] = useState<IGetMenuResponse[]>();
  const [menuSettings, setMenuSettings] = useState<IGetMenuResponse[]>([]);
  const [isMenuFavorite, setIsMenuFavorite] = useState<IGetIsMenuFavoriteResponse>();
  const [menuSearchList, setMenuSearchList] = useState<IGetMenuSearchResponse[]>();
  const [menuHistoryList, setMenuHistoryList] = useState<IGetMenuHistoryResponse[]>();
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [loadingHistoryList, setLoadingHistoryList] = useState(false);

  const { auth } = useAuth();

  const getToken = localStorage.getItem("@token");
  const token = getToken && JSON.parse(getToken);

  const getMenuList = useCallback(
    async (positionType: number) => {
      if (token?.access_token && currentLocal && currentLocal.systemIdentifier !== undefined) {
        try {
          setMenuLoading(true);
          const response = await GetMenuUseCase(
            service,
            token.access_token,
            positionType, currentLocal.id,
            currentLocal.systemIdentifier
          );

          if (positionType > 0) {
            return setMenuList(response);
          }
          setMenuSettings(prev => [...response, ...prev]);
        } catch (error: any) {
          if (error?.response?.data?.error_description) {
            toast(error.response?.data.error_description, "error");
          }
        } finally {
          setMenuLoading(false);
        }
      }
    },
    [token?.access_token, currentLocal, setMenuLoading, toast]
  );

  const getIsMenuFavorite = useCallback(async (params: IPostMenuRequest) => {
    try {
      const response = await GetIsMenuFavoriteUseCase(service, params);
      setIsMenuFavorite(response);
    } catch {
      setIsMenuFavorite({ isFavorited: false });
    }
  }, []);


  useEffect(() => {
    if (auth?.roles) {
      const mockSettings = mockMenuStrings(auth?.roles ?? []);
      setMenuSettings(prev => [...prev, ...mockSettings]);
    }
  }, [auth?.roles]);

  const postMenuFavorite = useCallback(
    async (request: IPostMenuRequest) => {
      try {
        showLoading();
        await PostMenuFavoriteUseCase(service, request);
        getMenuList(menuPosition);
        setIsMenuFavorite({ isFavorited: true });
      } finally {
        hideLoading();
      }
    },
    [
      hideLoading,
      showLoading,
      getMenuList,
      menuPosition,
    ]
  );

  const deleteMenuFavorite = useCallback(
    async (params: IPostMenuRequest) => {
      try {
        showLoading();
        await DeleteMenuFavoriteUseCase(service, params);
        getMenuList(menuPosition);
        setIsMenuFavorite({ isFavorited: false });
      } finally {
        hideLoading();
      }
    },
    [
      hideLoading,
      showLoading,
      getMenuList,
      menuPosition,
    ]
  );

  const postMenuActivity = useCallback(
    async (request: IPostMenuRequest) => {
      await PostMenuActivityUseCase(service, request);
    },
    []
  );

  const getMenuSearch = useCallback(
    async (keyword: string) => {
      try {
        setLoadingSearch(true);
        const response = await GetMenuSearchUseCase(service, keyword);
        if (!!response) {
          return setMenuSearchList(response);
        }
        setMenuSearchList([]);
      } catch (error: any) {
        if (error?.response?.data?.error_description) {
          toast(error.response?.data.error_description, "error");
        }
      } finally {
        setLoadingSearch(false);
      }
    },
    [toast]
  );

  const getMenuHistory = useCallback(
    async () => {
      if (currentLocal) {
        try {
          setLoadingHistoryList(true);
          const response = await GetMenuHistoryUseCase(service, currentLocal.id);
          setMenuHistoryList(response);
        } catch (error: any) {
          if (error?.response?.data?.error_description) {
            toast(error.response?.data.error_description, "error");
          }
        } finally {
          setLoadingHistoryList(false);
        }
      }
    },
    [toast, currentLocal]
  );

  useEffect(() => {
    getMenuList(menuPosition);
  }, [menuPosition, getMenuList, postMenuFavorite, deleteMenuFavorite]);

  useEffect(() => {
    setMenuListContext(menuList);
  }, [currentLocal?.id, menuList, setMenuListContext]);

  return {
    menuSettings,
    isMenuFavorite,
    getMenuList,
    postMenuFavorite,
    deleteMenuFavorite,
    postMenuActivity,
    getIsMenuFavorite,
    loadingSearch,
    menuSearchList,
    getMenuSearch,
    menuHistoryList,
    getMenuHistory,
    loadingHistoryList,
  };
};
