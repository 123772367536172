import { v4 } from "uuid";
import { ISocketService } from "../../../services/webSocketMqtt/MqttService"



interface IPrinterRequest {
    id: string;
    printerName: string;
    base64?: string;
    html?: string;
}

interface IPrinterResponse {
    id: string;
    printerName: string;
    printedAt: Date;
}

const mockPrinterRequest: IPrinterRequest = {
    id: "mockId",
    printerName: "mockPrinter",
    base64: "mockBase64"
};

const mockPrinterResponse: IPrinterResponse = {
    id: "mockId",
    printerName: "mockPrinter",
    printedAt: new Date()
};

const SendToSocketPrinterUseCase = async (socket: ISocketService, hubKey: string, data: IPrinterRequest) => {

    const result = await socket.sendMessageAndWaitResponse<IPrinterResponse, IPrinterRequest>({
        // reponseTopic: 'PRINTER/response',
        requestTopic: 'INVOICE/' + hubKey  + '/' + data.printerName+"/"+ v4(),
        data: data
    })
    return (result)
}
export default SendToSocketPrinterUseCase