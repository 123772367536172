import React from "react";
import styles from "./AverageTicketCards.module.scss";
import { moneyMaskNumber } from "services/utils/Money";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { IFinancialInformation } from "../../interfaces/IFinancialInformation";
import { Skeleton } from "@mui/material";

interface AverageTicketCardsProps {
  isLoadingFinancialInfo: boolean | undefined;
  financialInformation: IFinancialInformation | undefined
}

interface CardProps {
  title: string;
  value: number;
  isLoading: boolean | undefined;
}

const Card: React.FC<CardProps> = ({ title, value, isLoading }) => {
  if (value <= 0 && !isLoading) {
    return null;
  }

  return (
    <div>
      <p>{title}</p>
      {isLoading ? (
       
       <Skeleton variant="rectangular" width="100%" height={50} style={{ borderRadius: 8 }} /> 
       
      ) : (
        <span>{moneyMaskNumber(value)}</span>
      )}
    </div>
  );
};

const AverageTicketCards: React.FC<AverageTicketCardsProps> = ({
  isLoadingFinancialInfo,
  financialInformation,
}) => {
  const { isMobile } = useUi();

  return (
    <div id={!isMobile ? styles.AverageTicketCards : styles.AverageTicketCardsMobile}>
      <Card title="Taxa de serviço" value={financialInformation?.serviceFee ?? 0} isLoading={isLoadingFinancialInfo} />
      <Card title="Desconto" value={financialInformation?.discount ?? 0} isLoading={isLoadingFinancialInfo} />
      <Card title="Consumo cashless" value={financialInformation?.cashlessConsumption ?? 0} isLoading={isLoadingFinancialInfo} />
      <Card title="Consumo pós-pago" value={financialInformation?.postPaidConsumption ?? 0} isLoading={isLoadingFinancialInfo} />
      <Card title="Consumo ficha" value={financialInformation?.ticketConsumption ?? 0} isLoading={isLoadingFinancialInfo} />
      <Card title="Consumo produção" value={financialInformation?.productionConsumption ?? 0} isLoading={isLoadingFinancialInfo} />
    </div>
  );
};

export default AverageTicketCards;
