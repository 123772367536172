import { api } from "../Api";
import { invoiceUrl } from "Enviroment";
import { IInvoiceService } from "modules/invoice/domain/interfaces/IInvoiceService";
import { IGetCompanyResponse } from "modules/invoice/domain/dto/IGetCompanyResponse";
import { ISaveCompanyRequest } from "modules/invoice/domain/dto/ISaveCompanyRequest";
import { EnvironmentEnum } from "modules/invoice/presentation/interfaces/EnvironmentEnum";
import { IImportInvoiceConfigFromCsvResponse } from "modules/invoice/domain/dto/IImportInvoiceConfigFromCsvResponse";
import { IGetTaxResponse } from "modules/invoice/domain/dto/IGetTaxResponse";
import { IGetTaxProductsResponse } from "modules/invoice/domain/dto/IGetTaxProductsResponse";
import { ITaxSelect } from "modules/invoice/presentation/interfaces/ITaxSelect";
import { ICreateTaxRequest } from "modules/invoice/domain/dto/ICreateTaxRequest";
import { IGetTaxByIdResponse } from "modules/invoice/domain/dto/IGetTaxByIdResponse";

const InvoiceApi = (): IInvoiceService => {
  const baseUrl = invoiceUrl;

  const getToken = localStorage.getItem("@token");
  const token = getToken && JSON.parse(getToken);

  const createCompany = async (localId: string): Promise<IGetCompanyResponse> => {
    const response = await api.post(`${baseUrl}/Companies`, { id: localId });
    return response.data;
  }

  const editCompany = async (localId: string, request: ISaveCompanyRequest): Promise<IGetCompanyResponse> => {
    const response = await api.put(`${baseUrl}/companies/${localId}`, request);
    return response.data;
  }

  const getCompany = async (localId: string): Promise<IGetCompanyResponse> => {
    const response = await api.get(`${baseUrl}/companies/${localId}`, { params: { disableError: true }, headers: { "Authorization": `Bearer ${token.access_token}` } });
    return response.data;
  }

  const validateCertificate = async (localId: string, base64: string, password: string): Promise<boolean> => {
    const response = await api.post(`${baseUrl}/companies/${localId}/certificate/validate`, { base64, password });
    return response.data.isValid;
  }

  const getEnvironment = async (localId: string): Promise<EnvironmentEnum> => {
    const response = await api.get(`${baseUrl}/companies/${localId}/environment`, { params: { disableError: true }, headers: { "Authorization": `Bearer ${token.access_token}` } });
    return response.data.environment;
  }

  const updateEnvironment = async (localId: string, environment: EnvironmentEnum): Promise<void> => {
    const response = await api.put(`${baseUrl}/companies/${localId}/environment`, { companyId: localId, environment });
    return response.data;
  }

  const importInvoiceConfigFromCsv = async (localId: string, csv: string): Promise<IImportInvoiceConfigFromCsvResponse> => {
    const response = await api.post(`${baseUrl}/companies/importInvoiceConfigFromCsv`, { companyId: localId, csv }, { params: { disableError: true } });
    return response.data;
  }

  const getTax = async (localId: string, keyword?: string, page = 0): Promise<IGetTaxResponse> => {
    const response = await api.get(`${baseUrl}/companies/${localId}/tax`, { params: { keyword, page, pageSize: 20, disableError: true }, headers: { "Authorization": `Bearer ${token.access_token}` } });
    return response.data;
  }

  const getTaxById = async (localId: string, taxId: string): Promise<IGetTaxByIdResponse> => {
    const response = await api.get(`${baseUrl}/companies/${localId}/tax/${taxId}`, { headers: { "Authorization": `Bearer ${token.access_token}` } });
    return response.data;
  }

  const getTaxProducts = async (localId: string, ncm: string, cfop: string): Promise<IGetTaxProductsResponse[]> => {
    const response = await api.get(`${baseUrl}/companies/${localId}/tax/products`, { params: { Cfop: cfop, Ncm: ncm, disableError: true }, headers: { "Authorization": `Bearer ${token.access_token}` } });
    return response.data || [];
  }

  const getTaxFileUrl = async (localId: string): Promise<string> => {
    const response = await api.get(`${baseUrl}/companies/${localId}/taxFile`, { params: { disableError: true }, headers: { "Authorization": `Bearer ${token.access_token}` } });
    return response.data.url;
  }

  const getNcmList = async (localId: string, keyword?: string): Promise<ITaxSelect[]> => {
    const response = await api.get(`${baseUrl}/companies/${localId}/tax/selects/ncm`, { params: { keyword, disableError: true }, headers: { "Authorization": `Bearer ${token.access_token}` } });
    return response.data;
  }

  const getCfopList = async (localId: string, keyword?: string): Promise<ITaxSelect[]> => {
    const response = await api.get(`${baseUrl}/companies/${localId}/tax/selects/cfop`, { params: { keyword, isableError: true }, headers: { "Authorization": `Bearer ${token.access_token}` } });
    return response.data;
  }

  const getIcmsList = async (localId: string): Promise<ITaxSelect[]> => {
    const response = await api.get(`${baseUrl}/companies/${localId}/tax/selects/icms/taxSituations`, { params: { disableError: true }, headers: { "Authorization": `Bearer ${token.access_token}` } });
    return response.data;
  }

  const getIpiList = async (localId: string): Promise<ITaxSelect[]> => {
    const response = await api.get(`${baseUrl}/companies/${localId}/tax/selects/ipi/taxSituations`, { params: { disableError: true }, headers: { "Authorization": `Bearer ${token.access_token}` } });
    return response.data;
  }

  const getCofinsList = async (localId: string): Promise<ITaxSelect[]> => {
    const response = await api.get(`${baseUrl}/companies/${localId}/tax/selects/cofins/taxSituations`, { params: { disableError: true }, headers: { "Authorization": `Bearer ${token.access_token}` } });
    return response.data;
  }

  const getPisList = async (localId: string): Promise<ITaxSelect[]> => {
    const response = await api.get(`${baseUrl}/companies/${localId}/tax/selects/pis/taxSituations`, { params: { disableError: true }, headers: { "Authorization": `Bearer ${token.access_token}` } });
    return response.data;
  }

  const getIcmsSourcesList = async (localId: string): Promise<ITaxSelect[]> => {
    const response = await api.get(`${baseUrl}/companies/${localId}/tax/selects/icms/sources`, { params: { disableError: true }, headers: { "Authorization": `Bearer ${token.access_token}` } });
    return response.data;
  }

  const getBaseModeCalculationIcms = async (localId: string): Promise<ITaxSelect[]> => {
    const response = await api.get(`${baseUrl}/companies/${localId}/tax/selects/baseModeCalculationIcms`, { params: { disableError: true }, headers: { "Authorization": `Bearer ${token.access_token}` } });
    return response.data;
  }

  const getBaseModeCalculationIcmsst = async (localId: string): Promise<ITaxSelect[]> => {
    const response = await api.get(`${baseUrl}/companies/${localId}/tax/selects/baseModeCalculationIcmsst`, { params: { disableError: true }, headers: { "Authorization": `Bearer ${token.access_token}` } });
    return response.data;
  }

  const getStates = async (localId: string): Promise<ITaxSelect[]> => {
    const response = await api.get(`${baseUrl}/companies/${localId}/tax/selects/states`, { params: { disableError: true }, headers: { "Authorization": `Bearer ${token.access_token}` } });
    return response.data;
  }

  const generateApiKey = async (localId: string): Promise<string> => {
    const response = await api.post(`${baseUrl}/companies/${localId}/shareform`, { params: { disableError: true }, headers: { "Authorization": `Bearer ${token.access_token}` } });
    return response.data.apiKey;
  }

  const createTax = async (localId: string, request: ICreateTaxRequest): Promise<void> => {
    await api.post(`${baseUrl}/companies/${localId}/tax`, request);
  }

  const testNfe = async (localId: string, taxId: string): Promise<void> => {
    await api.post(`${baseUrl}/companies/${localId}/tax/${taxId}/nfe/test`);
  }

  const testNfce = async (localId: string, taxId: string): Promise<void> => {
    await api.post(`${baseUrl}/companies/${localId}/tax/${taxId}/nfce/test`);
  }

  const deleteTax = async (localId: string, taxId: string): Promise<void> => {
    await api.delete(`${baseUrl}/companies/${localId}/tax/${taxId}`);
  }

  const getStatusDetails = async (companyId: string, invoiceId: string): Promise<string> => {
    const response = await api.get(`${baseUrl}/Companies/${companyId}/Invoices/${invoiceId}/Note`);
    return response.data;
  }

  return {
    createCompany,
    editCompany,
    getCompany,
    validateCertificate,
    getEnvironment,
    updateEnvironment,
    importInvoiceConfigFromCsv,
    getTax,
    getTaxById,
    getTaxProducts,
    getTaxFileUrl,
    getNcmList,
    getCfopList,
    getIcmsList,
    getIpiList,
    getCofinsList,
    getPisList,
    getIcmsSourcesList,
    generateApiKey,
    getBaseModeCalculationIcms,
    getBaseModeCalculationIcmsst,
    getStates,
    createTax,
    testNfe,
    testNfce,
    deleteTax,
    getStatusDetails,
  }
};

export default InvoiceApi;
