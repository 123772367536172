import { IGetTurnoverTableResponse } from "modules/generalDashboard/domain/dto/IGetTurnoverTableResponse";
import { IGeneralDashboardApiService } from "modules/generalDashboard/domain/interfaces/IGeneralDashboardApiService";
import { IFilterValue } from "modules/generalDashboard/presentation/interfaces/IFilter";


const GetTurnoverTableUseCase = async (service: IGeneralDashboardApiService, localId: string,filter?: IFilterValue, sortByBable?: boolean, pagination?: { page?: number, pageSize?: number }): Promise<IGetTurnoverTableResponse> => {
    const response = await service.getTurnoverTable(localId, sortByBable, pagination, filter);

    return response;
}

export default GetTurnoverTableUseCase;