import { useEffect, useMemo, useRef, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, makeStyles } from '@material-ui/core';
import { ExpandLess, ExpandMore, QrCodeOutlined } from '@mui/icons-material';
import { LinkOutlined } from '@material-ui/icons';
import QRCode from "qrcode.react";

import { UseGestaoVendas } from '../../hooks/UseGestaoVendas';
import { useUi } from 'contexts/userInterface/UserInterfaceContext';
import styles from './SaasCatalogCodes.module.scss';
import Button from 'components/ui/Button/Button';
import Sidesheet from 'components/sidesheet/Sidesheet';

const useStyles = makeStyles({
    root: {
        '&.MuiAccordion-root': {
            border: "none",
            borderRadius: "10px",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
            "&.Mui-expanded": {
                margin: 0,
            },
            "&::before": {
                display: "none",
            },
            '&.MuiAccordion-root:first-of-type': {
                borderRadius: "10px",
            },
            '&.MuiAccordion-root:last-of-type': {
                borderRadius: "10px",
            },
            '@media (max-width: 400px)': {
                boxShadow: "none",
                border: "1px solid #DFCDFF"
            }
        },
    },
});

const SaasCatalogCodes = () => {
    const accordionStyle = useStyles();
    const qrCodeRef = useRef<any>("");
    const { toast } = useUi();
    const { getConfigData, getCatalogList, dataConfig, catalogList } = UseGestaoVendas(true);

    const [openQrcodeSidesheet, setOpenQrcodeSidesheet] = useState<string | null>(null);
    const [sidesheetTitle, setSidesheetTitle] = useState<string | null>(null);
    const [expand, setExpand] = useState(false);

    const catalogUrl = useMemo(() => dataConfig?.catalogUrl ?? "", [dataConfig?.catalogUrl]);
    const registeredCatalogs = useMemo(() => catalogList ?? [], [catalogList]);

    useEffect(() => {
        getConfigData();
        getCatalogList();
    }, [getCatalogList, getConfigData]);

    const handleDownloadQrcode = () => {
        const canvas = document.querySelector('canvas');

        if (canvas) {
            const dataUrl = canvas.toDataURL('image/png');

            const link = document.createElement('a');
            link.href = dataUrl;
            link.download = 'qrcode.png';

            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
        }
    };

    return (
        <>
            <Accordion
                elevation={0}
                expanded={expand}
                className={`${accordionStyle.root} ${styles.rootMobile}`}>
                <AccordionSummary style={{ cursor: "default" }}>
                    <div id={styles.accordionSummary}>
                        <div className={styles.header}>
                            <div className={styles.start}>
                                <div className={styles.iconContainer}>
                                    <img src="/assets/icon/saas/icon-cardapio-digital.svg" alt="ícone cardápio digital" />
                                </div>
                                <span>Cardápios digitais</span>
                            </div>

                            <div className={styles.end}>
                                <button onClick={() => setExpand(!expand)}>
                                    {expand ? <ExpandLess /> : <ExpandMore />}
                                </button>
                            </div>
                        </div>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <div style={{ width: '100%' }}>
                        {registeredCatalogs.map((catalog) => {
                            const currentQrcodeLink = `https://mepay.meep.cloud/${catalogUrl}?listId=${catalog.id}`;
                            const currentCatalog = catalog.description;

                            return (
                                <div className={styles.digitalMenuItem} key={catalog.id}>
                                    <div className={styles.digitalMenuItemTitle}>{currentCatalog}</div>

                                    <div
                                        className={styles.digitalMenuItemCopy}
                                        onClick={() => {
                                            navigator.clipboard.writeText(currentQrcodeLink);
                                            toast("Link copiado com sucesso!", 'success');
                                        }}>
                                        Copiar link
                                        <LinkOutlined style={{ width: "18px" }} />
                                    </div>

                                    <Button
                                        variant="outlined"
                                        className={styles.digitalMenuItemQrCodeButton}
                                        endIcon={<QrCodeOutlined />}
                                        onClick={() => {
                                            setSidesheetTitle(currentCatalog);
                                            setOpenQrcodeSidesheet(currentQrcodeLink);
                                        }}>
                                        QR Code
                                    </Button>
                                </div>
                            )
                        })}
                    </div>
                </AccordionDetails>
            </Accordion>

            <Sidesheet
                open={openQrcodeSidesheet}
                onClose={() => setOpenQrcodeSidesheet(null)}
                title={<h2><b>QR Code</b></h2>}
                content={
                    <div className={styles.sidesheetContent}>
                        <div>
                            <p>
                                Esse é o QR Code do seu cardápio. <b>Disponibilize-os para os seus clientes</b> e torne a experiência deles ainda melhor!
                            </p>
                            <div>{sidesheetTitle}</div>
                        </div>
                        <div>
                            <QRCode
                                ref={qrCodeRef}
                                size={200}
                                value={openQrcodeSidesheet ?? ""}
                            />
                            <span onClick={handleDownloadQrcode}>Salvar imagem</span>
                        </div>
                    </div>
                }
                currentStep={1}
                totalSteps={1}
                continueButton="Fechar"
                handleContinueButton={() => setOpenQrcodeSidesheet(null)}
            />
        </>
    )
}

export default SaasCatalogCodes;