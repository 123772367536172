import { ChangeEvent, Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import styles from './InvoiceRegister.module.scss';
import { FormHelperText, Icon, TextField } from '@material-ui/core';
import InputMasked from 'components/inputMasked/InputMasked'
import { MenuItem, Select } from '@mui/material';
import { ICompanyForm, ICompanyFormAddress, ICompanyFormCertificate } from '../interfaces/ICompanyForm';
import InvoiceApi from 'services/api/invoice/InvoiceApi';
import { ValidateCertificateUseCase } from 'modules/invoice/application/useCases/ValidateCertificateUseCase';
import { ValidateCertificateUseCase as PublicValidateCertificateUseCase } from '../../../../public/modules/invoice/application/useCases/ValidateCertificateUseCase';
import { useUi } from 'contexts/userInterface/UserInterfaceContext';
import { useLocal } from 'modules/local/presentation/context/LocalContext';
import { GenerateApiKeyUseCase } from 'modules/invoice/application/useCases/GenerateApiKeyUseCase';
import { useQuery } from 'hooks/UseQuery';
import InvoicePublicApi from 'services/api/invoice/InvoicePublicApi';
import { useParams } from 'react-router-dom';
import { Popover } from './Popover';

interface InvoiceRegisterProps {
    onSubmit: () => void;
    onCancel: () => void;
    setForm: Dispatch<SetStateAction<ICompanyForm>>;
    form: ICompanyForm;
    modal?: boolean;
}

type State = { id: string, acronym: string };

interface Errors {
    fullName?: string;
    displayName?: string;
    document?: string;
    stateRegistration?: string;
    municipalRegistration?: string;
    state?: string;
    city?: string;
    postalCode?: string;
    street?: string;
    number?: string;
    certificate?: string;
    certificatePassword?: string;
}

const service = InvoiceApi();
const publicService = InvoicePublicApi();

export const InvoiceRegister = ({ onSubmit, onCancel, form, setForm, modal = false }: InvoiceRegisterProps) => {
    const [isNationalSimple, setIsNationalSimple] = useState(true);
    const [errors, setErrors] = useState({} as Errors);
    const [certificate, setCertificate] = useState<File>();
    const [UFlist, setUFlist] = useState<State[]>([]);
    const [cities, setCities] = useState<string[]>([]);
    const [loadingCities, setLoadingCities] = useState(true);
    const [loadingCertificate, setLoadingCertificate] = useState(false);
    const [wasValidated, setWasValidated] = useState(false);
    const fileRef = useRef<HTMLInputElement>(null);
    const [loadingApiKey, setLoadingApiKey] = useState(false);
    const [hidePassword, setHidePassword] = useState(true);

    const { toast } = useUi();
    const { localId } = useParams<{ localId: string }>();
    const { currentLocal } = useLocal();
    const query = useQuery();

    const validate = () => {
        let isValid = true;
        setErrors({});

        if (!form.address?.state) {
            setErrors(prev => ({ ...prev, state: 'UF é obrigatório' }));
            isValid = false;
        }

        if (!form.address?.city) {
            setErrors(prev => ({ ...prev, city: 'Cidade é obrigatório' }));
            isValid = false;
        }

        if (!form.address?.postalCode) {
            setErrors(prev => ({ ...prev, postalCode: 'CEP é obrigatório' }));
            isValid = false;
        }

        if (!form.address?.street) {
            setErrors(prev => ({ ...prev, street: 'Rua é obrigatório' }));
            isValid = false;
        }

        if (!form.address?.number) {
            setErrors(prev => ({ ...prev, number: 'Número é obrigatório' }));
            isValid = false;
        }

        if (!form.stateRegistration) {
            setErrors(prev => ({ ...prev, stateRegistration: 'Inscrição estadual é obrigatório' }));
            isValid = false;
        }

        if (!form.municipalRegistration) {
            setErrors(prev => ({ ...prev, municipalRegistration: 'Inscrição municipal é obrigatório' }));
            isValid = false;
        }

        if (!form.hasCertificate) {
            if (!form.certificate?.base64) {
                setErrors(prev => ({ ...prev, certificate: 'Certificado é obrigatório' }));
                isValid = false;
            }

            if (!form.certificate?.password) {
                setErrors(prev => ({ ...prev, certificatePassword: 'Senha do certificado é obrigatório' }));
                isValid = false;
            } else if (!wasValidated) {
                setErrors(prev => ({ ...prev, certificatePassword: 'É necessário validar o certificado antes de prosseguir' }));
                isValid = false;
            }
        }

        return isValid;
    }

    const handleSubmit = () => {
        if (!validate()) return;

        onSubmit();
    }

    function fileToBase64(file: File) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    const handleSelectFile = async (ev: ChangeEvent<HTMLInputElement>) => {
        const files = ev.target.files;
        if (!files) return;

        setCertificate(files[0]);
        const base64 = await fileToBase64(files[0]);
        setForm(prev => ({ ...prev, hasCertificate: false, certificate: ({ ...prev.certificate || {} as ICompanyFormCertificate, base64: String(base64).replace('data:application/x-pkcs12;base64,', '') }) }));
        setWasValidated(false);
        setErrors(prev => ({ ...prev, certificate: undefined }));
    }

    const handleValidateCertificate = async () => {
        try {
            if (form.certificate?.password && form.certificate?.base64) {
                setErrors(prev => ({ ...prev, certificate: undefined, certificatePassword: undefined }));
                setLoadingCertificate(true);
                const apiKey = query.get('apiKey');
                let response;
                if (!apiKey) {
                    response = await ValidateCertificateUseCase(service, currentLocal!.id, form.certificate?.base64, form.certificate?.password);
                } else {
                    response = await PublicValidateCertificateUseCase(publicService, localId, form.certificate?.base64, form.certificate?.password, apiKey);
                }
                if (!response) {
                    toast('Certificado e/ou senha inválidos', 'error');
                } else {
                    toast('Certificado validado com sucesso', 'success');
                    setWasValidated(true);
                }
            }
        } finally {
            setLoadingCertificate(false);
        }
    }

    function formatCNPJ(cnpj: string) {
        if (!cnpj) return cnpj;

        cnpj = cnpj.replace(/\D/g, '');

        return cnpj.replace(/(\d{2})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1/$2')
            .replace(/(\d{4})(\d)/, '$1-$2');
    }

    useEffect(() => {
        fetch('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
            .then(response => response.json())
            .then((data) => {
                setUFlist(data.map((x: any) => ({ id: x.id, acronym: x.sigla })).sort((a: State, b: State) => a.acronym < b.acronym ? -1 : 1))
            });
    }, []);

    useEffect(() => {
        if (form.address?.state) {
            setLoadingCities(true);
            fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${form.address.state}/municipios`)
                .then(response => response.json())
                .then(data => {
                    setCities(data.map((x: any) => x.nome).sort((a: string, b: string) => a < b ? -1 : 1));
                    setLoadingCities(false);
                });
        }
    }, [form.address?.state]);

    const generateLink = async () => {
        try {
            setLoadingApiKey(true);
            const response = await GenerateApiKeyUseCase(service, currentLocal!.id);
            navigator.clipboard.writeText(window.location.origin + '/public/invoice/' + currentLocal!.id + '?apiKey=' + response);
            toast('URL copiada para a área de transferência!', 'success');
        } catch {
            toast('Houve um erro ao gerar o link temporário.', 'error');
        } finally {
            setLoadingApiKey(false);
        }
    }

    return (
        <div className={styles.container}>
            {
                !modal &&
                <>
                    <h4><b>1. </b>Complete suas informações</h4>
                    <p>Para emitir Notas Fiscais, preencha os dados abaixo. Para enviar formulário para a contabilidade, você poderá gerar um link temporário.</p>
                    <button className={styles.linkButton} onClick={generateLink} disabled={loadingApiKey}>
                        {
                            loadingApiKey
                                ? 'Gerando...'
                                : <>
                                    Gerar link temporário
                                    <Icon className={styles.icon}>content_copy</Icon>
                                </>
                        }
                    </button>
                </>
            }

            <span className={styles.shortDescription}>Selecione seu regime fiscal</span>
            <span className={styles.description}>Selecione o regime fiscal aplicável à sua empresa: Simples Nacional, Lucro Presumido ou Lucro Real.</span>

            <div className={styles.radioButtonsContainer}>
                <button className={isNationalSimple ? styles.selected : ''} onClick={() => setIsNationalSimple(true)}>
                    <Icon className={styles.icon}>{isNationalSimple ? 'radio_button_checked' : 'radio_button_unchecked'}</Icon>
                    <p>Simples nacional</p>
                </button>
                <button className={!isNationalSimple ? styles.selected : ''} onClick={() => setIsNationalSimple(false)}>
                    <Icon className={styles.icon}>{!isNationalSimple ? 'radio_button_checked' : 'radio_button_unchecked'}</Icon>
                    <p>Outro regime</p>
                </button>
            </div>

            <div className={styles.row}>
                <div className={styles.column}>
                    <div className={styles.labelContainer}>
                        <label className={styles.required}>Razão Social</label>
                        <Popover title='Razão Social' description='Nome legal registrado para fins comerciais e fiscais.' />
                    </div>
                    <TextField
                        variant="outlined"
                        fullWidth
                        size="small"
                        value={form.fullName}
                        disabled
                        helperText={errors.fullName}
                        error={!!errors.fullName}
                    />
                </div>
            </div>

            <div className={styles.row}>
                <div className={styles.column}>
                    <div className={styles.labelContainer}>
                        <label className={styles.required}>Nome fantasia</label>
                        <Popover title='Nome Fantasia' description='Nome comercial utilizado para identificação e marketing.' />
                    </div>
                    <TextField
                        variant="outlined"
                        fullWidth
                        size="small"
                        value={form.displayName}
                        disabled
                        helperText={errors.displayName}
                        error={!!errors.displayName}
                    />
                </div>
            </div>

            <div className={styles.row}>
                <div className={styles.column}>
                    <div className={styles.labelContainer}>
                        <label className={styles.required}>CNPJ</label>
                        <Popover title='CPNJ' description='Número de identificação fiscal.' />
                    </div>
                    <TextField
                        variant="outlined"
                        fullWidth
                        size="small"
                        value={formatCNPJ(form.document)}
                        disabled
                        helperText={errors.document}
                        error={!!errors.document}
                    />
                </div>

                <div className={styles.column}>
                    <div className={styles.labelContainer}>
                        <label className={styles.required}>Inscrição estadual</label>
                        <Popover title='Inscrição Estadual' description='Registro fiscal estadual. Você pode verificar esse número nos documentos fiscais ou no site da Secretaria da Fazenda do seu estado.' />
                    </div>
                    <TextField
                        variant="outlined"
                        fullWidth
                        size="small"
                        value={form.stateRegistration}
                        onChange={(event) => setForm(prev => ({ ...prev, stateRegistration: event.target.value }))}
                        helperText={errors.stateRegistration}
                        error={!!errors.stateRegistration}
                        type="tel"
                    />
                </div>

                <div className={styles.column}>
                    <div className={styles.labelContainer}>
                        <label className={styles.required}>Inscrição municipal</label>
                        <Popover title='Inscrição Municipal' description='Registro fiscal municipal. Você pode verificar esse número nos documentos fiscais ou no site da prefeitura da sua cidade.' />
                    </div>
                    <TextField
                        variant="outlined"
                        fullWidth
                        size="small"
                        value={form.municipalRegistration}
                        onChange={(event) => setForm(prev => ({ ...prev, municipalRegistration: event.target.value }))}
                        helperText={errors.municipalRegistration}
                        error={!!errors.municipalRegistration}
                        type="tel"
                    />
                </div>
            </div>

            <div className={styles.row}>
                <div className={styles.column}>
                    <label className={styles.required}>UF</label>
                    <Select
                        value={form.address?.state}
                        style={{ width: "100%" }}
                        size="small"
                        placeholder="UF"
                        onChange={(ev) => setForm(prev => ({ ...prev, address: { ...prev.address || {} as ICompanyFormAddress, state: ev.target.value } }))}
                        error={!!errors.state}
                    >
                        {
                            UFlist.map((x) => (
                                <MenuItem value={x.acronym} key={x.id}>
                                    {x.acronym}
                                </MenuItem>
                            ))
                        }
                    </Select>
                    {
                        !!errors.state &&
                        <FormHelperText className={styles.error}>{errors.state}</FormHelperText>
                    }
                </div>

                <div className={styles.column} style={{ flex: 3 }}>
                    <label className={styles.required}>Cidade</label>
                    <Select
                        value={form.address?.city}
                        style={{ width: "100%" }}
                        size="small"
                        placeholder="Cidade"
                        onChange={(ev) => setForm(prev => ({ ...prev, address: { ...prev.address || {} as ICompanyFormAddress, city: ev.target.value } }))}
                        error={!!errors.city}
                        disabled={loadingCities}
                    >
                        {
                            cities.map(x => (
                                <MenuItem value={x} key={x}>
                                    {x}
                                </MenuItem>
                            ))
                        }
                    </Select>
                    {
                        !!errors.city &&
                        <FormHelperText className={styles.error}>{errors.city}</FormHelperText>
                    }
                </div>

                <div className={styles.column}>
                    <label className={styles.required}>CEP</label>
                    <InputMasked
                        name={"postalCode"}
                        variant="outlined"
                        fullWidth
                        mask="99999-999"
                        value={form.address?.postalCode}
                        onChange={(ev: any) => setForm(prev => ({ ...prev, address: { ...prev.address || {} as ICompanyFormAddress, postalCode: ev.target.value } }))}
                        helperText={errors.postalCode}
                        error={!!errors.postalCode}
                        type="tel"
                    />
                </div>
            </div>

            <div className={styles.row}>
                <div className={styles.column} style={{ flex: 2 }}>
                    <label className={styles.required}>Logradouro</label>
                    <TextField
                        variant="outlined"
                        fullWidth
                        size="small"
                        value={form.address?.street}
                        onChange={(event) => setForm(prev => ({ ...prev, address: { ...prev.address || {} as ICompanyFormAddress, street: event.target.value } }))}
                        helperText={errors.street}
                        error={!!errors.street}
                    />
                </div>

                <div className={styles.column}>
                    <label className={styles.required}>Número</label>
                    <TextField
                        variant="outlined"
                        fullWidth
                        size="small"
                        value={form.address?.number}
                        onChange={(event) => setForm(prev => ({ ...prev, address: { ...prev.address || {} as ICompanyFormAddress, number: event.target.value } }))}
                        helperText={errors.number}
                        error={!!errors.number}
                    />
                </div>
            </div>

            <div className={styles.row}>
                <div className={styles.column} style={{ maxWidth: 400 }}>
                    <div className={styles.labelContainer}>
                        <label>Certificado digital</label>
                        <Popover title='Certificado Digital' description='Se não possui um Certificado Digital, consulte um fornecedor autorizado para adquiri-lo. Ele é essencial para transações seguras e conformidade fiscal.' />
                    </div>

                    <input type="file" style={{ display: 'none' }} id="file" onChange={handleSelectFile} ref={fileRef} />
                    <label htmlFor='file' className={styles.uploadButton}>
                        <span>
                            {
                                !form.hasCertificate
                                    ? 'Fazer upload'
                                    : 'Alterar certificado'
                            }
                        </span>
                        <Icon className={styles.icon}>upload</Icon>
                    </label>
                    {
                        !!errors.certificate &&
                        <FormHelperText className={styles.error}>{errors.certificate}</FormHelperText>
                    }
                </div>

                {
                    !!certificate &&
                    <div className={styles.column} style={{ flex: 0, width: 'fit-content' }}>
                        <label>1 arquivo enviado:</label>
                        <div className={styles.selectedFile}>
                            <span>{certificate.name}</span>
                            <button onClick={() => {
                                setCertificate(undefined);
                                if (fileRef.current) {
                                    fileRef.current.value = '';
                                }
                                setForm(prev => ({ ...prev, certificate: ({ ...prev.certificate || {} as ICompanyFormCertificate, base64: '' }) }));
                                setWasValidated(false);
                            }}>
                                <Icon>close</Icon>
                            </button>
                        </div>
                    </div>
                }
            </div>

            {
                !form.hasCertificate &&
                <div className={styles.row}>
                    <div className={styles.column} style={{ maxWidth: 400 }}>
                        <label className={styles.required}>Senha do certificado</label>
                        <div className={`${styles.passwordInputContainer} ${wasValidated ? styles.validated : ''}`}>
                            <input
                                disabled={wasValidated}
                                type={hidePassword ? 'password' : 'text'}
                                value={form.certificate?.password}
                                onChange={(event) => setForm(prev => ({ ...prev, certificate: ({ ...prev.certificate || {} as ICompanyFormCertificate, password: event.target.value }) }))}
                            />

                            {
                                wasValidated && (
                                    <>
                                        <span className={styles.validatedText}>Validado</span>
                                        <Icon className={styles.validatedIcon}>check_circle_outline</Icon>
                                    </>
                                )
                            }

                            <button onClick={() => setHidePassword(!hidePassword)}>
                                <Icon>{!hidePassword ? 'visibility_off' : 'visibility'}</Icon>
                            </button>
                        </div>
                        {
                            !!errors.certificatePassword &&
                            <FormHelperText className={styles.error}>{errors.certificatePassword}</FormHelperText>
                        }
                        {/* <TextField
                            variant="outlined"
                            fullWidth
                            size="small"
                            value={form.certificate?.password}
                            onChange={(event) => setForm(prev => ({ ...prev, certificate: ({ ...prev.certificate || {} as ICompanyFormCertificate, password: event.target.value }) }))}
                            helperText={errors.certificatePassword}
                            error={!!errors.certificatePassword}
                        /> */}
                    </div>
                    <div className={styles.column} style={{ maxWidth: 300 }}>
                        <label></label>
                        <button onClick={handleValidateCertificate} disabled={loadingCertificate || !form.certificate?.base64 || !form.certificate.password || wasValidated} className={styles.validateButton}>
                            {
                                loadingCertificate ? 'Validando...' : 'Validar'
                            }
                        </button>
                    </div>
                </div>
            }

            <div className={styles.buttonsContainer}>
                <button className={styles.cancelButton} onClick={onCancel}>Cancelar</button>
                <button className={styles.submitButton} onClick={handleSubmit}>Próximo</button>
            </div>
        </div>
    )
}