import React, { FC, useCallback } from 'react'
import styles from './ui.module.scss';
import Button, { IButtonProps } from 'components/ui/Button/Button';
export interface IButtonsFormProps {
    //propertys
    cancelLabel?: string;
    submitLabel?: string;
    onCancel?: () => void,
    onSubmit?: () => void
    disabled?: boolean,
    stick?: boolean,
    color?: IButtonProps["color"],
    width?: string;
    marginTop?: string;
    padding?: string;
}
const ButtonsForm: FC<IButtonsFormProps> = ({ onCancel, disabled, onSubmit, cancelLabel = "Cancelar", submitLabel = "Continuar", stick, color, width, marginTop, padding }) => {


    const submitHandle = useCallback(() => {
        onSubmit?.()
    }, [onSubmit])
    return (
        <div
            className={styles.buttonContainer}
            style={stick ? { position: 'sticky', bottom: 0, backgroundColor: '#fff', padding: padding ?? "16px 0", justifySelf: "flex-end", marginTop: marginTop ?? "16px" } : undefined}
        >
            {!!onCancel && (
                <Button
                    className={styles.cancelButton}
                    style={{ width: width ?? "100%" }}
                    onClick={onCancel}
                    disabled={disabled}
                    variant='outlined'
                    color={color ?? "secondary"}
                    type={"button"}
                >
                    {cancelLabel}
                </Button>
            )}
            <Button
                className={styles.submitButton}
                style={{ width: width ?? "100%" }}
                onClick={submitHandle}
                disabled={disabled}
                variant='contained'
                color={color ?? "secondary"}
                type={!onSubmit ? "submit" : "button"}
            >
                {submitLabel}
            </Button>
        </div>
    )
}
export default ButtonsForm;