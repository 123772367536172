import { Api } from "services/api/Api";
import { erpUrl } from "Enviroment";
import { IGetDeviceProfilesErpResponse, IPostDeviceProfilesErpRequest } from "modules/meepErp/application/dtos/deviceProfiles/IDeviceProfiles";

const BASE_URL = erpUrl;

const DeviceProfilesErpService = () => {
  const api = Api();

  const getDeviceProfilesErp = async (
    ownerId: string,
    deviceProfileId: string
  ): Promise<IGetDeviceProfilesErpResponse> => {
    const response = await api.get<IGetDeviceProfilesErpResponse>(
      `${BASE_URL}/api/${ownerId}/DeviceProfiles/${deviceProfileId}`,
    );
    return response.data;
  };

  const createDeviceProfilesErp = async (
    ownerId: string,
    deviceProfileId: string,
    data: IPostDeviceProfilesErpRequest
  ): Promise<void> => {
    const response = await api.post<void>(
      `${BASE_URL}/api/${ownerId}/DeviceProfiles/${deviceProfileId}`,
      data
    );
    return response.data;
  };

 

  return {
    getDeviceProfilesErp,
    createDeviceProfilesErp,
  };
};
export default DeviceProfilesErpService;