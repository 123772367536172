import { useLocal } from "modules/local/presentation/context/LocalContext";
import { Dispatch, MutableRefObject, SetStateAction, useCallback, useState } from "react";
import { IFilterValue } from "../interfaces/IFilter";
import { IRankingProducts } from "../interfaces/IRankingProducts";
import GeneralDashboardApi from "services/api/generalDashboard/GeneralDashboardApi";
import GetRankingProductsUseCase from "modules/generalDashboard/application/useCases/GetRankingProductsUseCase";
import { ICashierEvent } from "../interfaces/ICashierEvent";

export interface IParamsUseRankingProducts {
  localId?: string,
  sessionId?: string,
}

const UseRankingProducts = (
    filter: IFilterValue | undefined,
    isLoadingCashier: boolean,
    lastCashierOpenData: MutableRefObject<ICashierEvent | undefined>,
    isLoadingFinancialInfo: boolean | undefined,
    setIsProductLoaded: Dispatch<SetStateAction<boolean>>,
  ) => {

  const [isLoading, setIsLoading] = useState<boolean>();
  const [rankingProducts, setRankingProducts] = useState<IRankingProducts>()
  const { currentLocal } = useLocal();

  const getRankingProducts = useCallback(
    async (params?: IParamsUseRankingProducts) => {
      try {
        const serviceGeneral = GeneralDashboardApi();
        setIsProductLoaded(false);
        setIsLoading(true);
        if (!isLoadingCashier && !isLoadingFinancialInfo && currentLocal) {
          const cashierIds = filter?.cashierIds && filter.cashierIds.length > 0
            ? filter.cashierIds
            : lastCashierOpenData.current?.id
            ? [lastCashierOpenData.current.id]
            : undefined;

          if (!cashierIds) return;

          const response = await GetRankingProductsUseCase(serviceGeneral,
            currentLocal.id,
            {
              ...filter,
              cashierIds: cashierIds,
            });

          setRankingProducts(response);
          return response;
        }
      } finally {
        setIsLoading(false);
        setIsProductLoaded(true);
      }
    },
    [currentLocal, filter, isLoadingCashier, isLoadingFinancialInfo, lastCashierOpenData, setIsProductLoaded]
  );

  return {
    rankingProducts,
    isLoading,
    getRankingProducts,
    setRankingProducts,
  };
};

export default UseRankingProducts;
