import { IGetPaymentLinkByIdResponse } from "./IGetPaymentLinkByIdResponse";

export interface IPaymentType {
  paymentTypeId: PaymentTypeEnum;
}

export enum PaymentTypeEnum {
  CREDIT = 1,
  TICKET = 9,
  PIX = 18
}

export interface IPostPaymentLinkRequest {
  productId: string;
  localId: string;
  name: string;
  image?: string;
  paymentTypes: IPaymentType[];
  description?: string;
  successMessage?: string;
  price: number;
  expirationDate: string;
  maximumInstallments?: number;
  allowPaymentSlip?: boolean;
  interestPerInstallment?: number;
}

export interface IPostPaymentLinkResponse extends IGetPaymentLinkByIdResponse {}
