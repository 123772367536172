import { IPaymentType, IPostPaymentLinkRequest } from "modules/paymentLink/domain/dto/IPostPaymentLinkRequest";
import { IPaymentLinkService } from "modules/paymentLink/domain/interfaces/IPaymentLinkService";
import { PaymentTypeEnum } from "modules/paymentLink/presentation/interfaces/INewPaymentLink";
import { IPaymentLinkInfoForm } from "modules/paymentLink/presentation/interfaces/IPaymentLinkForms";
import { IPaymentLinkValuesForm } from "modules/paymentLink/presentation/interfaces/IPaymentValuesFroms";

export const AddPaymentLinkUseCase = async (
  service: IPaymentLinkService,
  localId: any,
  info: IPaymentLinkInfoForm,
  values: IPaymentLinkValuesForm
) => {
  const result = await service.postPaymentLink(
    parsePostPaymentLinkPayload(localId, info, values)
  );
  return result;
};

const parsePostPaymentLinkPayload = (
  localId: string,
  info: IPaymentLinkInfoForm,
  values: IPaymentLinkValuesForm
): IPostPaymentLinkRequest => {
  let paymentTypes: IPaymentType[] = [];

  if (values.credit) {
    paymentTypes.push({paymentTypeId: PaymentTypeEnum.CREDIT})
  }

  if (values.ticket) {
    paymentTypes.push({paymentTypeId: PaymentTypeEnum.TICKET})
  }

  if (values.pix) {
    paymentTypes.push({paymentTypeId: PaymentTypeEnum.PIX})
  }
  return {
    localId,
    name: info.name,
    expirationDate: info.expiration,
    description: info.description,
    successMessage: info.successMessage,
    image: info.img,
    productId: values.productId,
    price: Number(values.price.replace(/,/g, '.')),
    maximumInstallments: values.credit ? values.installments : undefined,
    interestPerInstallment: values.credit ? values.tax : undefined,
    paymentTypes,
  };
};
