import { useQuery } from "hooks/UseQuery";
import { useHistory, useLocation } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { ValidateSaasUseCase } from "modules/saas/application/useCases/ValidateSaasUseCase";
import { SaasApi } from "services/api/saas/SaasApi";
import { IRegisterSaas } from "../../interfaces/register/IRegisterSaas";
import { RegisterSaasUseCase } from "modules/saas/application/useCases/RegisterSaasUseCase";
import { UseBackPage } from "../../hooks/UseBackPage";
import UseProspect from "../../hooks/UseProspect";
import { ProspectType } from "modules/saas/domain/interfaces/ProspectTypeEnum";
import ResendCodeUseCase from "modules/saas/application/useCases/ResendCodeUseCase";

const service = SaasApi();

export const UseCodePage = () => {
    const [code, setCode] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [form, setForm] = useState<IRegisterSaas>({} as IRegisterSaas);
    const [progressBarColor, setProgressBarColor] = useState("rgba(224, 224, 224, 0.50)");
    const [backgroundColorTop, setBackgroundColorTop] = useState("#FFF");
    const [backgroundColorBottom, setBackgroundColorBottom] = useState("#FFF");

    const history = useHistory();
    const query = useQuery();
    const { toast } = useUi();
    const { onBack } = UseBackPage();
    const { setEmail, setProspectId, setKey, setType } = UseProspect();
    const location = useLocation<{ type: string }>();

    useEffect(() => {
        const _email = query.get("email");
        const _type = Number(location.state?.type ?? 0);

        if (_type === ProspectType.Cielo || _type === ProspectType.CieloEvent || _type === ProspectType.CieloEstablishment) {
            setProgressBarColor("#00739E");
            setBackgroundColorTop("#00AEEF");
            setBackgroundColorBottom("#00AEEF");
        } else {
            setProgressBarColor("#865FDD");
            setBackgroundColorTop("#6B41C9");
            setBackgroundColorBottom("#865FDD");
        }

        setEmail(_email!);
        setType(_type);
        setForm(prev => ({
            ...prev,
            email: query.get("email") ?? '',
            type: !!Number(_type) ? Number(_type) : undefined,
        }));
    }, [location.state?.type, query, setEmail, setType]);

    const handleNewCode = useCallback(async () => {
        try {
            setIsLoading(true);
            await RegisterSaasUseCase(service, form);
        } finally {
            setIsLoading(false);
        }
    }, [form]);

    const handleSubmit = async () => {
        try {
            setIsLoading(true);

            const email = query.get('email');
            const type = query.get('type') ?? location.state?.type;
            const response = await ValidateSaasUseCase(service, { email: email!, code });

            if (response.key && response.prospectId) {
                setEmail(email!);
                setProspectId(response.prospectId);
                setKey(response.key);

                history.push(`/public/saas/profile?prospectId=${response.prospectId}&key=${response.key}`, { type: type });
            } else {
                toast('A chave fornecida está inválida ou expirou.', 'error');
            }
        } catch {
            toast('Ocorreu um erro ao validar o código. Tente novamente.', 'error');
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        const email = query.get('email');
        if (!email) onBack("register");
    }, [onBack, query]);


    const resendCode = useCallback(async () => {
        if (isLoading) return;
        const email = query.get('email');
        if(email === null) return;
        try {
            setIsLoading(true);
            await ResendCodeUseCase(service, email);
        } finally {
            setIsLoading(false);
        }
    }, [isLoading, query]);


    return {
        code,
        isLoading,
        progressBarColor,
        backgroundColorTop,
        backgroundColorBottom,
        setCode,
        handleSubmit,
        handleNewCode,
        resendCode,
    }
}