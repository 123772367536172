import { useLocal } from "modules/local/presentation/context/LocalContext";
import { MutableRefObject, useCallback, useState } from "react";
import { IFilterValue } from "../interfaces/IFilter";
import { IRankingCustomers } from "../interfaces/IRankingCustomers";
import GeneralDashboardApi from "services/api/generalDashboard/GeneralDashboardApi";
import GetRankingCustomersUseCase from "modules/generalDashboard/application/useCases/GetRankingCustomersUseCase";
import { ICashierEvent } from "../interfaces/ICashierEvent";

export interface IParamsUseRankingCustomers {
  localId?: string,
  sessionId?: string,
}

const UseRankingCustomers = (filter: IFilterValue | undefined, isLoadingCashier: boolean, lastCashierOpenData: MutableRefObject<ICashierEvent | undefined>, isLoadingFinancialInfo: boolean | undefined) => {

  const [isLoading, setIsLoading] = useState<boolean>();
  const [rankingCustomers, setRankingCustomers] = useState<IRankingCustomers>()
  const { currentLocal } = useLocal();

  const getRankingCustomers = useCallback(
    async (params?: IParamsUseRankingCustomers) => {
      try {
        const serviceGeneral = GeneralDashboardApi();
        setIsLoading(true);
        if (!isLoadingCashier && !isLoadingFinancialInfo && currentLocal) {
          const cashierIds = filter?.cashierIds && filter.cashierIds.length > 0
            ? filter.cashierIds
            : lastCashierOpenData.current?.id
            ? [lastCashierOpenData.current.id]
            : undefined;

          if (!cashierIds) return;
          
          const response = await GetRankingCustomersUseCase(serviceGeneral,
            currentLocal.id,
            {
              ...filter,
              cashierIds: cashierIds,
            });

          setRankingCustomers(response);
          return response;
        }
      } finally {
        setIsLoading(false);
      }
    },
    [currentLocal, filter, isLoadingCashier, isLoadingFinancialInfo, lastCashierOpenData]
  );

  return {
    rankingCustomers,
    isLoading,
    getRankingCustomers,
    setRankingCustomers,
  };
};

export default UseRankingCustomers;
