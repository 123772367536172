import React from "react";
import styles from "./AverageTicket.module.scss";
import { CircularProgress } from "@material-ui/core";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { IAverageTickets } from "../../interfaces/IAverageTickets";
import { moneyMaskNumber } from "services/utils/Money";

interface ICashlessProps {
  averageTicketData: IAverageTickets | undefined;
  isLoading: boolean | undefined;
}

const AverageTicket: React.FC<ICashlessProps> = ({
  averageTicketData,
  isLoading,
}) => {
  const { isMobile } = useUi();

  const hasLeftContainerData =
    (averageTicketData?.ticketPayment ?? 0) > 0 ||
    (averageTicketData?.ticketIdentifiedClient ?? 0) > 0;

  const hasMidContainerData =
    (averageTicketData?.cashlessConsumption ?? 0) > 0 ||
    (averageTicketData?.cahlessRecharge ?? 0) > 0;

  const hasRightContainerData =
    (averageTicketData?.posPaidTable ?? 0) > 0 ||
    (averageTicketData?.posPaidIndividual ?? 0) > 0 ||
    (averageTicketData?.posPaidTicketZero ?? 0) > 0;

  const containers = [
    hasLeftContainerData,
    hasMidContainerData,
    hasRightContainerData,
  ].filter(Boolean);

  const containerCount = containers.length;

  return (
    <div id={styles.AverageTicket}>
      <div className={styles.topTitle}>
        <p>Ticket médio</p>
      </div>
      <div className={!isMobile ? styles.container : styles.containerMobile}>
        {hasLeftContainerData && (
          <>
            <div className={styles.containerLeft}>
              <div className={styles.top}>
                <p>Ticket médio de ficha • Pagamento</p>
                {isLoading ? (
                  <div className={styles.loading}>
                    <CircularProgress size={16} color="inherit" />{" "}
                  </div>
                ) : (
                  <span>{moneyMaskNumber(averageTicketData?.ticketPayment)}</span>
                )}
              </div>
              <div className={styles.bottom}>
                <p>Ticket médio de ficha • Cliente identificado</p>
                {isLoading ? (
                  <div className={styles.loading}>
                    <CircularProgress size={16} color="inherit" />{" "}
                  </div>
                ) : (
                  <span>{moneyMaskNumber(averageTicketData?.ticketIdentifiedClient)}</span>
                )}
              </div>
            </div>
            {containerCount > 1 && !isMobile && <div className={styles.separator}></div>}
          </>
        )}

        {hasMidContainerData && (
          <>
            <div className={styles.containerMid}>
              <div>
                <p>Ticket médio cashless • Recarga</p>
                {isLoading ? (
                  <div className={styles.loading}>
                    <CircularProgress size={16} color="inherit" />{" "}
                  </div>
                ) : (
                  <span>{moneyMaskNumber(averageTicketData?.cahlessRecharge)}</span>
                )}
              </div>
              <div>
                <p>Ticket médio cashless • Consumo</p>
                {isLoading ? (
                  <div className={styles.loading}>
                    <CircularProgress size={16} color="inherit" />{" "}
                  </div>
                ) : (
                  <span>{moneyMaskNumber(averageTicketData?.cashlessConsumption)}</span>
                )}
              </div>
            </div>
            {containerCount > 2 && !isMobile && <div className={styles.separator}></div>}
          </>
        )}

        {hasRightContainerData && (
          <div className={styles.containerRight}>
            <div>
              <p>Ticket médio pós-pago • Conta</p>
              {isLoading ? (
                <div className={styles.loading}>
                  <CircularProgress size={16} color="inherit" />{" "}
                </div>
              ) : (
                <span>{moneyMaskNumber(averageTicketData?.posPaidTable)}</span>
              )}
            </div>
            <div>
              <p>Ticket médio pós-pago • Pessoa</p>
              {isLoading ? (
                <div className={styles.loading}>
                  <CircularProgress size={16} color="inherit" />{" "}
                </div>
              ) : (
                <span>{moneyMaskNumber(averageTicketData?.posPaidIndividual)}</span>
              )}
            </div>
            <div>
              <p className={styles.totalText}>Ticket zero • Pós-pago</p>
              {isLoading ? (
                <div className={styles.loading}>
                  <CircularProgress size={16} color="inherit" />{" "}
                </div>
              ) : (
                <span className={styles.totalValue}>{averageTicketData?.posPaidTicketZero}</span>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AverageTicket;
