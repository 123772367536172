import { useLocal } from 'modules/local/presentation/context/LocalContext';
import { useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import { IDeviceEnviromentValue } from '../../components/deviceProfile/profileForms/deviceEnvironmentForm/Interfaces/IEnvironmentValue';
import { IDevicePaymentValue } from '../../components/deviceProfile/profileForms/devicePaymentForm/interfaces/IDevicePaymentValue';
import { IDeviceOperationItemValue } from '../../components/deviceProfile/profileForms/deviceSystemForm/interfaces/IDeviceSystemValue';
import { IOperatorItemValue } from '../../components/deviceProfile/profileForms/operatorsForm/interfaces/IOperatorsValues';
import { IDeviceProfileValue } from '../../components/deviceProfile/profileForms/profileForm/IDeviceProfileValue';
import { FormDeviceProfileStep } from "../../../domain/interface/FormDeviceProfileSteps";
import UseDevicePayment from '../../hooks/deviceProfile/UseDevicePayment';
import UseDeviceProfile from '../../hooks/deviceProfile/UseDeviceProfile';
import UseDeviceOperation from '../../hooks/deviceProfile/UseDeviceOperation';
import UseDeviceEnvironment from '../../hooks/deviceProfile/UseDeviceEnvironments';
import UseDeviceCatalog from '../../hooks/deviceProfile/UseDeviceCatalog';
import { IDeviceCatalogValue } from '../../components/deviceProfile/profileForms/deviceCatalogForm/IDeviceCatalogForm';
import UseDeviceOperator from '../../hooks/deviceProfile/UseDeviceOperator';
import { IDeviceInvoiceValue } from '../../components/deviceProfile/profileForms/deviceInvoiceForm/interfaces/InvoiceValues';
import UseDeviceInvoice from '../../hooks/deviceProfile/UseDeviceInvoice';
import UseDeviceToProfile from '../../hooks/deviceProfile/UseDeviceToProfile';
import { IDeviceItemValue } from '../../components/deviceProfile/profileForms/deviceProfileForm/interfaces/IDeviceItem';
import UseOperationParams from '../../hooks/deviceProfile/UseOperationParams';
import { IOperationParamsValues } from '../../components/deviceProfile/profileForms/operationParamsForm/IOperationParamsValues';
import { ICustomerIdentification, IDeviceProfile, IDeviceProfileProducts } from '../../interfaces/IDeviceProfile';
import UseDeviceCustomerIdentification from '../../hooks/deviceProfile/UseDeviceCustomerIdentification';
import UseGeneralParams from '../../hooks/deviceProfile/UseGeneralParams';
import { IUsabilityValues } from '../../components/deviceProfile/profileForms/usabilityForm/IUsabilityValues';
import UseUsability from '../../hooks/deviceProfile/UseUsability';
import UseUserInterface from '../../hooks/deviceProfile/UseUserInterface';
import { IUserInterfaceValues } from '../../components/deviceProfile/profileForms/userInterfaceForm/IUserInterfaceValues';
import UsePrinterParams from '../../hooks/deviceProfile/UsePrinterParams';
import { IPrinterParamsValues } from '../../components/deviceProfile/profileForms/printerParamsForm/IPrinterParamsValues';
import { IFilterProfileDevice } from '../../components/deviceProfile/filterProfile/IFilterDeviceProfile';
import UseProductss from '../../hooks/deviceProfile/UseProductss';
import { IPagSeguroParamsValues } from '../../components/deviceProfile/profileForms/pagSeguroParamsForm/IPagSeguroParamsValues';
import UsePagSeguroParams from '../../hooks/deviceProfile/UsePagSeguroParams';
import { IFilterDevice } from 'modules/equipamento/presentation/components/filterDevice/IFilterDevice';
import { ITerminalValues } from '../../components/deviceProfile/profileForms/terminalForm/ITerminalValues';
import EditTerminalUseCase from 'modules/config/deviceConfig/application/useCase/deviceProfile/EditTerminalUseCase';
import DeviceProfileApi from 'services/api/config/device/DeviceProfileApi';
import GetSmartPrinterUseCase from 'modules/config/smartPrinterConfig/application/useCases/GetSmartPrinterUseCase';
import PrinterApi from 'services/api/config/printer/PrinterApi';
import { IInventoryManagementParamsValues } from '../../components/deviceProfile/profileForms/inventoryManagementParamsForm/inventoryManagementParamsForm';
import UseErpDeviceProfiles from '../../hooks/deviceProfile/UseErpDeviceProfiles';

const UseDeviceProfileForm = (onChangeFilterHandle: (filter: IFilterProfileDevice) => void, perfilVinculo: number | undefined, path: string = "/private/pdv/perfil") => {

    const { action, id, step } = useParams<{ action: string, id: string, step: string }>();
    const [formStep, setFormStep] = useState<FormDeviceProfileStep>(FormDeviceProfileStep.PROFILE);
    const { push } = useHistory();
    const { currentLocal, localConfiguration } = useLocal();

    const [sucessSaveFlag, setSucessSaveFlag] = useState(false)
    const [modified, setModified] = useState(false);
    const [nextStepForm, setNextStepForm] = useState<FormDeviceProfileStep | undefined>();
    const [confirmModalVisible, setConfirmModalVisible] = useState(false);
    const [confirmChangeNfceVisible, setConfirmChangeNfceVisible] = useState(false);
    const [localId, setLocalId] = useState<string>();
    const [deviceToProfileItem, setDeviceToProfileItem] = useState<IDeviceItemValue[] | undefined>(undefined);

    const { addNewProfile, editNewProfile, getDefaultValue, deviceProfileDefault } = UseDeviceProfile(onChangeFilterHandle, path);
    const { editOperation, getOperationList, operations } = UseDeviceOperation(onChangeFilterHandle, path);
    const { editPaymentConfig, paymentMethods, getPaymentMethods, customPaymentMethods } = UseDevicePayment(perfilVinculo, path);
    const { editGeneralParams } = UseGeneralParams(path);
    const { editOperationParam } = UseOperationParams(path);
    const { editUsability } = UseUsability(path);
    const { editUserInterface } = UseUserInterface(path);
    const { editPrinterParams } = UsePrinterParams(path);
    const { editPagSeguroParams } = UsePagSeguroParams(path);
    const { editDeviceInvoice, } = UseDeviceInvoice(path);
    const { editEnvironmentList } = UseDeviceEnvironment(path);
    const { getDevicesList, deviceList, editDevices } = UseDeviceToProfile(path);
    const { catalogs, getCatalogsList, editDeviceCatalog } = UseDeviceCatalog(path);
    const { operators, getOperatorsList, editDeviceOperator, } = UseDeviceOperator(path);
    const { editCustomerIdentification } = UseDeviceCustomerIdentification(path);
    const { editDeviceProducts, getProductList, productList } = UseProductss(path);
    const { createDeviceProfilesErp, getDeviceProfilesErp, deviceProfileErpDefault } = UseErpDeviceProfiles(path);
    const service = DeviceProfileApi();

    useEffect(() => {
        if (currentLocal) {
            setLocalId(currentLocal.id)
        }
    }, [currentLocal]);

    const getMoreDevices = useCallback(
        (deviceFilter?: IFilterDevice) => {
            localId && getDevicesList(localId, deviceFilter)
        },
        [getDevicesList, localId],
    );

    useEffect(() => {
        setFormStep(Number(step ?? 0));
        setModified(false);
        getDefaultValue(id);//TODO: provisório revisar logica de modificaçao]
        getDeviceProfilesErp(id)
    }, [getDefaultValue, id, step])


    const onChangeFormStep = useCallback((stepForm: FormDeviceProfileStep) => {
        if (modified) {
            setConfirmModalVisible(true);
            setNextStepForm(stepForm);
        } else {
            setModified(false);
            push(`${path}/adicionar/${id}/${stepForm}`)
        }
    }, [modified, push, path, id]);

    const closeStepForm = useCallback(() => {
        if (modified) {
            setConfirmModalVisible(true);
            setNextStepForm(undefined);
        } else {
            setModified(false);
            push(`${path}`)
        }
    }, [modified, path, push])

    const onLeaveWithoutSave = useCallback(() => {
        if (nextStepForm !== undefined) {
            setNextStepForm(undefined);
            push(`${path}/adicionar/${id}/${nextStepForm}`)
        } else {
            push(`${path}`)
        }
        setModified(false);
        setConfirmModalVisible(false);
    }, [nextStepForm, push, path, id]);

    const onSubmitProfile = useCallback((values: IDeviceProfileValue) => {
        if (values.id) {
            editNewProfile(id, values);
        } else {
            addNewProfile(id, values);
        }
    }, [editNewProfile, id, addNewProfile])

    const onSubmitTerminal = useCallback((values: ITerminalValues) => {
        if (currentLocal) {
            EditTerminalUseCase(service, currentLocal.id, id, values)
            push(`${path}/adicionar/${id}/` + FormDeviceProfileStep.OTHER);
        }
    }, [currentLocal, service, id, push, path])

    const onSubmitSystemConfig = useCallback((values: IDeviceOperationItemValue[]) => {
        editOperation(id, values)
    }, [editOperation, id])

    const onSubmitPayment = useCallback((values: IDevicePaymentValue) => {
        editPaymentConfig(id, values)
    }, [editPaymentConfig, id])

    const onSubmitAmbient = useCallback(async (values: IDeviceEnviromentValue[]) => {
        await editEnvironmentList(id, values)
    }, [editEnvironmentList, id])

    const onSubmitCatalog = useCallback(async (values: IDeviceCatalogValue[]) => {
        await editDeviceCatalog(id, values)
    }, [editDeviceCatalog, id])

    const onSubmitOperator = useCallback(async (values: IOperatorItemValue[]) => {
        await editDeviceOperator(id, values)
    }, [editDeviceOperator, id])

    const onSubmitInvoice = useCallback(async (values: IDeviceInvoiceValue) => {
        await editDeviceInvoice(id, values)
    }, [editDeviceInvoice, id])

    const onSubmitUsability = useCallback((values: IUsabilityValues) => {
        editUsability(id, values);
    }, [editUsability, id])

    const onSubmitUserInterface = useCallback((values: IUserInterfaceValues) => {
        editUserInterface(id, values);
    }, [editUserInterface, id])

    const onSubmitPrinter = useCallback((values: IPrinterParamsValues) => {
        editPrinterParams(id, values);
    }, [editPrinterParams, id])

    const onSubmitPagSeguro = useCallback((values: IPagSeguroParamsValues) => {
        editPagSeguroParams(id, values);
    }, [editPagSeguroParams, id])

    const onSubmitInventoryManagement = useCallback((values: IInventoryManagementParamsValues) => {
        createDeviceProfilesErp(id, values)
    }, [id])

    const onSubmitGeneralParams = useCallback(async (values: IDeviceProfile) => {
        await editGeneralParams(id, values);
    }, [editGeneralParams, id])

    const onSubmitOperationParams = useCallback(async (values: IOperationParamsValues) => {
        await editOperationParam(id, values);
    }, [editOperationParam, id])

    const onSubmitDeviceProductsParams = useCallback(async (values: IDeviceProfileProducts) => {
        await editDeviceProducts(id, values);
    }, [editDeviceProducts, id])

    const onSubmitConfirmedDeviceToProfile = useCallback(async (values?: IDeviceItemValue[]) => {
        await editDevices(id, values ?? deviceToProfileItem ?? []);
        setConfirmChangeNfceVisible(false);
    }, [deviceToProfileItem, editDevices, id])

    const onSubmitDeviceToProfile = useCallback(async (values: IDeviceItemValue[]) => {
        if (values.filter(x => x.enabled).length > 0 && (localConfiguration?.gerarNFCE ?? false) === true) {
            setDeviceToProfileItem(values);
            setConfirmChangeNfceVisible(true);
        } else {
            onSubmitConfirmedDeviceToProfile(values);
        }
    }, [localConfiguration?.gerarNFCE, onSubmitConfirmedDeviceToProfile])

    const onSaveSubmit = useCallback((values: { sketchFlag: boolean }) => {
        if (values.sketchFlag) {
            closeStepForm();
        } else {
            setSucessSaveFlag(true);
        }
    }, [closeStepForm]);

    const onSubmitCustomerIdentification = useCallback(async (values: ICustomerIdentification) => {
        await editCustomerIdentification(id, values)
    }, [editCustomerIdentification, id]);

    useEffect(() => {

        getDefaultValue(id)
        return () => {
        }
    }, [getDefaultValue, id])

    useEffect(() => {
        if (action === "adicionar" || "editar") {
            if (id && localId) {
                switch (formStep) {
                    case FormDeviceProfileStep.SYSTEM:
                        getOperationList(localId);
                        break;
                    case FormDeviceProfileStep.PAYMENT:
                        getPaymentMethods(localId)
                        break;
                    case FormDeviceProfileStep.DEVICE:
                        getDevicesList(localId)
                        break;
                    case FormDeviceProfileStep.OPERATOR:
                        getOperatorsList(localId)
                        break;
                    case FormDeviceProfileStep.PRINTER:
                        getDefaultValue(id);
                        break;
                    case FormDeviceProfileStep.INVOICE:
                        break;
                    case FormDeviceProfileStep.MENU:
                        getCatalogsList(localId)
                        break;
                    case FormDeviceProfileStep.PARAMS:
                        break;
                    case FormDeviceProfileStep.PRODUCTS:
                        getProductList()
                        break;
                    case FormDeviceProfileStep.OPERATION_PARAMS:
                        break;
                    default:
                        getDefaultValue(id);
                        break;
                }
            } else {
            }
        }
        return () => {

        }
    }, [action, localId, formStep, getCatalogsList, getPaymentMethods, id, onSubmitCatalog, getOperatorsList, getDefaultValue, getDevicesList, getOperationList, getProductList])

    const getPrinterSectors = useCallback(async () => {
        if (currentLocal) {
            const response = await GetSmartPrinterUseCase(PrinterApi, currentLocal.id);
            return response.list;
        } else{
            throw new Error("Local não encontrado")
        }
    }, [currentLocal])

    return (
        {
            deviceProfileDefault,
            operations,
            paymentMethods,
            customPaymentMethods,
            operators,
            profileId: id,
            formStep,
            catalogs,
            sucessSaveFlag,
            deviceList,
            modified,
            confirmModalVisible,
            confirmChangeNfceVisible,
            setConfirmChangeNfceVisible,
            onLeaveWithoutSave,
            setConfirmModalVisible,
            setModified,
            onChangeFormStep,
            onSubmitProfile,
            onSubmitSystemConfig,
            onSubmitOperator,
            onSubmitPayment,
            onSubmitAmbient,
            onSubmitCatalog,
            onSubmitGeneralParams,
            onSubmitOperationParams,
            onSubmitUsability,
            onSubmitUserInterface,
            onSubmitPrinter,
            onSubmitPagSeguro,
            onSubmitInvoice,
            closeStepForm,
            onSaveSubmit,
            getDevicesList,
            editDevices,
            onSubmitDeviceToProfile,
            onSubmitCustomerIdentification,
            onSubmitDeviceProductsParams,
            productList,
            getMoreDevices,
            onSubmitTerminal,
            onSubmitConfirmedDeviceToProfile,
            getPrinterSectors,
            onSubmitInventoryManagement,
            deviceProfileErpDefault
        }
    )
}
export default UseDeviceProfileForm