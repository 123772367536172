import { useLocal } from "modules/local/presentation/context/LocalContext";
import { MutableRefObject, useCallback, useState } from "react";
import GeneralDashboardApi from "services/api/generalDashboard/GeneralDashboardApi";
import { IPosPaid } from "../interfaces/IPosPaid";
import GetPosPaidUseCase from "modules/generalDashboard/application/useCases/GetPosPaidUseCase";
import { IFilterValue } from "../interfaces/IFilter";
import { ICashierEvent } from "../interfaces/ICashierEvent";

const UsePosPaid = (filter: IFilterValue | undefined, isLoadingCashier: boolean, lastCashierOpenData: MutableRefObject<ICashierEvent | undefined>, isLoadingFinancialInfo: boolean | undefined) => {

  const [isLoading, setIsLoading] = useState<boolean>();
  const [posPaidData, setPosPaidData] = useState<IPosPaid>()
  const { currentLocal } = useLocal();

  const getPosPaid= useCallback(
    async () => {
      try {
        const serviceGeneral = GeneralDashboardApi();
        setIsLoading(true);
        if (!isLoadingCashier && !isLoadingFinancialInfo && currentLocal) {
          const cashierIds = filter?.cashierIds && filter.cashierIds.length > 0
            ? filter.cashierIds
            : lastCashierOpenData.current?.id
            ? [lastCashierOpenData.current.id]
            : undefined;

          if (!cashierIds) return;
          
          const response = await GetPosPaidUseCase(serviceGeneral,
            currentLocal.id,     
            {
              ...filter,
              cashierIds: cashierIds,
            });

          setPosPaidData(response);
          return response;
        }
      } finally {
        setIsLoading(false);
      }
    },
    [currentLocal, filter, isLoadingCashier, isLoadingFinancialInfo, lastCashierOpenData]
  );

  return {
    posPaidData,
    isLoading,
    getPosPaid,
    setPosPaidData,
  };
};

export default UsePosPaid;
