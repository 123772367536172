import React, { FC, useCallback, useEffect } from "react";
import styles from "./OperatorFragment.module.scss";
import OperatorList from "../components/operatorList/OperatorList";
import OperatorForm from "../components/operatorForm/OperatorForm";
import { IOperatorFormValue } from "../components/operatorForm/IOperatorValue";
import { IOperatorItem } from "modules/operators/domain/models/IOperatorItem";
import { OperatorRoles, OperatorRolesLabel } from "modules/operators/domain/models/OperatorRoles";
import { AddCircle, PersonPin } from "@material-ui/icons";
import Button from "components/ui/Button/Button";
import { UseOperatorFragment } from "../hooks/UseOperatorFragment";
import SidesheetFeedback from "components/sidesheet/sidesheetFeedback/SidesheetFeedback";

export interface IOperatorFragmentProps {
    setTitle: React.Dispatch<React.SetStateAction<string>>;
    openAddOperator: boolean;
    setOpenAddOperator: React.Dispatch<React.SetStateAction<boolean>>;
    selectedOperatorToEdit?: IOperatorFormValue;
    setSelectedOperatorToEdit: React.Dispatch<React.SetStateAction<IOperatorFormValue | undefined>>;
    selectedOperatorToDelete?: IOperatorItem;
    setSelectedOperatorToDelete: React.Dispatch<React.SetStateAction<IOperatorItem | undefined>>;
    setOpenOperator: (value: React.SetStateAction<boolean | "add">) => void;
    decrypt?: (encryptedMessage: string) => string | null | undefined;
}

const OperatorFragment: FC<IOperatorFragmentProps> = ({
    setTitle,
    openAddOperator,
    setOpenAddOperator,
    selectedOperatorToEdit,
    setSelectedOperatorToEdit,
    selectedOperatorToDelete,
    setSelectedOperatorToDelete,
    setOpenOperator,
    decrypt
}) => {
    const { isLoading, getOperators, operatorList, onSubmitAdd, onSubmitEdit, onSubmitDelete } = UseOperatorFragment();

    useEffect(() => {
        getOperators()
    }, [getOperators]);

    const onClickAdd = () => {
        setOpenAddOperator(true)
    }

    const operatorItemToValue = (operator: IOperatorItem): IOperatorFormValue => {
        return {
            id: operator.id,
            name: operator.name,
            password: operator.password,
            confirmPassword: operator.password,
            privilegedAccess: operator.privilegedAccess,
            roles: operator.roles.map((role) => ({ value: role as OperatorRoles, label: OperatorRolesLabel(role as OperatorRoles) })),
            commission: operator.commission,
            useCashlessCardAsPassword: operator.useCashlessCardAsPassword,
            tag: operator.tag
        }
    }

    const onClickEdit = (operator: IOperatorItem) => {
        setSelectedOperatorToEdit(operatorItemToValue(operator))
    }

    const onClickDelete = (operator: IOperatorItem) => {
        setSelectedOperatorToDelete(operator)
    }

    const onSubmitAddOperator = useCallback(async (value: IOperatorFormValue) => {
            try {
                await onSubmitAdd(value);
                setOpenAddOperator(false);
                getOperators();
            } finally {}
    }, [getOperators, onSubmitAdd, setOpenAddOperator]);

    const onSubmitEditOperator = useCallback(async (value: IOperatorFormValue) => {
        if (selectedOperatorToEdit) {
            try {
                await onSubmitEdit(selectedOperatorToEdit, value);
                setSelectedOperatorToEdit(undefined);
                getOperators();
            } finally {}
        }
    }, [getOperators, onSubmitEdit, selectedOperatorToEdit, setSelectedOperatorToEdit])

    const onSubmitDeleteOperator = useCallback(async () => {
        if (selectedOperatorToDelete) {
            try {
                await onSubmitDelete(selectedOperatorToDelete);
                setSelectedOperatorToDelete(undefined);
                getOperators();
            } finally {}
        }
    }, [getOperators, onSubmitDelete, selectedOperatorToDelete, setSelectedOperatorToDelete]);

    const handleGoBack = useCallback(() => {
        setTitle("Operadores");
        setOpenAddOperator(false);
        setSelectedOperatorToEdit(undefined);
        setSelectedOperatorToDelete(undefined);
    }, [setOpenAddOperator, setSelectedOperatorToDelete, setSelectedOperatorToEdit, setTitle]);

    if (openAddOperator) {
        setTitle("Novo operador");
        return (
            <OperatorForm
                isLoading={isLoading}
                onSubmit={onSubmitAddOperator}
                handleGoBack={handleGoBack}
            />
        );
    }

    if (!!selectedOperatorToEdit) {
        setTitle("Editar operador");
        return (
            <OperatorForm
                isEdit
                isLoading={isLoading}
                defaultValue={selectedOperatorToEdit}
                onSubmit={onSubmitEditOperator}
                handleGoBack={handleGoBack}
                decrypt={decrypt}
            />
        );
    }

    if (!!selectedOperatorToDelete) {
        setTitle("Excluir operador");
        return (
            <div className={styles.deleteOperator}>
                <SidesheetFeedback
                    text={<span>Deseja realmente excluir o operador?{<br />}Essa ação não poderá ser desfeita.</span>}
                />
                <div className={styles.footerButtons}>
                    <Button variant="outlined" onClick={handleGoBack}>Voltar</Button>
                    <Button onClick={onSubmitDeleteOperator}>Excluir</Button>
                </div>
            </div>
        );
    }

    return (
        <div id={styles.OperatorFragment} >
            <div className={styles.content}>
                <Button variant="outlined" color='primary' onClick={onClickAdd} startIcon={<AddCircle />} >Novo operador</Button>
                <div className={styles.description} >
                    <PersonPin fontSize="large" />
                    <p>Operadores são quem irão utilizar o seu terminal de vendas. Você pode limitar as permissões de cada um.</p>
                </div>
                <OperatorList
                    operatorList={operatorList}
                    onClickAdd={onClickAdd}
                    onClickDelete={onClickDelete}
                    onClickEdit={onClickEdit}
                    isLoading={isLoading}
                />
            </div>
            <div className={styles.footerButtons}>
                <Button variant="outlined" onClick={() => setOpenOperator(false)}>Cancelar</Button>
                {/* <Button disabled={isLoading} onClick={() => setOpenOperator(false)}>
                    {isLoading ? <CircularProgress size={16} color="inherit" /> : "Salvar"}
                </Button> */}
            </div>
        </div>
    )
}
export default OperatorFragment;

