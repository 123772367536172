import { IGetSalesByDayParams } from "../domain/dto/IGetSalesByDayParams";
import { IGetSalesByDaysResponse } from "../domain/dto/IGetSalesByDayResponse";
import { ISalesByDayReportService } from "../domain/interface/ISalesByDayReportService";

const GetSalesByDayUseCase = async (service: ISalesByDayReportService, params: IGetSalesByDayParams): Promise<IGetSalesByDaysResponse> => {
  const response = service.getSalesByDay(params);
  return response;
};

export default GetSalesByDayUseCase;
