import { useCallback, useEffect, useState } from 'react';
import { Grid, MenuItem, Select, TextField } from '@mui/material';
import { Pagination } from '@material-ui/lab';
import { formatISO } from 'date-fns';

import Layout from 'components/layout/presentation/Layout';
import styles from './Orders.module.scss';
import { useLocal } from 'modules/local/presentation/context/LocalContext';
import { OrderItem } from './OrderItem/OrderItem';
import { GetOrdersUseCase } from 'modules/order/application/useCases/GetOrdersUseCase';
import OrderApi from 'services/api/order/OrderApi';
import { IPaginatedOrders } from '../../interfaces/IPaginatedOrders';
import ConfirmOrderAction from '../../components/ConfirmOrderAction/ConfirmOrderAction';
import { ReprocessOrderUseCase } from 'modules/order/application/useCases/reprocessOrderUseCase';
import { UpdateAndReprocessOrderUseCase } from 'modules/order/application/useCases/updateAndReprocessOrderUseCase';
import { SendOrderByEmailUseCase } from 'modules/order/application/useCases/SendOrderByEmailUseCase';
import { CancelOrderUseCase } from 'modules/order/application/useCases/cancelOrderUseCase';
import { useUi } from 'contexts/userInterface/UserInterfaceContext';
import { formatCpfCnpj, onlyNumber } from 'modules/contaDigital/presentation/pages/utils';
import Utils from 'services/utils/Utils';

interface IFilter {
    status: string;
    orderDate: string;
    number: string;
    document: string;
}

const INITIAL_FILTER = {
    status: '',
    orderDate: '',
    number: '',
    document: '',
}

const statusList = [{
    label: 'Autorizada',
    value: 'Autorizada',
}, {
    label: 'Em processamento',
    value: 'Em processamento',
},{
    label: 'Emitida',
    value: 'Emitida',
},{
    label: 'Emitindo',
    value: 'Emitindo',
},{
    label: 'Emitida em contigencia',
    value: 'Emitida em contigencia',
},{
    label: 'Emitida offline',
    value: 'Emitida offline',
},{
    label: 'Emitida com falha',
    value: 'Emitida com falha',
},{
    label: 'Emitida assíncrono',
    value: 'Emitida assíncrono',
},{
    label: 'Em digitação',
    value: 'Em digitação',
},{
    label: 'Em processamento na sefaz',
    value: 'Em processamento na sefaz',
},{
    label: 'Rejeitada',
    value: 'Rejeitada',
}]

const service = OrderApi();

export const Orders = () => {
    const { currentLocal } = useLocal();
    const { toast } = useUi();

    const [data, setData] = useState({} as IPaginatedOrders);
    const [currentPage, setCurrentPage] = useState(1);
    const [filter, setFilter] = useState(INITIAL_FILTER);
    const [showConfirmAction, setShowConfirmAction] = useState(false);
    const [actionInfo, setActionInfo] = useState({ type: '', invoiceId: '' });

    useEffect(() => {
        getData();
    }, []);

    const getData = useCallback(async (page?: number) => {
        if (currentLocal?.id) {
            try {
                const params = { page: page || 1, pageSize: 20 };
                const response = await GetOrdersUseCase(service, currentLocal.id, params);
                dataHandler(response);
                setCurrentPage(page || 1);
            } catch(e) {
                toast('Um erro ocorreu ao buscar os pedidos.', 'error');
            }
        }
    }, [currentLocal?.id]);

    const filterData = async () => {
        const params: any = { page: 1, pageSize: 20 };
        if (filter.status) params['status'] = filter.status;
        if (filter.number) params['number'] = filter.number;
        if (filter.document) params['document'] = onlyNumber(filter.document);
        if (filter.orderDate) params['orderDate'] = formatISO(new Date(filter.orderDate));

        try {
            const response = await GetOrdersUseCase(service, currentLocal!.id, params);
            dataHandler(response);
            setCurrentPage(1);
        } catch(e) {
            toast('Um erro ocorreu ao buscar os pedidos.', 'error');
        }
    }

    const dataHandler = (orders: IPaginatedOrders) => {
        setData(() => ({
            Page: orders.Page,
            HasNext: orders.HasNext,
            Items: orders.Items.map(item => {
                let nfce = item.NfceData || '';

                if (Utils.isValidJson(nfce)) {
                    nfce = JSON.parse(nfce);
                }

                return {
                    ...item,
                    NfceData: nfce
                }
            }),
        }));
    }

    const showActionConfirmation = (type: string, invoiceId: string) => {
        setActionInfo({ type, invoiceId })
        setShowConfirmAction(true);
    }

    const triggerAction = (email?: string) => {
        setShowConfirmAction(false);

        if (actionInfo.type === 'RESEND') {
            reprocessNfce();
            return;
        }

        if (actionInfo.type === 'UPDATE') {
            updateAndReprocessNfce();
            return;
        }

        if (actionInfo.type === 'SEND_EMAIL') {
            sendByEmailNfce(email);
            return;
        }

        if (actionInfo.type === 'CANCEL') {
            cancelNfce();
        }
    }

    const reprocessNfce = async () => {
        try {
            await ReprocessOrderUseCase(service, currentLocal!.id, actionInfo.invoiceId);
            toast('NFC-e reemitida com sucesso.', 'success');
        } catch(e) {
            //
        }
    }

    const updateAndReprocessNfce = async () => {
        try {
            await UpdateAndReprocessOrderUseCase(service, currentLocal!.id, actionInfo.invoiceId);
            toast('NFC-e atualizada e reemitida com sucesso.', 'success');
        } catch(e) {
            //
        }
    }

    const sendByEmailNfce = async (email = '') => {
        try {
            await SendOrderByEmailUseCase(service, currentLocal!.id, actionInfo.invoiceId, email);
            toast('NFC-e enviada por e-mail com sucesso.', 'success');
        } catch(e) {
            //
        }
    }

    const cancelNfce = async () => {
        try {
            await CancelOrderUseCase(service, currentLocal!.id, actionInfo.invoiceId);
            getData();
            toast('NFC-e cancelada com sucesso.', 'success');
        } catch(e) {
            //
        }
    }

    return (
        <Layout>
            <div className={styles.ordersWrapper}>
                <h1 className={styles.ordersTitle}>Pedidos</h1>

                <Grid container columnSpacing={{ xs: 2 }}>
                    <Grid container item xs={12} sm spacing={{ xs: 2 }} style={{ flexGrow: 1 }}>
                        {/* STATUS */}
                        <Grid item xs={12} sm={6} md={3}>
                            <label className={styles.ordersLabel}>Status</label>
                            <Select
                                value={filter.status}
                                style={{ width: "100%" }}
                                size="small"
                                onChange={(ev) => setFilter((old) => ({ ...old, status: ev.target.value }))}
                            >
                                {statusList.map(status => (
                                    <MenuItem value={status.value} key={status.value}>
                                        {status.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>

                        {/* DOC OR INVOICE NUMBER */}
                        <Grid item xs={12} sm={6} md={3}>
                            <label className={styles.ordersLabel}>Nº da nota</label>
                            <TextField
                                variant="outlined"
                                fullWidth
                                size="small"
                                value={filter.number}
                                onChange={(ev) => setFilter((old) => ({ ...old, number: onlyNumber(ev.target.value) }))}
                            />
                        </Grid>

                        {/* Documento */}
                        <Grid item xs={12} sm={6} md={3}>
                            <label className={styles.ordersLabel}>Documento</label>
                            <TextField
                                variant="outlined"
                                fullWidth
                                size="small"
                                value={filter.document}
                                onChange={(ev) => setFilter((old) => ({ ...old, document: formatCpfCnpj(ev.target.value) }))}
                            />
                        </Grid>


                        {/* PURCHASE DATE */}
                        <Grid item xs={12} sm={6} md={3}>
                            <label className={styles.ordersLabel}>Data da compra</label>
                            <TextField
                                name={"orderDate"}
                                variant="outlined"
                                placeholder='00/00/0000'
                                size="small"
                                fullWidth
                                value={filter.orderDate}
                                onChange={(ev: any) => setFilter((old) => ({ ...old, orderDate: ev.target.value }))}
                                type="date"
                            />
                        </Grid>
                    </Grid>

                    <Grid container item xs={12} sm="auto">
                        <Grid item xs={12}>
                            <div className={styles.filterWrapper}>
                                <button className={styles.clearButton} onClick={() =>  setFilter(INITIAL_FILTER)}>
                                    Limpar
                                </button>

                                <button className={styles.searchButton} onClick={filterData}>
                                    Buscar
                                </button>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>

                <div className={styles.tableContainer}>
                    <div className={styles.header}>
                        <div className={styles.column}>Número da nota</div>
                        <div className={styles.column}>Data da compra</div>
                        <div className={styles.column}>Data da emissão</div>
                        <div className={styles.column}>Status NFC-e</div>
                        <div className={styles.column}>Valor</div>
                        <div className={styles.column}>Desconto</div>
                        <div className={`${styles.column} ${styles.products}`} />
                        <div className={`${styles.column} ${styles.fixed}`} />
                    </div>

                    <div className={styles.body}>
                        {
                            !data.Items?.length ?
                                <span className={styles.noData}>Nenhum dado encontrado</span> :
                                data.Items?.map(item => (
                                    <OrderItem
                                        key={item.OrderId}
                                        item={item}
                                        triggerActionFn={(type, invoiceId) => showActionConfirmation(type, invoiceId)}
                                    />
                                ))
                        }
                    </div>

                    <Pagination
                        page={currentPage}
                        color="primary"
                        className={styles.pagination}
                        defaultPage={1}
                        hideNextButton={!data.HasNext}
                        count={data.HasNext ? data.Page + 1 : data.Page}
                        onChange={(ev, page) => getData(page)}
                    />
                </div>
            </div>

            <ConfirmOrderAction
                isOpen={showConfirmAction}
                actionType={actionInfo.type}
                cancelFn={() => setShowConfirmAction(false)}
                confirmFn={triggerAction}
            />
        </Layout>
    )
}
