import { TextField } from '@material-ui/core';
import styles from './InvoiceAccounting.module.scss';
import { Dispatch, SetStateAction, useState } from 'react';
import { ICompanyForm, ICompanyFormFSProduction } from '../interfaces/ICompanyForm';
import { Popover } from '../InvoiceRegister/Popover';

interface InvoiceAccountingProps {
    onSubmit: () => void;
    onCancel: () => void;
    setForm: Dispatch<SetStateAction<ICompanyForm>>;
    form: ICompanyForm;
    loading: boolean;
    modal?: boolean;
}

interface Errors {
    tokenIDCSC: string;
    tokenCSC: string;
    number: string;
    nfeNumber: string;
    nfeSequence: string;
}

export const InvoiceAccounting = ({ onSubmit, onCancel, form, setForm, loading, modal = false }: InvoiceAccountingProps) => {
    const [errors, setErrors] = useState({} as Errors);

    const validate = () => {
        let isValid = true;
        setErrors({} as Errors);

        if (!form.federalSettingsProduction?.nfeSequence) {
            setErrors(prev => ({ ...prev, nfeSequence: 'NFC-e serie é obrigatório' }));
            isValid = false;
        }

        if (!form.federalSettingsProduction?.nfeNumber) {
            setErrors(prev => ({ ...prev, nfeNumber: 'numeração inicial é obrigatório' }));
            isValid = false;
        }

        if (!form.federalSettingsProduction?.tokenIDCSC) {
            setErrors(prev => ({ ...prev, tokenIDCSC: 'ID do token CSC é obrigatório' }));
            isValid = false;
        }

        if (!form.federalSettingsProduction?.tokenCSC) {
            setErrors(prev => ({ ...prev, tokenCSC: 'Token do CSC é obrigatório' }));
            isValid = false;
        }

        return isValid;
    }

    const handleSubmit = () => {
        if (!validate()) return;

        onSubmit();
    }

    return (
        <div className={styles.container}>
            <h4>{!modal && <b>2.1 </b>}Configurando ambiente de <strong>Produção:</strong></h4>
            <span className={styles.description}>Complete as informações necessárias para configurar o ambiente de produção: garantindo o correto funcionamento das operações fiscais.</span>

            <div className={styles.row}>
                <div className={styles.column}>
                    <div className={styles.labelContainer}>
                        <label className={styles.required}>NFC-e serie</label>
                        <Popover title='NFC-e série' description='Campo que identifica a série específica da NFC-e a ser emitida, utilizando uma numeração sequencial para diferenciar lotes de notas fiscais do consumidor eletrônicas.' />
                    </div>
                    <TextField
                        variant="outlined"
                        fullWidth
                        size="small"
                        value={form.federalSettingsProduction?.nfeSequence}
                        onChange={(event) => setForm(prev => ({ ...prev, federalSettingsProduction: { ...prev.federalSettingsProduction || {} as ICompanyFormFSProduction, nfeSequence: event.target.value } }))}
                        helperText={errors.nfeSequence}
                        error={!!errors.nfeSequence}
                    />
                </div>
            </div>

            <div className={styles.row}>
                <div className={styles.column}>
                    <div className={styles.labelContainer}>
                        <label className={styles.required}>Numeração inicial</label>
                        <Popover title='Numeração inicial' description='Número inicial da sequência de numeração das NFC-e a serem emitidas. Ele define o primeiro número da série que será utilizado para emissão das notas fiscais do consumidor eletrônicas.' />
                    </div>
                    <TextField
                        variant="outlined"
                        fullWidth
                        size="small"
                        value={form.federalSettingsProduction?.nfeNumber}
                        onChange={(event) => setForm(prev => ({ ...prev, federalSettingsProduction: { ...prev.federalSettingsProduction || {} as ICompanyFormFSProduction, nfeNumber: event.target.value } }))}
                        helperText={errors.nfeNumber}
                        error={!!errors.nfeNumber}
                    />
                </div>
            </div>

            <div className={styles.row}>
                <div className={styles.column}>
                    <div className={styles.labelContainer}>
                        <label className={styles.required}>ID do token CSC</label>
                        <Popover title='ID do token CSC' description='Identificador único do Código de Segurança do Contribuinte (CSC), obtido junto às autoridades fiscais, necessário para autenticar a emissão de NFC-e.' />
                    </div>
                    <TextField
                        variant="outlined"
                        fullWidth
                        size="small"
                        value={form.federalSettingsProduction?.tokenIDCSC}
                        onChange={(event) => setForm(prev => ({ ...prev, federalSettingsProduction: { ...prev.federalSettingsProduction || {} as ICompanyFormFSProduction, tokenIDCSC: event.target.value } }))}
                        helperText={errors.tokenIDCSC}
                        error={!!errors.tokenIDCSC}
                    />
                </div>
            </div>

            <div className={styles.row}>
                <div className={styles.column}>
                    <div className={styles.labelContainer}>
                        <label className={styles.required}>Token do CSC</label>
                        <Popover title='Token do CSC' description='Código de segurança é obtido no portal do Contribuinte ou junto à Secretaria da Fazenda. Ele é essencial para a autenticação da emissão de NFC-e.' />
                    </div>
                    <TextField
                        variant="outlined"
                        fullWidth
                        size="small"
                        value={form.federalSettingsProduction?.tokenCSC}
                        onChange={(event) => setForm(prev => ({ ...prev, federalSettingsProduction: { ...prev.federalSettingsProduction || {} as ICompanyFormFSProduction, tokenCSC: event.target.value } }))}
                        helperText={errors.tokenCSC}
                        error={!!errors.tokenCSC}
                    />
                </div>
            </div>

            <div className={styles.row}>
                <div className={styles.column}>
                    <div className={styles.labelContainer}>
                        <label>SAT</label>
                        <Popover title='SAT' description='Utilizado para emissão de cupons fiscais eletrônicos no varejo. Ele é obrigatório em alguns estados brasileiros para empresas que operam com vendas diretas ao consumidor final.' />
                    </div>
                    <TextField
                        variant="outlined"
                        fullWidth
                        size="small"
                        value={form.federalSettingsProduction?.number}
                        onChange={(event) => setForm(prev => ({ ...prev, federalSettingsProduction: { ...prev.federalSettingsProduction || {} as ICompanyFormFSProduction, number: event.target.value } }))}
                    />
                </div>
            </div>

            <h4>{!modal && <b>2.2 </b>}Configurando ambiente de <strong>Homologação:</strong></h4>
            <span className={styles.description}>Complete as informações necessárias para configurar o ambiente de produção: garantindo o correto funcionamento das operações fiscais.</span>

            <div className={styles.row}>
                <div className={styles.column}>
                    <div className={styles.labelContainer}>
                        <label>NFC-e serie</label>
                        <Popover title='NFC-e série' description='Campo que identifica a série específica da NFC-e a ser emitida, utilizando uma numeração sequencial para diferenciar lotes de notas fiscais do consumidor eletrônicas.' />
                    </div>
                    <TextField
                        variant="outlined"
                        fullWidth
                        size="small"
                        value={form.federalSettingsHomologation?.nfeSequence}
                        onChange={(event) => setForm(prev => ({ ...prev, federalSettingsHomologation: { ...prev.federalSettingsHomologation || {} as ICompanyFormFSProduction, nfeSequence: event.target.value } }))}
                    />
                </div>
            </div>

            <div className={styles.row}>
                <div className={styles.column}>
                    <div className={styles.labelContainer}>
                        <label>Numeração inicial</label>
                        <Popover title='Numeração inicial' description='Número inicial da sequência de numeração das NFC-e a serem emitidas. Ele define o primeiro número da série que será utilizado para emissão das notas fiscais do consumidor eletrônicas.' />
                    </div>
                    <TextField
                        variant="outlined"
                        fullWidth
                        size="small"
                        value={form.federalSettingsHomologation?.nfeNumber}
                        onChange={(event) => setForm(prev => ({ ...prev, federalSettingsHomologation: { ...prev.federalSettingsHomologation || {} as ICompanyFormFSProduction, nfeNumber: event.target.value } }))}
                    />
                </div>
            </div>

            <div className={styles.row}>
                <div className={styles.column}>
                    <div className={styles.labelContainer}>
                        <label>ID do token CSC</label>
                        <Popover title='ID do token CSC' description='Identificador único do Código de Segurança do Contribuinte (CSC), obtido junto às autoridades fiscais, necessário para autenticar a emissão de NFC-e.' />
                    </div>
                    <TextField
                        variant="outlined"
                        fullWidth
                        size="small"
                        value={form.federalSettingsHomologation?.tokenIDCSC}
                        onChange={(event) => setForm(prev => ({ ...prev, federalSettingsHomologation: { ...prev.federalSettingsHomologation || {} as ICompanyFormFSProduction, tokenIDCSC: event.target.value } }))}
                    />
                </div>
            </div>

            <div className={styles.row}>
                <div className={styles.column}>
                    <div className={styles.labelContainer}>
                        <label>Token do CSC</label>
                        <Popover title='Token do CSC' description='Código de segurança é obtido no portal do Contribuinte ou junto à Secretaria da Fazenda. Ele é essencial para a autenticação da emissão de NFC-e.' />
                    </div>
                    <TextField
                        variant="outlined"
                        fullWidth
                        size="small"
                        value={form.federalSettingsHomologation?.tokenCSC}
                        onChange={(event) => setForm(prev => ({ ...prev, federalSettingsHomologation: { ...prev.federalSettingsHomologation || {} as ICompanyFormFSProduction, tokenCSC: event.target.value } }))}
                    />
                </div>
            </div>

            <div className={styles.buttonsContainer}>
                <button className={styles.cancelButton} onClick={onCancel}>Voltar</button>
                <button className={styles.submitButton} disabled={loading} onClick={handleSubmit}>
                    {
                        loading
                            ? 'Salvando...'
                            : 'Salvar'
                    }
                </button>
            </div>
        </div>
    )
}