import { IGetCashlessResponse } from "modules/generalDashboard/domain/dto/IGetCashlessResponse";
import { IGeneralDashboardApiService } from "modules/generalDashboard/domain/interfaces/IGeneralDashboardApiService";
import { IFilterValue } from "modules/generalDashboard/presentation/interfaces/IFilter";


const GetCashlessUseCase = async (service: IGeneralDashboardApiService, localId: string,  filter?: IFilterValue): Promise<IGetCashlessResponse> => {
    const response = await service.getCashless(localId, filter);

    return response;
}

export default GetCashlessUseCase;