import UseDimension from "components/dimension/UseDimension";
import styles from "./SalesByDayFooter.module.scss";
import { moneyMaskNumber } from "services/utils/Money";
import { IGetSalesByDaysResponse } from "modules/SaleForDayPage/domain/dto/IGetSalesByDayResponse";

interface ISalesByDayFooter {
  item: IGetSalesByDaysResponse;
}

const SalesByDayFooter = ({ item }: ISalesByDayFooter) => {
  const { dimensions } = UseDimension();

  return (
    <div id={styles.salesByDayItem}>
      {dimensions.width > 650 ? (
        <div className={styles.saleItem}>
          <span style={{ textAlign: 'left' }}>Total Geral:</span>
          <span>{moneyMaskNumber(item.TotalCashless ?? 0)}</span>
          <span>{moneyMaskNumber(item.TotalCancelado ?? 0)}</span>
          <span>{moneyMaskNumber(item.TotalBoleto ?? 0)}</span>
          <span>{moneyMaskNumber(item.TotalDebito ?? 0)}</span>
          <span>{moneyMaskNumber(item.TotalDebitoNT ?? 0)}</span>
          <span>{moneyMaskNumber(item.TotalCredito ?? 0)}</span>
          <span>{moneyMaskNumber(item.TotalCreditoNT ?? 0)}</span>
          <span>{moneyMaskNumber(item.TotalDinheiro ?? 0)}</span>
          <span>{moneyMaskNumber(item.TotalDesconto ?? 0)}</span>
          <span>{moneyMaskNumber(item.TotalVoucher ?? 0)}</span>
          <span>{moneyMaskNumber(item.TotalOutros ?? 0)}</span>
          <span>{moneyMaskNumber(item.TotalEmAberto ?? 0)}</span>
          <span>{moneyMaskNumber(item.TotalValorTotalTransacionado ?? 0)}</span>
          <span>{moneyMaskNumber(item.TotalValorTotalNaoTransacionado ?? 0)}</span>
          <span>{moneyMaskNumber(item.TotalGeral ?? 0)}</span>
        </div>
      ) : (
        <div className={styles.totalItemMobile}>
          <div className={styles.item}>
            <span>Total geral</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Cashless:</span>
            <span>{moneyMaskNumber(item.TotalCashless ?? 0)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Cancelado:</span>
            <span>{moneyMaskNumber(item.TotalCancelado ?? 0)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Boleto:</span>
            <span>{moneyMaskNumber(item.TotalBoleto ?? 0)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Débito:</span>
            <span>{moneyMaskNumber(item.TotalDebito ?? 0)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Débito (NT):</span>
            <span>{moneyMaskNumber(item.TotalDebitoNT ?? 0)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Crédito:</span>
            <span>{moneyMaskNumber(item.TotalCredito ?? 0)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Crédito (NT):</span>
            <span>{moneyMaskNumber(item.TotalCreditoNT ?? 0)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Dinheiro:</span>
            <span>{moneyMaskNumber(item.TotalDinheiro ?? 0)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Desconto:</span>
            <span>{moneyMaskNumber(item.TotalDesconto ?? 0)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Voucher:</span>
            <span>{moneyMaskNumber(item.TotalVoucher ?? 0)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Outros:</span>
            <span>{moneyMaskNumber(item.TotalOutros ?? 0)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Em aberto:</span>
            <span>{moneyMaskNumber(item.TotalEmAberto ?? 0)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Total:</span>
            <span>{moneyMaskNumber(item.TotalValorTotalTransacionado ?? 0)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Total (NT):</span>
            <span>{moneyMaskNumber(item.TotalValorTotalNaoTransacionado ?? 0)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Total geral:</span>
            <span>{moneyMaskNumber(item.TotalGeral ?? 0)}</span>
          </div>
        </div>
      )}
    </div>
  );
};
export default SalesByDayFooter;
