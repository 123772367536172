import React, { FC, Fragment } from 'react'
import { IKDSItem } from 'modules/kds/presentation/components/KDSItem/IKDSItem';
import Utils from 'services/utils/Utils';
import { moneyMaskNumber } from 'services/utils/Money';

interface LayoutPrinterProps {
    ticketToPrint: IKDSItem,   
}

export const LayoutPrinter: FC<LayoutPrinterProps> = ({ ticketToPrint }) => {

    const layoutPrinterStyles = {
        backgroundColor: '#ffffff',
        fontWeight: 500,
        overflow: 'visible',
        borderTop: '5px double #ddd',
        position: 'relative' as 'relative',        
        zindex: 1,
        fontsize: '40px',
        width: "250px",
    };

    const containerResumoLayoutPrinterStyles = {};

    const logoResumoLayoutPrinterStyles = {
        textAlign: 'center' as 'center'
    };

    const localNameResumoLayoutPrinterStyles = {
        textAlign: 'center' as 'center'
    };

    const friendlyIdResumeStyles = {
        // marginBottom: '10px'
    };

    const textLabelPedidoStyles = {
        fontSize: '14px',
    };

    const textPedidoStyles = {
        fontSize: '14px',
        fontWeight: 'bold' as 'bold'
    };

    const ordersItemsResumoLayoutPrinterStyles = {};
    const contentOrdersItemStyles = {
        borderBottom: '1px solid #000'

    };

    const ordersItemResumoLayoutPrinterStyles = {
        display: 'flex',
        gap: '8px',
         margin: '6px 0',
        justifyContent: 'space-between' as 'space-between',
        fontSize: '14px'
    };

    const nameOrdersItemResumoLayoutPrinterStyles = {};

    const priceOrdersItemResumoLayoutPrinterStyles = {
        marginLeft: '14px'
    };

    const compositionOrdersItemResumoLayoutPrinterStyles = {
        fontSize: '14px',       
        borderRadius: '8px',
        textAlign: 'left' as 'left',
        border: '1px dashed #000',
        margin: '8px 0',
        padding: '6px'
    };

    const optionsOrdersItemResumoLayoutPrinterStyles = {};

    const descriptionOptionConsumerordersItemResumoLayoutPrinterStyles = {
        display: 'flex' as 'flex'
    };

    const optionsItemsOptionConsumerordersItemResumoLayoutPrinterStyles = {
        marginLeft: '14px'
    };

    const optionItemOptionConsumerordersItemResumoLayoutPrinterStyles = {
        display: 'flex' as 'flex',
        justifyContent: 'space-between' as 'space-between'
    };

    const observationResumoLayoutPrinterStyles = {
        fontSize: '14px'
    };

    const amountResumoLayoutPrinterStyles = {
        marginTop: '8px', 
        fontSize: 'large',
        display: 'flex' as 'flex',
        justifyContent: 'space-between' as 'space-between'
    };

    const resumeLayoutDottedSeparatorStyles = {
        margin: '16px 0',
        borderBottom: '3px dotted #000'
    };

    const kioskNameStyles = {
        fontSize: '14px'
    };

    const dateResumoLayoutPrinterStyles = {
        fontSize: '14px'
    };

    return (
        <div style={layoutPrinterStyles}>
            <div style={containerResumoLayoutPrinterStyles}>

                <div style={localNameResumoLayoutPrinterStyles}>
                    {
                        ticketToPrint.deviceName
                    }
                </div>

                <div style={friendlyIdResumeStyles}>
                    <span style={textLabelPedidoStyles}>Pedido:</span> <span style={textPedidoStyles}>{ticketToPrint?.code}</span>
                </div>

                <div style={ordersItemsResumoLayoutPrinterStyles}>
                    {
                        ticketToPrint.order.items.map((item, key) => (
                            <div key={key} style={contentOrdersItemStyles}>
                                <Fragment>
                                    <div style={ordersItemResumoLayoutPrinterStyles}>
                                        <div style={nameOrdersItemResumoLayoutPrinterStyles}>{item.name}</div>
                                        <div style={priceOrdersItemResumoLayoutPrinterStyles}>
                                            {moneyMaskNumber(item.value * (item.quantity ?? 1))}
                                        </div>
                                    </div>

                                    {item.compositions && item.compositions.length > 0 && (
                                        <div style={compositionOrdersItemResumoLayoutPrinterStyles}>
                                            {item.compositions.map((composition, compKey) => (
                                                <div style={optionsOrdersItemResumoLayoutPrinterStyles} key={compKey}>
                                                    <div style={descriptionOptionConsumerordersItemResumoLayoutPrinterStyles}>
                                                        {composition.description}:
                                                    </div>
                                                    <div style={optionsItemsOptionConsumerordersItemResumoLayoutPrinterStyles}>
                                                        {composition.options.map((option, optionKey) => (
                                                            <div
                                                                style={optionItemOptionConsumerordersItemResumoLayoutPrinterStyles}
                                                                key={optionKey}
                                                            >
                                                                <div>{option}</div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </Fragment>
                            </div>
                        ))
                    }

                    {
                        ticketToPrint.observation && <div style={observationResumoLayoutPrinterStyles}>
                            {ticketToPrint?.observation}
                        </div>
                    }

                    <div style={amountResumoLayoutPrinterStyles}>
                        <div>Total:</div> <div>{moneyMaskNumber(ticketToPrint.amount)}</div>
                    </div>

                    <div style={resumeLayoutDottedSeparatorStyles}>
                        {ticketToPrint.customer && ticketToPrint?.customer.name &&
                            <div style={kioskNameStyles}>
                                {'Nome: ' + ticketToPrint?.customer.name}
                            </div>
                        }
                        { 
                            ticketToPrint.customer?.document && ticketToPrint.customer?.document.length === 11 &&
                                <div style={kioskNameStyles}>
                                    CPF:{Utils.hideCpf(ticketToPrint.customer?.document)}
                                </div>
                        }
                        {
                        ticketToPrint.customer?.document && ticketToPrint.customer?.document.length === 14 &&
                        <div style={kioskNameStyles}>
                            CNPJ:{Utils.hideCnpj(ticketToPrint.customer?.document)}
                        </div>
                        }
                        {
                        ticketToPrint.table &&
                        <div style={kioskNameStyles}>
                            Mesa: {ticketToPrint.table}
                        </div>
                        }
                        <div style={dateResumoLayoutPrinterStyles}>
                            {Utils.toDateAndTime(ticketToPrint.createdAt)}
                        </div>
                        <div style={dateResumoLayoutPrinterStyles}>
                            {ticketToPrint.deviceName}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
