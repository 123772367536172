import { FC, useCallback } from "react";
import { Button } from "@mui/material";
import styles from './InputStatus.module.scss'
import { EnumCardStatus } from "../interfaces/IHouseFlowFilter";
import { ParamsClientHouseFlow } from "modules/houseFlow/presentation/hooks/UseHouseFlowList";
import { useHouseFlowList } from "modules/houseFlow/presentation/context/HouseFlowListContext";


export const InputStatus: FC<{ 
  value?: EnumCardStatus; 
  onChange: (value?: EnumCardStatus) => void; 
  params: ParamsClientHouseFlow | undefined; 
}> = ({ value, onChange }) => {

  const { inUse, available, idle } = useHouseFlowList();

  const onChangeHandle = useCallback((_value: EnumCardStatus) => {
    if (value !== _value) {
      onChange(_value);
    } else {
      onChange(undefined);
    }
  }, [onChange, value]);

  const onChangeHandleTotal = useCallback(() => {
    onChange(undefined);
  }, [onChange]);

  const total = (inUse + available + idle);

  const calculatePercentage = useCallback((totalStatus: number) => {
    if (total > 0) {
      let totalPercente = ((totalStatus / total) * 100).toFixed(2);
      return totalPercente
    }
    return 0

  }, [total]);

  return (

    <div id={styles.InputStatus}>
      <label>Status</label>
      <div className={styles.buttonContainer}>
        {
          <Button 
            className={value === undefined ? styles.buttonSelected : styles.button} 
            size="small" 
            variant={"outlined"} 
            onClick={onChangeHandleTotal}>
              Total 
              <span>{`(${total})`}</span>
          </Button>
        }

        {
          inUse > 0 &&
          <Button 
            className={value === EnumCardStatus.INUSE ? styles.buttonSelected : styles.button} 
            size="small" 
            variant={"outlined"} 
            onClick={() => onChangeHandle(EnumCardStatus.INUSE)}>
              <span 
                style={{ backgroundColor: " #ED0029 " }} 
                className={styles.boxPercente}>
                  {calculatePercentage(inUse)}%
              </span>
              Ocupados 
              <span>{`(${inUse})`}</span>
          </Button>
        }
        {
          available > 0 &&
          <Button 
            className={value === EnumCardStatus.AVAILABLE ? styles.buttonSelected : styles.button} 
            size="small" 
            variant={"outlined"} 
            onClick={() => onChangeHandle(EnumCardStatus.AVAILABLE)}>
              <span 
                style={{ backgroundColor: " #00BFA5" }} 
                className={styles.boxPercente}>
                  {calculatePercentage(available)}%
              </span>
              Disponíveis
              <span>{`(${available})`}</span>
          </Button>
        }
        {
          idle > 0 &&
          <Button 
            className={value === EnumCardStatus.IDLE ? styles.buttonSelected : styles.button} 
            size="small" 
            variant={"outlined"} 
            onClick={() => onChangeHandle(EnumCardStatus.IDLE)}>
              <span 
                style={{ backgroundColor: "#FFB300 " }} 
                className={styles.boxPercente}>
                  {calculatePercentage(idle)}%
              </span>
              Ociosas
              <span>{`(${idle})`}</span>
          </Button>
        }
      </div>
    </div>
  )
};
