import { IAddressSaasRequest } from "modules/saas/domain/dtos/address/IAddressSaasRequest";
import { IAuthenticateSaasRequest } from "modules/saas/domain/dtos/authenticate/IAuthenticateSaasRequest";
import { IAuthenticateSaasResponse } from "modules/saas/domain/dtos/authenticate/IAuthenticateSaasResponse";
import { ICheckDocumentResponse } from "modules/saas/domain/dtos/checkDocument/ICheckDocumentResponse";
import { ICheckEmailResponse } from "modules/saas/domain/dtos/checkEmail/ICheckEmailResponse";
import { ICheckoutRequest } from "modules/saas/domain/dtos/checkout/ICheckoutRequest";
import { ICheckoutCieloRequest } from "modules/saas/domain/dtos/checkoutCielo/ICheckoutCieloRequest";
import { ISimpleCreateLocalRequest } from "modules/saas/domain/dtos/createLocal/ISimpleCreateLocalRequest";
import { ILeadsRequest } from "modules/saas/domain/dtos/leads/ILeadsRequest";
import { ILeadsResponse } from "modules/saas/domain/dtos/leads/ILeadsResponse";
import { ILocalSaasRequest } from "modules/saas/domain/dtos/local/ILocalSaasRequest";
import { ILocalSegmentsResponse } from "modules/saas/domain/dtos/localSegments/ILocalSegmentsResponse";
import { IGetSaasPlansResponse } from "modules/saas/domain/dtos/plans/IGetSaasPlansResponse";
import { IProfileSaasRequest } from "modules/saas/domain/dtos/profile/IProfileSaasRequest";
import { IProspectResponse } from "modules/saas/domain/dtos/prospect/IProspectResponse";
import { IRegisterSaasRequest } from "modules/saas/domain/dtos/register/IRegisterSaasRequest";
import { ResetPasswordRequest } from "modules/saas/domain/dtos/resetPassword/ResetPasswordRequest";
import { ResetPasswordResponse } from "modules/saas/domain/dtos/resetPassword/ResetPasswordResponse";
import { ISimpleCheckoutRequest } from "modules/saas/domain/dtos/simpleCheckout/ISimpleCheckoutRequest";
import { IGetTrackConfigResponse } from "modules/saas/domain/dtos/trackConfig/IGetTrackConfigResponse";
import { ISaveTrackConfigRequest } from "modules/saas/domain/dtos/trackConfig/ISaveTrackConfigRequest";
import { IValidateSaasRequest } from "modules/saas/domain/dtos/validate/IValidateSaasRequest";
import { IValidateSaasResponse } from "modules/saas/domain/dtos/validate/IValidateSaasResponse";
import { ISaasService } from "modules/saas/domain/interfaces/ISaasService";
import { api } from "../Api";

//const config: AxiosRequestConfig = {
//    baseURL: process.env.REACT_APP_PLANS_URL,
//};

const baseURL = process.env.REACT_APP_PLANS_URL;
const baseURLMS = process.env.REACT_APP_API_URL;

//const api = axios.create(config);

export const SaasApi = (): ISaasService => {
    const leads = async (request: ILeadsRequest): Promise<ILeadsResponse> => {
        const response = await api.post(`${baseURL}/leads`, request);
        return response.data;
    }

    const register = async (request: IRegisterSaasRequest): Promise<void> => {
        return await api.post(`${baseURL}/prospects`, request);
    }

    const validate = async (request: IValidateSaasRequest): Promise<IValidateSaasResponse> => {
        const response = await api.post(`${baseURL}/prospects/validate-code`, request);
        return response.data;
    }

    const authenticate = async (request: IAuthenticateSaasRequest, token: string): Promise<IAuthenticateSaasResponse> => {
        const response = await api.post(`${baseURL}/prospects/meep-user/${request.type}`, request, { headers: { "Authorization": `Bearer ${token}` } });
        return response.data;
    }

    const prospect = async (prospectId: string, key: string): Promise<IProspectResponse> => {
        const response = await api.get(`${baseURL}/prospects/${prospectId}`, { headers: { "x-api-key": key } });
        return response.data;
    }

    const saveProfile = async (request: IProfileSaasRequest, prospectId: string, key: string): Promise<void> => {
        return await api.put(`${baseURL}/prospects/${prospectId}/profile`, request, { headers: { "x-api-key": key } });
    }

    const saveLocal = async (request: ILocalSaasRequest, prospectId: string, key: string): Promise<void> => {
        return await api.put(`${baseURL}/prospects/${prospectId}/local`, request, { headers: { "x-api-key": key } });
    }

    const saveAddress = async (request: IAddressSaasRequest, prospectId: string, key: string): Promise<void> => {
        return await api.put(`${baseURL}/prospects/${prospectId}/local/address`, request, { headers: { "x-api-key": key } });
    }

    const checkEmail = async (email: string): Promise<ICheckEmailResponse> => {
        const response = await api.get(`${baseURLMS}/saas/CheckUserByEmail?email=${email}`);
        return response.data;
    }

    const checkDocument = async (document: string): Promise<ICheckDocumentResponse> => {
        const response = await api.get(`${baseURLMS}/saas/CheckUserByDocument?document=${document}`);
        return response.data;
    }

    const checkLocalDocument = async (document: string): Promise<boolean> => {
        const response = await api.get(`${baseURLMS}/saas/CheckLocalByDocument?document=${document}`);
        return response.data.exists;
    }

    const getLocalSegments = async (): Promise<ILocalSegmentsResponse[]> => {
        const response = await api.get(`${baseURLMS}/saas/LocalSegments`);
        return response.data;
    }

    const passwordRecover = async (documentOrEmail: string): Promise<ResetPasswordResponse> => {
        const response = await api.post(`${baseURLMS}/saas/account/passwordRecover`, { documentOrEmail });
        return response.data;
    }

    const resetPassword = async (request: ResetPasswordRequest): Promise<void> => {
        return await api.post(`${baseURLMS}/saas/account/resetPassword`, request);
    }

    const checkout = async (prospectId: string, request: ICheckoutRequest, key: string): Promise<void> => {
        return await api.post(`${baseURL}/prospects/${prospectId}/checkout`, request, { headers: { "x-api-key": key } });
    }

    const checkoutTrial = async (prospectId: string, key: string): Promise<void> => {
        return await api.post(`${baseURL}/prospects/${prospectId}/checkout/free-trial`, null, { headers: { "x-api-key": key } });
    }

    const checkoutCielo = async (prospectId: string, key: string): Promise<void> => {
        return await api.post(`${baseURL}/prospects/${prospectId}/checkout/cielo`, null, { headers: { "x-api-key": key } });
    }

    const checkoutCieloEvent = async (prospectId: string, key: string, request: ICheckoutCieloRequest): Promise<void> => {
        return await api.post(`${baseURL}/prospects/${prospectId}/checkout/cielo/event`, request, { headers: { "x-api-key": key } });
    }

    const checkoutCieloEstablishment = async (prospectId: string, key: string, request: ICheckoutCieloRequest): Promise<void> => {
        return await api.post(`${baseURL}/prospects/${prospectId}/checkout/cielo/establishment`, request, { headers: { "x-api-key": key } });
    }

    const getPlans = async (params: string = ''): Promise<IGetSaasPlansResponse[]> => {
        params = params === '' ? 'categoryId=1' : params;
        const response = await api.get(`${baseURL}/plans?${params}`);
        return response.data;
    }

    const deliveryAddress = async (request: IAddressSaasRequest, prospectId: string, key: string): Promise<void> => {
        return await api.put(`${baseURL}/prospects/${prospectId}/delivery/address`, request, { headers: { "x-api-key": key } });
    }

    const getTrackConfig = async (page: number, keyword?: string): Promise<IGetTrackConfigResponse> => {
        // const response = await api.get(`${baseURL}/payments/solicitations`, { params: { keyword, page } });
        // return response.data;

        return {
            currentPage: 1,
            totalPages: 2,
            totalRegisters: 25,
            data: [
                {
                    id: '1234567',
                    localName: 'Chalezinho',
                    localId: '11111',
                    solicitationDate: new Date().toISOString(),
                    trackingCode: 'CM123654789BR',
                    address: {
                        street: 'Doutor Lourival Sotto Maior',
                        number: '180',
                        complement: undefined,
                        neighborhood: 'Quintas das Avenidas',
                        city: 'Juiz de Fora',
                        state: 'MG',
                        country: 'Brasil',
                        zipCode: '36046-578',
                    },
                    status: 1
                },
                {
                    id: '1234568',
                    localName: 'Chalezinho',
                    localId: '11111',
                    solicitationDate: new Date().toISOString(),
                    trackingCode: 'CM123654789BR',
                    address: {
                        street: 'Doutor Lourival Sotto Maior',
                        number: '180',
                        complement: undefined,
                        neighborhood: 'Quintas das Avenidas',
                        city: 'Juiz de Fora',
                        state: 'MG',
                        country: 'Brasil',
                        zipCode: '36046-578',
                    },
                    status: 2
                },
                {
                    id: '1234569',
                    localName: 'Chalezinho',
                    localId: '11111',
                    solicitationDate: new Date().toISOString(),
                    trackingCode: 'CM123654789BR',
                    address: {
                        street: 'Doutor Lourival Sotto Maior',
                        number: '180',
                        complement: undefined,
                        neighborhood: 'Quintas das Avenidas',
                        city: 'Juiz de Fora',
                        state: 'MG',
                        country: 'Brasil',
                        zipCode: '36046-578',
                    },
                    status: 3
                },
            ]
        }
    }

    const saveTrackConfig = async (trackingId: string, request: ISaveTrackConfigRequest): Promise<void> => {
        return await api.put(`${baseURL}/tracking/${trackingId}`, request);
    }

    const simpleCheckout = async (request: ISimpleCheckoutRequest): Promise<void> => {
        return await api.post(`${baseURL}/prospects/basic`, request);
    }
    
    const simpleValidate = async (request: IValidateSaasRequest): Promise<IValidateSaasResponse> => {
        const response = await api.post(`${baseURL}/prospects/basic/validate-code`, request);
        return response.data;
    }

    const simpleCreateLocal = async (prospectId: string, key: string, request: ISimpleCreateLocalRequest): Promise<void> => {
        const response = await api.post(`${baseURL}/prospects/${prospectId}/basic/establishment`, request, { headers: { "x-api-key": key } });
        return response.data;
    }

    const resendCode = async (email: string): Promise<void> => {
        return await api.post(`${baseURL}/prospects/resend-validation-code `, { email });
    }

    return {
        leads,
        register,
        validate,
        authenticate,
        prospect,
        saveProfile,
        saveLocal,
        saveAddress,
        checkEmail,
        checkDocument,
        checkLocalDocument,
        getLocalSegments,
        passwordRecover,
        resetPassword,
        checkout,
        getPlans,
        deliveryAddress,
        getTrackConfig,
        saveTrackConfig,
        checkoutTrial,
        checkoutCielo,
        checkoutCieloEvent,
        checkoutCieloEstablishment,
        simpleCheckout,
        simpleValidate,
        simpleCreateLocal,
        resendCode
    }
}