import { useLocal } from "modules/local/presentation/context/LocalContext";
import { MutableRefObject, useCallback, useState } from "react";
import GeneralDashboardApi from "services/api/generalDashboard/GeneralDashboardApi";
import { IFinancialInformation } from "../interfaces/IFinancialInformation";
import { IFilterValue } from "../interfaces/IFilter";
import GetFinancialInformationUseCase from "modules/generalDashboard/application/useCases/GetFinancialInformationUseCase";
import { ICashierEvent } from "../interfaces/ICashierEvent";

const UseFinancialInformation = (filter: IFilterValue | undefined, isLoadingCashier: boolean, lastCashierOpenData: MutableRefObject<ICashierEvent | undefined>) => {

  const [isLoadingFinancialInfo, setIsLoadingFinancialInfo] = useState<boolean>(true);
  const [financialInformation, setFinancialInformation] = useState<IFinancialInformation>()
  const { currentLocal } = useLocal();

  const getFinancialInformation = useCallback(
    async () => {
      const serviceGeneral = GeneralDashboardApi();       
      if (!isLoadingCashier && currentLocal) {
        const cashierIds = filter?.cashierIds && filter.cashierIds.length > 0
          ? filter.cashierIds
          : lastCashierOpenData.current?.id
          ? [lastCashierOpenData.current.id]
          : undefined;
        
        if (!cashierIds) {
          setIsLoadingFinancialInfo(false);
          return;
        }
        const response = await GetFinancialInformationUseCase(serviceGeneral,
        currentLocal.id,     
        {
          ...filter,
          cashierIds: cashierIds,
        });

        setFinancialInformation(response);
        setIsLoadingFinancialInfo(false); 
        return response;
      }
    },
    [currentLocal, filter, isLoadingCashier, lastCashierOpenData]
  );

  return {
    financialInformation,
    isLoadingFinancialInfo,
    getFinancialInformation,
    setFinancialInformation,
    setIsLoadingFinancialInfo
  };
};

export default UseFinancialInformation;
