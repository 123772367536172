import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import FooterButtons from '../../../footerButtons/FooterButtons'
import styles from './inventoryManagementParamsForm.module.scss'
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FormControlLabel, Switch } from '@material-ui/core'
import { InputContainer } from 'modules/meepErp/presentation/components/ui/form/FormContainers';
import { IGetStorageLocalsListRequest } from 'modules/meepErp/application/dtos/storageLocals/IGetStorageLocalsListRequest';
import { getSupplyStorageLocalsUseCase } from 'modules/meepErp/application/useCases/supplyLocals/SupplyLocalsUseCase';
import { IGetStorageLocalsListResponse } from 'modules/meepErp/application/dtos/storageLocals/IGetStorageLocalsListResponse';
import { useLocal } from 'modules/local/presentation/context/LocalContext';

export interface IInventoryManagementParamsValues {
    storageLocationId: string;
    storageLocationLinked: boolean;
}

export interface IInventoryManagementFormProps {
    defaultValue?: IInventoryManagementParamsValues;
    onModified: (modified: boolean) => void;
    onSubmit: (value: IInventoryManagementParamsValues) => void
    onClickBack: (value: IInventoryManagementParamsValues) => void
}

const InventoryManagementForm: React.FC<IInventoryManagementFormProps> = ({ defaultValue, onModified, onSubmit, onClickBack }) => {
    const [values, setValues] = useState<IInventoryManagementParamsValues>(defaultValue ?? {} as IInventoryManagementParamsValues);
    const [storageLocation, setStorageLocation] = useState<IGetStorageLocalsListResponse>();
    const { currentLocal } = useLocal();
    const [error, setError] = useState('')

    useEffect(() => {
        if (defaultValue) {
            setValues(defaultValue);
        }
    }, [defaultValue]);

    const submitHandle = useCallback((ev: FormEvent<HTMLFormElement>) => {
        ev.preventDefault();

        if (values.storageLocationLinked && !values.storageLocationId) {
            setError('Por favor, selecione um local de estoque.');
            return;
        }

        setError('');
        onSubmit(values);
    }, [onSubmit, values]);

    const onChangeChecked = (value: any) => {
        setValues((prev) => ({ ...prev, storageLocationLinked: value }));
        onModified(true);
    }

    const getSupplyStorageLocation = useCallback(async (request: IGetStorageLocalsListRequest) => {
        if (!currentLocal) throw new Error("Local não econtrado!");
        const response = await getSupplyStorageLocalsUseCase(request)
        return response
    }, [currentLocal])

    useEffect(() => {
        getSupplyStorageLocation({ page: 1, pageSize: 30 }).then(setStorageLocation);
    }, [getSupplyStorageLocation])

    return (
        <form id={styles.InventoryManagementForm} onSubmit={submitHandle}>
            <div className={styles.container}>
                <FormControlLabel
                    className={styles.switch}
                    checked={values.storageLocationLinked || false}
                    onChange={(_, checked) => onChangeChecked(checked)}
                    control={<Switch color='secondary' />} label={<p>Vincular local de estoque</p>}
                />
                {values.storageLocationLinked && (
                    <InputContainer label="Local de estoque" requerid>
                        <Autocomplete
                            options={storageLocation?.items ?? []}
                            getOptionLabel={(item) => item.name}
                            fullWidth

                            onChange={(ev, value) => {
                                setValues((prev) => ({ ...prev, storageLocationId: value?.id ?? '' }));
                            }}
                            renderInput={(params) => <TextField {...params}
                                fullWidth
                                helperText={error}
                                error={!!error}
                            />}
                            value={storageLocation?.items.find((item) => item.id === values.storageLocationId) ?? null}
                        />
                    </InputContainer>
                )}


            </div>
            <>
                <FooterButtons onClickBack={() => onClickBack(values)} labelNext={"Salvar"} nextType='submit'></FooterButtons>
            </>
        </form>
    )
}
export default InventoryManagementForm