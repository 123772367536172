import GetAllMovementsUseCase from "modules/generalDashboard/application/useCases/GetAllMovementsUseCase";
import { IAllMovements } from "modules/generalDashboard/presentation/interfaces/IAllMovements";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { MutableRefObject, useCallback, useState } from "react";
import GeneralDashboardApi from "services/api/generalDashboard/GeneralDashboardApi";
import { IFilterValue } from "../interfaces/IFilter";
import { ICashierEvent } from "../interfaces/ICashierEvent";

export interface IParamsUseAllMovements {
  localId?: string,
  sessionId?: string,
  startDate?: string,
  endDate?: string  
}

const UseAllMovements = (filter: IFilterValue | undefined, isLoadingCashier: boolean, lastCashierOpenData: MutableRefObject<ICashierEvent | undefined>, isLoadingFinancialInfo: boolean | undefined) => {

  const [isLoading, setIsLoading] = useState<boolean>();
  const [allMovements, setAllMovements] = useState<IAllMovements>()
  const { currentLocal } = useLocal();

  const getAllMovements= useCallback(
    async (params?: IParamsUseAllMovements) => {
      try {
        const serviceGeneral = GeneralDashboardApi();
        setIsLoading(true);
        if (!isLoadingCashier && !isLoadingFinancialInfo && currentLocal) {
          const cashierIds = filter?.cashierIds && filter.cashierIds.length > 0
            ? filter.cashierIds
            : lastCashierOpenData.current?.id
            ? [lastCashierOpenData.current.id]
            : undefined;

          if (!cashierIds) return;
          const response = await GetAllMovementsUseCase(serviceGeneral, currentLocal.id, {
            ...filter,
            cashierIds: cashierIds,
          });
          
          setAllMovements(response);          
          return response;
        }
      } finally {
        setIsLoading(false);
      }
    },
    [currentLocal, filter, isLoadingCashier, isLoadingFinancialInfo, lastCashierOpenData]
  );

  return {
    allMovements,
    isLoading,
    getAllMovements,
    setAllMovements,
  };
};

export default UseAllMovements;
