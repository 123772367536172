import { IGetTurnoverTableResponse } from 'modules/generalDashboard/domain/dto/IGetTurnoverTableResponse';
import { IParamsUseTurnover } from 'modules/generalDashboard/presentation/hooks/UseTurnoverTable';
import { useState, useCallback } from 'react';

const UseTurnoverTableItem = (getTurnoverTable: (params?: IParamsUseTurnover) => Promise<IGetTurnoverTableResponse | undefined>) => {
  
  const [sorting, setSorting] = useState(false);

  const handleSort =  useCallback( async (sortByTable: boolean) => {  
    setSorting(true); 

    await getTurnoverTable({ sortByTable: sortByTable });

    setSorting(false);
  },[getTurnoverTable]);

  return {
    sorting,
    handleSort
  };
};

export { UseTurnoverTableItem };
