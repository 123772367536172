import { useLocal } from "modules/local/presentation/context/LocalContext";
import { MutableRefObject, useCallback, useState } from "react";
import { IFilterValue } from "../interfaces/IFilter";
import { IRankingOperators } from "../interfaces/IRankingOperators";
import GeneralDashboardApi from "services/api/generalDashboard/GeneralDashboardApi";
import GetRankingOperatorsUseCase from "modules/generalDashboard/application/useCases/GetRankingOperatorsUseCase";
import { ICashierEvent } from "../interfaces/ICashierEvent";

export interface IParamsUseRankingOperators {
  localId?: string,
  sessionId?: string,
  period?: number,
}

const UseRankingOperators = (filter: IFilterValue | undefined, isLoadingCashier: boolean, lastCashierOpenData: MutableRefObject<ICashierEvent | undefined>, isLoadingFinancialInfo: boolean | undefined) => {

  const [isLoading, setIsLoading] = useState<boolean>();
  const [rankingOperators, setRankingOperators] = useState<IRankingOperators>()
  const { currentLocal } = useLocal();

  const getRankingOperators = useCallback(
    async (params?: IParamsUseRankingOperators) => {
      try {
        const serviceGeneral = GeneralDashboardApi();

        setIsLoading(true);
        if (!isLoadingCashier && !isLoadingFinancialInfo && currentLocal) {
          const cashierIds = filter?.cashierIds && filter.cashierIds.length > 0
            ? filter.cashierIds
            : lastCashierOpenData.current?.id
            ? [lastCashierOpenData.current.id]
            : undefined;
          
          if (!cashierIds) return;

          const response = await GetRankingOperatorsUseCase(serviceGeneral,
            currentLocal.id,
            {
              ...filter,
              cashierIds: cashierIds,
            });

          setRankingOperators(response);
          return response;
        }
      } finally {
        setIsLoading(false);
      }
    },
    [currentLocal, filter, isLoadingCashier, isLoadingFinancialInfo, lastCashierOpenData]
  );

  return {
    rankingOperators,
    isLoading,
    getRankingOperators,
    setRankingOperators,
  };
};

export default UseRankingOperators;
