
import { IGetAllMovementsResponse } from "modules/generalDashboard/domain/dto/IGetAllMovementsResponse";
import { IGeneralDashboardApiService } from "modules/generalDashboard/domain/interfaces/IGeneralDashboardApiService";
import { IFilterValue } from "modules/generalDashboard/presentation/interfaces/IFilter";



const GetAllMovementsUseCase = async (service: IGeneralDashboardApiService, localId: string, filter?: IFilterValue): Promise<IGetAllMovementsResponse> => {
    const response = await service.getAllMovements(localId, filter);

    return response;
}

export default GetAllMovementsUseCase;