import { IGetPreRechargeResponse } from "modules/generalDashboard/domain/dto/IGetPreRechargeResponse";
import { IGeneralDashboardApiService } from "modules/generalDashboard/domain/interfaces/IGeneralDashboardApiService";
import { IFilterValue } from "modules/generalDashboard/presentation/interfaces/IFilter";


const GetPreRechargeUseCase = async (service: IGeneralDashboardApiService, localId: string,  filter?: IFilterValue): Promise<IGetPreRechargeResponse> => {
    const response = await service.getPreRecharge(localId, filter);

    return response;
}

export default GetPreRechargeUseCase;