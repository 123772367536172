import { Button, CircularProgress } from "@material-ui/core";
import { Autocomplete, TextField } from "@mui/material";
import { FC, FormEvent, useCallback, useEffect, useRef, useState } from "react";
import styles from "./GeneralDashFilter.module.scss";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { Refresh } from "@mui/icons-material";
import { IFilterValue } from "../../interfaces/IFilter";
import { ICashierEvent, ICashierEventList } from "../../interfaces/ICashierEvent";
import { IParamsUseCashiersEvents } from "../../hooks/UseCashiersEvents";

export interface IFilterProps {
    onSubmit: (value: IFilterValue) => void;
    cashiers: ICashierEventList | undefined;
    defaultValues?: IFilterValue;
    isLoading?: boolean;
    filterType: string;
    getCashiersByPeriod: (params?: IParamsUseCashiersEvents) => Promise<ICashierEventList | undefined>;
    setFilterType: React.Dispatch<React.SetStateAction<string>>;
}

const filterTypes = [
    {
        value: "cashier",
        description: "Caixa",
    },
    // {
    //     value: "period",
    //     description: "Período",
    // },
];

const GeneralDashFilter: FC<IFilterProps> = ({
    onSubmit,
    cashiers,
    defaultValues,
    filterType,
    setFilterType,
    isLoading,
}) => {
    const { toast } = useUi();

    const [values, setValues] = useState<IFilterValue>({
        cashierIds: [],
    });

    const [startDate, setStartDate] = useState<string>("");
    const [endDate, setEndDate] = useState<string>("");

    const refRefresh = useRef<boolean>(false);
    const refFirstLoad = useRef<boolean>(true);

    const onRefresh = useCallback(() => {
        refRefresh.current = true;
    }, []);

    useEffect(() => {
        if (defaultValues) {
            setValues(defaultValues);
        }
    }, [defaultValues]);

    useEffect(() => {
        if (cashiers?.cashiers.length && refFirstLoad.current && !values.cashierIds?.length) {
            refFirstLoad.current = false;
            setValues((prevValues) => ({
                ...prevValues,
                cashierIds: [cashiers.cashiers[0].id],
            }));
        }
    }, [cashiers, values.cashierIds]);

    const submitHandle = useCallback(
        async (ev: FormEvent<HTMLFormElement>) => {
            ev.preventDefault();

            if (filterType === "period") {
                if (startDate && endDate) {
                    const startDateObj = new Date(startDate);
                    const endDateObj = new Date(endDate);

                    const diffTime = Math.abs(endDateObj.getTime() - startDateObj.getTime());
                    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

                    if (diffDays > 30) {
                        toast("Selecione um intervalo de no máximo 30 dias.", "error");
                        return;
                    }

                    onSubmit({
                        ...values,
                        startDate: startDateObj.toISOString(),
                        endDate: endDateObj.toISOString(),
                    });
                } else {
                    toast("Selecione um intervalo de datas.", "error");
                }
            } else {
                if ((values.cashierIds?.length ?? 0) > 7) {
                    toast("Selecione no máximo 7 caixas.", "error");
                    return;
                }
                if (!values.cashierIds?.length) {
                    if (refRefresh.current) {
                        onSubmit({
                            ...values,
                        });
                        refRefresh.current = false;
                    } else {
                        toast("Selecione pelo menos um caixa.", "error");
                        return;
                    }
                } else {
                    onSubmit({
                        ...values,
                    });
                }
            }
        },
        [startDate, endDate, filterType, onSubmit, toast, values]
    );

    const changeAutoCompleteSessionHandle = useCallback(
        (selectedCashiers?: ICashierEvent[]) => {
            setValues((prev) => ({
                ...prev,
                cashierIds: selectedCashiers?.map((cashier) => cashier.id) ?? [],
            }));
        },
        []
    );

    const getSelectedSessionsCountLabel = (count: number) => {
        const firstCashier = cashiers?.cashiers.find((x) => x.id === values.cashierIds?.[0]);

        return (
            <>
                <div className={styles.selectedCashierItem}>{firstCashier?.eventName}</div>
                {count > 1 && `+${count - 1} selecionados`}
            </>
        );
    };

    return (
        <div id={styles.GeneralDashFilter}>
            <form onSubmit={submitHandle}>
                <div>
                    <Autocomplete
                        className={styles.input}
                        style={{ flex: 2, minWidth: 350 }}
                        size="small"
                        disableClearable
                        renderInput={(props) => (
                            <div className={styles.inputContainer}>
                                <span>Filtrar por</span>
                                <TextField
                                    maxRows={1}
                                    label={undefined}
                                    name="filterType"
                                    {...props}
                                />
                            </div>
                        )}
                        isOptionEqualToValue={(option, values) =>
                            option.value === values.value
                        }
                        options={filterTypes}
                        getOptionLabel={(option) => option.description}
                        onChange={(ev, value) => setFilterType(value.value)}
                        value={filterTypes.find((x) => x.value === filterType)}
                    />
                     {filterType === "cashier" && (
                    <Autocomplete
                        className={styles.input}
                        multiple
                        style={{ flex: 2, minWidth: 350 }}
                        size="small"
                        renderTags={(value, getTagProps) => {
                            if (value.length === 0) return null;

                            const firstOption = value[0];
                            const countLabel = value.length > 1 ? `+${value.length - 1}` : "";

                            return (
                                <>
                                    <span {...getTagProps({ index: 0 })}>
                                        {`${new Date(firstOption.startDate + "Z").toLocaleString()}${firstOption.endDate ? " - " + new Date(firstOption.endDate + "Z").toLocaleString() : ""}`}
                                    </span>
                                    {countLabel && <span {...getTagProps({ index: 1 })}>{countLabel}</span>}
                                </>
                            );
                        }}
                        renderInput={(props) => (
                            <div className={styles.inputContainer}>
                                <span>Filtrar por caixa</span>
                                <TextField
                                    maxRows={1}
                                    placeholder={
                                        (values.cashierIds?.length ?? 0) > 0
                                            ? ""
                                            : "Selecione um ou mais caixas"
                                    }
                                    name={"cashier"}
                                    {...props}
                                    InputLabelProps={{ shrink: false }}
                                />
                            </div>
                        )}
                        renderOption={(props, option) => {
                            return (
                                <span {...props} key={option.id}>
                                    {`${new Date(option.startDate + "Z").toLocaleString()}${option.endDate ? " - " + new Date(option.endDate + "Z").toLocaleString() : ""}`}
                                </span>
                            );
                        }}
                        isOptionEqualToValue={(option, values) =>
                            option.id === values.id
                        }
                        options={cashiers?.cashiers ?? []}
                        getOptionLabel={(item) =>
                            `${new Date(item.startDate + "Z").toLocaleString()}${item.endDate ? " - " + new Date(item.endDate + "Z").toLocaleString() : ""}`
                        }
                        onChange={(ev, value) => changeAutoCompleteSessionHandle(value)}
                        value={
                            cashiers?.cashiers.filter((cashier) =>
                                values.cashierIds?.includes(cashier.id)
                            ) || []
                        }
                    />
                    )}
                    {filterType === "period" && (
                        <div style={{ display: 'flex', gap: '8px', alignItems: 'self-end' }}>
                            <TextField
                                label="Data inicial"
                                type="date"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                                className={styles.input}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <TextField
                                label="Data final"
                                type="date"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                                className={styles.input}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </div>
                    )}
                    <div className={styles.containerButton}>
                        <Button
                            className={styles.button}
                            variant={"contained"}
                            color={"primary"}
                            type={"submit"}
                        >
                            Buscar
                            {isLoading && <CircularProgress />}
                        </Button>
                    </div>
                </div>

                <div className={styles.refreshButton}>
                    <Button
                        className={styles.button}
                        onClick={onRefresh}
                        variant="text"
                        type={"submit"}
                    >
                        <Refresh /> <u>Atualizar dados</u>
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default GeneralDashFilter;
