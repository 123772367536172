import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useState } from "react";
import GetProductsWithoutSplitUseCase from "modules/generalDashboard/application/useCases/GetProductsWithoutSplitUseCase";
import { IProductWithoutSplitResponse } from "modules/products/domain/dto/IProductWithoutSplitResponse";
import { ProductsApi } from "services/api/products/ProductsApi";

const UseProductsWithoutSplit = () => {
  const { currentLocal } = useLocal();
  const [listProductsWithoutSplit, setListProductsWithoutSplit] = useState<IProductWithoutSplitResponse | undefined>()

  const getProductsWithoutSplit = useCallback(
    async () => {
      const serviceGeneral = ProductsApi();
      if (currentLocal) {
        const response = await GetProductsWithoutSplitUseCase(serviceGeneral, currentLocal.id);

        setListProductsWithoutSplit(response);

        return response
      }
    },
    [currentLocal]
  );

  return {
    listProductsWithoutSplit,
    getProductsWithoutSplit,
  };
};

export default UseProductsWithoutSplit;
