import { useBreadcumbs } from "components/breadcumbs/BreadcumbsContext";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import SendToLocalPrinterUseCase from "modules/localPrinter/application/SendToLocalPrinterUseCase";
import React, { useCallback, useEffect, useRef, useState } from "react";
import KDSService from "services/api/KDS/KDSService";
import LocalPrinterApi from "services/api/localPrinter/LocalPrinterApi";
import KDSLocalStorage from "services/repository/kds/KDSLocalStorage";
import ChangeKDSStepUseCase from "../application/useCases/ChangeKDSStepUseCase";
import GetIfoodStatusUseCase from "../application/useCases/GetIfoodStatusUseCase ";
import GetKDSGroupedListUseCase from "../application/useCases/GetKDSGroupedListUseCase";
import GetKDSListUseCase from "../application/useCases/GetKDSListUseCase";
import GetKDSStepListUseCase from "../application/useCases/GetStepsListUseCase";
import GetStepTotalUseCase from "../application/useCases/GetStepTotalUseCase";
import GetKDSStoresListUseCase from "../application/useCases/GetStoresKdsUseCase";
import GetKDSTableListUseCase from "../application/useCases/GetTableListUseCase";
import { IssueNfeUseCase } from "../application/useCases/IssueNfeUseCase";
import VerifyUpdateOrderUseCase from "../application/useCases/VerifyUpdateOrderUseCase";
import { INFCeResponse } from "../domain/dto/nfe/NFCeResponse";
import { IKDSList } from "./components/KDSGrid/IKDSList";
import { IKDSGroupedList } from "./components/KDSGroupedList/IKDSGroupedList";
import { IKDSItem, KDSStatusStep } from "./components/KDSItem/IKDSItem";
import { KDSStepAction } from "./components/KDSItem/KDSItem";
import { IfoodStatus } from "./interfaces/IIfoodStatus";
import { IIssueNFCe } from "./interfaces/IIssueNFE";
import { IKDSFilter } from "./interfaces/IKDSFilter";
import { IKDSStep } from "./interfaces/IKDSSteps";
import { IKDSTable } from "./interfaces/IKDSTable";
import { IKDSStores } from "./interfaces/_IKDSStores";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { ISelect } from "interfaces/ISelect";
import GetDeviceListUseCase from "../application/useCases/GetDeviceListUseCase";
import UseSelectedEventStartDate from "./hooks/UseSelectedEventStartDate";
import { useParams } from "react-router-dom";
import UseManageEventPage from "modules/events/presentation/pages/manageEvent/UseManageEventPage";
import { IPrinter } from "./interfaces/IPrinter";
import GetPrinterListUseCase from "../application/useCases/GetPrinterListUseCase";

const service = KDSService();
const localStorage = KDSLocalStorage();

export interface IStepLoading {
  id: string;
  isLoading: boolean;
}

const UseKDSPage = () => {
  const { selectEventStartDate } = UseSelectedEventStartDate();

  const [filter, setFilter] = useState<IKDSFilter>({
    startDate: selectEventStartDate
      ? selectEventStartDate
      : new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
        0,
        0,
        0
      ),
    endDate: new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate(),
      23,
      59,
      59,
      999
    ),
    orientation: "desc",
    groupedByTable: false,
  });
  const { currentLocal } = useLocal();
  const [stepList, setStepList] = useState<IKDSStep[]>();
  const [storeList, setStoreList] = useState<IKDSStores[]>();
  const [tableList, setTableList] = useState<IKDSTable[]>();
  const [kdsList, setKDSList] = useState<IKDSList>();
  const [kdsGroupedList, setKDSGroupedList] = useState<IKDSGroupedList>();
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({ page: 1, pageSize: 50 });
  const [ifoodStatus, setIfoodStatus] = useState<IfoodStatus>();
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const enableRefresh = useRef(true);
  const [total, setTotal] = useState<number>(0);
  const [kdsAudioPlay, setKdsAudioPlay] = useState(true);
  const [issueNFCe, setIssueNFCe] = useState<INFCeResponse[]>([]);
  const [summaryView, setSummaryView] = useState<boolean>(false);
  const [isLoadingStep, setIsLoadingStep] = useState<IStepLoading[]>([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [deviceList, setDeviceList] = useState<ISelect[]>([]);
  const [printerList, setPrinterList] = useState<IPrinter[]>();  

  const {hideLoading, showLoading} = useUi();


  const { updateRouters } = useBreadcumbs();
  const { toast } = useUi();
  const { eventId } = useParams<{ eventId: string }>();
  const { event } = UseManageEventPage()
  const onSubmitFilter = useCallback(
    (filterValue: IKDSFilter) => {
      let startDate = filterValue.startDate
        ? new Date(filterValue.startDate)
        : "";
      let endDate = filterValue.endDate ? new Date(filterValue.endDate) : "";
      if (startDate > endDate) {
        toast("Data e hora inicial deve ser menor que a final", "error");
        return false;
      } else {
        setPagination((prev) => ({ ...prev, page: 1 }));
        setFilter((prev) => ({ ...prev, ...filterValue }));
      }
    },
    [toast]
  );

  const getPrinterList = useCallback(async () => {
    if (currentLocal) {
        try {
            showLoading();
            const response = await GetPrinterListUseCase(service, currentLocal.id)
            setPrinterList(response);
            return response;
        } catch {
            throw new Error("Erro ao buscar impressoras");
        } finally {
            hideLoading();
        }
    } else {
        throw new Error("Erro ao buscar impressoras");
    }
}, [currentLocal, hideLoading, showLoading]);

useEffect(() => {
  getPrinterList();
},[getPrinterList])

  useEffect(() => {
    const summaryViewLocalStorage = JSON.parse(
      window.localStorage.getItem("@summaryView") || "false"
    );
    setSummaryView(summaryViewLocalStorage);
  }, [setSummaryView]);

  const getDataAutocomplete = useCallback(async () => {
    try {
      if (currentLocal) {
        GetKDSStepListUseCase(service, currentLocal?.id).then((response) => {
          var newResponse = response;
          if (currentLocal.systemIdentifier === 1) {
            newResponse = response.filter((item) => item.name !== 'Em transporte' && item.name !== 'Devolvido' && item.name !== 'Pronto para retirar' && item.name !== 'Aceito');
          }
          setStepList(newResponse)
        }
        );
        GetKDSStoresListUseCase(service, currentLocal?.id).then((response) =>
          setStoreList(response)
        );
        GetKDSTableListUseCase(service, currentLocal.id).then((response) =>
          setTableList(response)
        );
      }
    } finally {
      setIsLoading(false);
    }
  }, [currentLocal]);

  const getStatusIfood = useCallback(async () => {
    if (currentLocal) {
      // setCurrentPage(page)
      try {
        setIsLoading(true);
        const response = await GetIfoodStatusUseCase(service, currentLocal?.id);
        setIfoodStatus(response);
      } finally {
        setIsLoading(false);
      }
    }
  }, [currentLocal]);

  const getStateTotalHandle = useCallback(
    async (stepId: string) => {
      if (currentLocal) {
        try {
          setIsButtonDisabled(true);
          setIsLoadingStep((prev) => [
            ...prev,
            { id: stepId, isLoading: true },
          ]);
          const service = KDSService();
          return await GetStepTotalUseCase(
            service,
            { ...filter },
            currentLocal.id,
            stepId
          );
        } finally {
          setIsLoadingStep((prev) => {
            const newSteps = [...prev];
            const index = newSteps.findIndex((item) => item.id === stepId);
            if (index !== -1) {
              newSteps[index] = { id: stepId, isLoading: false };
            }
            if (newSteps.every((item) => item.isLoading === false)) {
              return [];
            } else {
              return newSteps;
            }
          });
          setIsButtonDisabled(false);
        }
      } else {
        return null;
      }
    },
    [currentLocal, filter]
  );

  const getDeviceList = useCallback(async () => {
    try {
      setIsLoading(true);
      if (currentLocal) {
        const response = await GetDeviceListUseCase(service, currentLocal.id);
        setDeviceList(response.items);
        return response;
      }
    } finally {
      setIsLoading(false);
    }
  }, [currentLocal]);

  useEffect(() => {
    getDeviceList();
  }, [getDeviceList]);

  const getKDSList = useCallback(async () => {
    if (currentLocal && !filter.groupedByTable) {
      // setCurrentPage(page)
      try {
        setIsLoading(true);
        const response = await GetKDSListUseCase(
          service,
          localStorage,
          { ...filter, ...pagination },
          currentLocal?.id
        );
        setKDSList(response);
      } finally {
        setIsLoading(false);
      }
    }
  }, [currentLocal, filter, pagination]);

  const getKDSGroupedList = useCallback(async () => {
    if (currentLocal && filter.groupedByTable) {
      // setCurrentPage(page)
      try {
        setIsLoading(true);
        const response = await GetKDSGroupedListUseCase(
          service,
          localStorage,
          { ...filter, ...{ ...pagination } },
          currentLocal?.id
        );
        setKDSGroupedList(response);
      } finally {
        setIsLoading(false);
      }
    }
  }, [currentLocal, filter, pagination]);

  const handleSummaryView = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const check = event.target.checked;
      setSummaryView(check);
      window.localStorage.setItem("@summaryView", JSON.stringify(check));
      onSubmitFilter({ ...filter });
    },
    [filter, onSubmitFilter]
  );

  const ChangeKDSStep = useCallback(
    async (action: KDSStepAction, item: IKDSItem) => {
      if (currentLocal) {
        const response = await ChangeKDSStepUseCase(service, {
          localId: currentLocal.id,
          itemId: item.id,
          action,
        });
        onSubmitFilter({ ...filter });
        return response;
      } else {
        throw new Error("Local nao selecionado");
      }
    },
    [currentLocal, filter, onSubmitFilter]
  );

  const postIssueNfe = useCallback(
    async (item: IIssueNFCe) => {
      if (currentLocal) {
        const response = await IssueNfeUseCase(item, service);

        setIssueNFCe(response);
        return response;
      } else {
        throw new Error("Error ao buscar dados fiscais");
      }
    },
    [currentLocal]
  );

  const playAlert = useCallback(async () => {
    const audio = new Audio(
      "http://soundbible.com/mp3/Store_Door_Chime-Mike_Koenig-570742973.mp3"
    );

    const hasNewOrder =
      (kdsList?.items.filter(
        (item) => item.currentStep.name === KDSStatusStep.PENDING
      ).length ?? 0) > 0;

    const storageKdsAudioPlay = window.localStorage.getItem("@kdsAudioPlay");

    if (
      hasNewOrder &&
      (storageKdsAudioPlay == null || storageKdsAudioPlay === "true")
    ) {
      audio.play();
    }
  }, [kdsList]);

  const refresh = useCallback(async () => {
    if (currentLocal) {
      const response = await VerifyUpdateOrderUseCase(
        service,
        localStorage,
        { ...filter },
        currentLocal.id
      );
      playAlert();

      if (response) {
        getKDSList();
        getKDSGroupedList();
      }
    }
  }, [currentLocal, filter, getKDSGroupedList, getKDSList, playAlert]);

  const onChangePage = useCallback(
    (page: number, pageSize = 50) => {
      if (page !== pagination.page) {
        setKDSList(undefined);
        setKDSGroupedList(undefined);
      }
      setPagination((prev) => ({ page, pageSize }));
      playAlert();
    },
    [pagination.page, playAlert]
  );

  const sendToLocalPrinter = useCallback(
    async (item: IKDSItem): Promise<void> => {
      const service = LocalPrinterApi();
      await SendToLocalPrinterUseCase(service, item);
    },
    []
  );

  const handleShowStatusModal = () => {
    setOpenStatusModal(true);
  };
  const handleHideStatusModal = () => {
    setOpenStatusModal(false);
  };

  const audioShowPause = useCallback(() => {
    setKdsAudioPlay(false);

    window.localStorage.setItem("@kdsAudioPlay", "false");

    playAlert();
  }, [playAlert]);

  const audioShowPlay = useCallback(() => {
    setKdsAudioPlay(true);
    window.localStorage.setItem("@kdsAudioPlay", "true");
    playAlert();
  }, [playAlert]);

  useEffect(() => {
    getStatusIfood();
  }, [
    filter.groupedByTable,
    getKDSGroupedList,
    getKDSList,
    getStatusIfood,
    issueNFCe,
  ]);

  useEffect(() => {
    getKDSList();
  }, [getKDSList]);

  useEffect(() => {
    getKDSGroupedList();
  }, [getKDSGroupedList]);

  useEffect(() => {
    getDataAutocomplete();
  }, [getDataAutocomplete]);

  useEffect(() => {
    if (eventId) {
      updateRouters([
        {
          title: "Evento/Caixa",
          url: "/private/eventos/list",
        },
        {
          title: event?.name
        },
        {
          title: 'Pedidos'
        },
        {
          title: 'Gestor de pedidos',
        },
      ]);
    } else {
      updateRouters([
        {
          title: "Evento/Caixa",
          url: "/private/eventos/list",
        },
        {
          title: 'Pedidos'
        },
        {
          title: 'Gestor de pedidos',
        },
      ]);
    }

    return () => {
      updateRouters([]);
    };
  }, [eventId, updateRouters, event]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (enableRefresh.current) {
        refresh();
      }
    }, 30000);
    return () => {
      clearInterval(timer);
    };
  }, [refresh]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (kdsAudioPlay) {
        playAlert();
      }
    }, 5000);
    return () => {
      clearInterval(timer);
    };
  }, [kdsAudioPlay, playAlert]);

  return {
    onSubmitFilter,
    getKDSList,
    kdsList,
    isLoading,
    stepList,
    tableList,
    storeList,
    onChangePage,
    filter,
    pagination,
    ChangeKDSStep,
    sendToLocalPrinter,
    ifoodStatus,
    handleShowStatusModal,
    openStatusModal,
    handleHideStatusModal,
    enableRefresh,
    getStateTotalHandle,
    kdsGroupedList,
    getKDSGroupedList,
    total,
    setTotal,
    audioShowPlay,
    audioShowPause,
    kdsAudioPlay,
    postIssueNfe,
    issueNFCe,
    summaryView,
    setSummaryView,
    handleSummaryView,
    isLoadingStep,
    isButtonDisabled,
    setFilter,
    deviceList,
    printerList
  };
};

export default UseKDSPage;
