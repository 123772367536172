import React, { FC, useEffect } from 'react'
import styles from './LocalPage.module.scss'
import { FormHelperText, TextField } from '@material-ui/core'
import { UseLocalPage } from './UseLocalPage'
import InputMasked from 'components/inputMasked/InputMasked'
import { CircularProgress, MenuItem, Select } from '@mui/material'
import { UseBackPage } from '../../hooks/UseBackPage'
import SaasPublicLayout from '../SaasPublicLayout'
import { ProspectType } from 'modules/saas/domain/interfaces/ProspectTypeEnum'
import { ILocalSchema, localSchema } from 'modules/saas/domain/schema/localSchema'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import Button from 'components/ui/Button/Button'
import UseProspect from '../../hooks/UseProspect'

export interface ILocalPageProps {
    //propertys
}

const LocalPage: FC<ILocalPageProps> = () => {
    const {
        isLoading,
        defaultValues,
        segments,
        progressBarColor,
        backgroundColorTop,
        backgroundColorBottom,
        handleSubmit: submitForm,
    } = UseLocalPage();
    const { onBack } = UseBackPage();
    const { type } = UseProspect();

    const { register, handleSubmit, formState: { errors }, setValue, control, getValues, setError, clearErrors } = useForm<ILocalSchema>({
        resolver: zodResolver(localSchema),
    });

    useEffect(() => {
        setValue("establishmentDocument", defaultValues.document ?? "");
        setValue("establishmentName", defaultValues.name ?? "");
        setValue("segment", (defaultValues.segment ?? "").toString());
    }, [defaultValues, setValue]);
    
    return (
        <SaasPublicLayout
            step={type === ProspectType.CieloEvent || type === ProspectType.CieloEstablishment ? 5 : 4}
            totalSteps={type === ProspectType.CieloEvent || type === ProspectType.CieloEstablishment ? 6 : 5}
            progressBarColor={progressBarColor}
            backgroundColorTop={backgroundColorTop}
            backgroundColorBottom={backgroundColorBottom}
        >
            <div className={styles.container}>
                <div className={styles.header}>
                    <h1>Nos conte um pouco sobre seu negócio</h1>
                    <span>Iremos personalizar a sua conta!</span>
                </div>

                <form
                    onSubmit={handleSubmit((data) => submitForm(data, setError, clearErrors))}
                >
                    <div className={styles.column}>
                        <label htmlFor="establishmentDocument" className={styles.required}>CNPJ</label>
                        <Controller
                            name="establishmentDocument"
                            control={control}
                            render={({ field }) => (
                                <InputMasked
                                    name="establishmentDocument"
                                    variant="outlined"
                                    fullWidth
                                    mask="99.999.999/9999-99"
                                    placeholder="Digite seu CNPJ"
                                    value={field.value}
                                    onChange={(e: any) => field.onChange(e.target.value.replace(/\D/g, ""))}
                                    helperText={errors.establishmentDocument?.message}
                                    error={!!errors.establishmentDocument}
                                    disabled={isLoading}
                                    type="tel"
                                    overridePaste
                                />
                            )}
                        />
                    </div>

                    <div className={styles.column}>
                        <label htmlFor="establishmentName" className={styles.required}>Nome do estabelecimento</label>
                        <TextField
                            variant="outlined"
                            fullWidth
                            size="small"
                            placeholder="Digite o nome do seu estabelecimento"
                            {...register("establishmentName")}
                            error={!!errors.establishmentName}
                            helperText={errors.establishmentName?.message}
                            disabled={isLoading}
                        />
                    </div>

                    <div className={styles.column}>
                        <label htmlFor="document" className={styles.required}>Qual o seu negócio?</label>
                        <Controller
                            name="establishmentDocument"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <Select
                                        {...field}
                                        style={{ width: "100%" }}
                                        size="small"
                                        placeholder="Selecione o seu negócio"
                                        {...register("segment")}
                                        value={getValues("segment")}
                                        disabled={isLoading}
                                        error={!!errors.segment}
                                    >
                                        {
                                            segments.map(item => (
                                                <MenuItem value={item.id.toString()} key={item.id}>
                                                    {item.description}
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                    {!!errors.segment && <FormHelperText className={styles.error} error>{errors.segment.message}</FormHelperText>}
                                </>
                            )}
                        />
                    </div>

                    <div className={styles.footerButtons}>
                        <Button variant="text" color="secondary" onClick={() => onBack("profile")} style={{ color: backgroundColorTop }}>
                            Voltar
                        </Button>
                        <Button
                            color="secondary"
                            style={{ backgroundColor: backgroundColorTop }}
                            type="submit"
                        >
                            {isLoading ? <CircularProgress size={16} color="inherit" /> : "Continuar"}
                        </Button>
                    </div>
                </form>
            </div>
        </SaasPublicLayout>
    )
}
export default LocalPage