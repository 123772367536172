import React, {
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from "react";
import { toast } from "react-toastify";
import SendToSocketPrinterUseCase from "../../application/SendToSocketPrinterUseCase";
import html2canvas from "html2canvas";
import ReactDOM from "react-dom";
import ReactDOMServer from "react-dom/server";
import MqttService from "services/webSocketMqtt/MqttService";
import { UseLocalStore } from "modules/local/presentation/store/UseLocalStore";
import IntegrationApi from "services/api/config/integration/IntegrationApi";
import GetHubByOwnerIdUseCase from "modules/config/integrationsConfig/application/useCases/GetHubByOwnerIdUseCase";
const UseSocketPrinter = () => {
  const [currentLocal] = UseLocalStore((state) => [state.currentLocal]);
  const [hubKey, setHubKey] = useState<string>();

  const [isLoadingSocketPrinter, setIsLoadingSocketPrinter] =
    useState<boolean>(false);

  useEffect(() => {
    const getHubKey = async () => {
      if (!currentLocal) throw new Error("Local não encontrado");
      const service = IntegrationApi();
      const response = await GetHubByOwnerIdUseCase(service, currentLocal.id);
      setHubKey(response.key);
    };
    getHubKey();
  }, [currentLocal]);

  //Metodo para enviar para impressora
  const sentToPrinter = useCallback( async (id: string, printerName: string, html?: string, base64?: string) => {
      try {
        setIsLoadingSocketPrinter(true);
        const socket = MqttService();
        if (!hubKey) {
          toast.error("Hub não encontrado");
          return;
        }
        await SendToSocketPrinterUseCase(socket, hubKey, {    
            id: id,
            printerName,
            base64,
            html,
          }
        );
        return "ok";
      } finally {
        setIsLoadingSocketPrinter(false);
      }
    },
    [hubKey]
  );

  const htmlToBase64 = useCallback(async (html: ReactElement) => {
    const element = document.createElement("div");
    ReactDOM.render(html, element);

    document.body.appendChild(element);
    await new Promise((resolve) => setTimeout(resolve));

    const canvas = await html2canvas(element, {
      width: 200,
      allowTaint: false,
      useCORS: true,
    });
    document.body.style.overflow = "hidden";
    document.body.removeChild(element);

    const base64Image = canvas.toDataURL("image/png");
    console.log("base64Image", base64Image);
    return base64Image;
  }, []);

  const getElementHtml = useCallback(async (html: ReactElement) => {
    await new Promise((resolve) => setTimeout(resolve));
    const htmlString = ReactDOMServer.renderToStaticMarkup(html);
    // const element = document.createElement('div');
    // element.innerHTML = htmlString;
    // document.body.appendChild(element);
    return htmlString;
  }, []);

  return {
    htmlToBase64,
    getElementHtml,
    sentToPrinter,
    isLoadingSocketPrinter,
  };
};

export default UseSocketPrinter;
