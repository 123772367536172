import { Icon, Tab, Tabs } from '@material-ui/core'
import styles from './TabView.module.scss'
export interface ITabViewProps<T extends { icon?: string, label?: string; disabled?: boolean;[key: string]: any; }> {
    tabs: (T)[]
    onChangeTab?: (tab: T, index: number,) => void
    defaultIndex?: number
    index?: number;
    disabled?: boolean
    scrollButton?: "auto" | "on" | "off" | "desktop" | undefined;
}

const TabView = <T extends { icon?: string, label?: string; disabled?: boolean;[key: string]: any; },>({ index, onChangeTab, tabs, disabled, scrollButton }: ITabViewProps<T>) => {
    return (
        <Tabs
            classes={{
                indicator: styles.indicator,
                scroller: styles.scroller,
                root: styles.root,
            }}
            onChange={(_, value) => onChangeTab?.(tabs[value], value)}
            value={index}
            TabIndicatorProps={{}}
            // indicatorColor="primary"
            textColor="inherit"
            variant="scrollable"
            aria-label="full width tabs example"
            scrollButtons={scrollButton}
            className="device-profile-tab-view"
        >
            {
                tabs?.map((tabItem, index) => (
                    <Tab key={index}
                        label={tabItem.label}
                        disabled={tabItem.disabled}
                        icon={tabItem.icon && (<Icon>{tabItem.icon}</Icon>)}
                        classes={{
                            root: styles.tab,
                            selected: styles.selected,
                        }}
                        style={{ display: tabItem.hidden ? "none" : "flex" }}
                    />
                ))
            }
        </Tabs>
    )
}
export default TabView