import { useHistory } from "react-router-dom";

import Layout from "components/layout/presentation/Layout";
import SaasPlanList from "components/saasPlansOverlay/SaasPlanList";
import styles from "./PlanLimitationWarning.module.scss";
import Button from "components/ui/Button/Button";
import { UseSaasPaymentPage } from "modules/saasPayment/presentation/hooks/UseSaasPaymentPage";
import { useEffect, useState } from "react";
import SimpleTrialModal from "modules/plans/presentation/components/simpleTrialModal/SimpleTrialModal";

const PlanLimitationWarning = () => {
    const [openSimpleTrialModal, setOpenSimpleTrialModal] = useState(false);

    const history = useHistory();
    const {
        selectedType,
        setSelectedType,
        selectedPlan,
        setSelectedPlan,
        plans,
        localDocument,
    } = UseSaasPaymentPage(true);

    useEffect(() => {
        if (localDocument === "28302604000101") {
            return setOpenSimpleTrialModal(true);
        }
    }, [localDocument]);

    return (
        <Layout>
            <div className={styles.saasPlanLimitation} style={{ backgroundImage: 'url(/assets/img/bg-plan-limitation-warning.png)' }}>
                <p className={styles.sassPlanLimitationTitle}>Para ter <span className={styles.highlight}>acesso</span> a essa página é necessário <span className={styles.highlight}>assinar</span> um de nossos planos</p>

                <SaasPlanList
                    isCieloCustomer={false}
                    plans={plans}
                    selectedMode={selectedType}
                    selectedPlan={selectedPlan}
                    selectModeFn={setSelectedType}
                    selectPlanFn={setSelectedPlan}
                />

                <div className={styles.saasPlansButtonContainer}>
                    <Button onClick={() => history.push('/private/saas/payment')}>Contratar plano</Button>
                </div>
            </div>

            <SimpleTrialModal open={openSimpleTrialModal} onClose={() => setOpenSimpleTrialModal(false)} redirectToCheckout />
        </Layout>
    );
}

export default PlanLimitationWarning;