import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useRef, useState } from "react";
import GeneralDashboardApi from "services/api/generalDashboard/GeneralDashboardApi";
import { IFilterValue } from "../interfaces/IFilter";
import { ICashierEvent, ICashierEventList } from "../interfaces/ICashierEvent";
import GetCashiersEventListUseCase from "modules/generalDashboard/application/useCases/GetCashiersEventListUseCase";

export interface IParamsUseCashiersEvents {
  keyword?: string, 
  page?: number, 
  pageSize?: number
}

const UseCashiersEvents = (filter: IFilterValue | undefined) => {

  const [isLoadingCashier, setIsLoadingCashier] = useState<boolean>(true);
  const isLoadingCashierCurrent = useRef<boolean>(true);
  const [cashierEvents, setCashiersEvents] = useState<ICashierEventList>()
  const { currentLocal } = useLocal();
  const lastCashierOpenData = useRef<ICashierEvent>();

  const getCashiersEvents= useCallback(
    async (params?: IParamsUseCashiersEvents) => {
      try {
        const serviceGeneral = GeneralDashboardApi();
        if (currentLocal) {
          const response = await GetCashiersEventListUseCase(serviceGeneral,
            currentLocal.id,
            filter,
          );

          lastCashierOpenData.current = response.cashiers[0]
          setCashiersEvents(response);

          return response
        }
      } finally {
        setIsLoadingCashier(false);
        isLoadingCashierCurrent.current = false;

      }
    },
    [currentLocal, filter]
  );


  return {
    cashierEvents,
    isLoadingCashier,
    getCashiersEvents,
    setCashiersEvents,
    lastCashierOpenData,
    isLoadingCashierCurrent,
    setIsLoadingCashier
  };
};

export default UseCashiersEvents;
