import { IGetPosPaidResponse } from "modules/generalDashboard/domain/dto/IGetPosPaidResponse";
import { IGeneralDashboardApiService } from "modules/generalDashboard/domain/interfaces/IGeneralDashboardApiService";
import { IFilterValue } from "modules/generalDashboard/presentation/interfaces/IFilter";


const GetPosPaidUseCase = async (service: IGeneralDashboardApiService, localId: string,  filter?: IFilterValue): Promise<IGetPosPaidResponse> => {
    const response = await service.getPosPaid(localId, filter);

    return response;
}

export default GetPosPaidUseCase;