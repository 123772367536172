import { IGetSalesCategoriesResponse } from "modules/generalDashboard/domain/dto/IGetSalesCategoriesResponse";
import { IGeneralDashboardApiService } from "modules/generalDashboard/domain/interfaces/IGeneralDashboardApiService";
import { IFilterValue } from "modules/generalDashboard/presentation/interfaces/IFilter";

const GetSalesCategoriesUseCase = async (service: IGeneralDashboardApiService, localId: string,  filter?: IFilterValue): Promise<IGetSalesCategoriesResponse> => {

    const response = await service.getSalesCategories(localId, filter);

    return response;

}

export default GetSalesCategoriesUseCase;