import { CircularProgress, Drawer } from "@mui/material";
import styles from "./Sidesheet.module.scss";
import { ReactElement, ReactNode } from "react";
import { ArrowBackIosNew, CloseRounded } from "@mui/icons-material";
import UseDimension from "components/dimension/UseDimension";

export interface ISidesheetProps {
  open: string | boolean | null;
  onClose: () => void;
  title: ReactElement | string | ReactNode; // h2
  arrowBack?: boolean;
  handleArrowBack?: () => void;
  notTotalHeight?: boolean;
  content?: ReactElement | null;
  currentStep?: number;
  totalSteps?: number;
  cancelButton?: boolean;
  continueButton?: string;
  handleContinueButton?: () => void;
  feedback?: ReactElement;
  isLoading?: boolean;
  tabView?: JSX.Element;
  disableContinueButton?: boolean;
  showSteps?: boolean;
  customizedCancelButton?: string;
  handleCustomizedCancelButton?: () => void;
  styles?: {
    buttonContainer?: string;
  }
  children?: ReactElement;
}

const Sidesheet = ({
  open,
  onClose,
  title,
  arrowBack,
  handleArrowBack,
  notTotalHeight = false,
  content,
  currentStep = 0,
  totalSteps = 1,
  cancelButton,
  continueButton,
  handleContinueButton,
  feedback,
  isLoading,
  tabView,
  disableContinueButton,
  showSteps,
  customizedCancelButton,
  handleCustomizedCancelButton,
  children
}: ISidesheetProps) => {
  const { isMobile } = UseDimension();

  return (
    <Drawer anchor="right" style={{ zIndex: 1300 }} open={!!open} onClose={onClose}>
      <div id={styles.configContainer}>
        <div
          className={`${styles.configBox} ${isMobile && styles.configBoxMobile
            }`}
        >
          <div className={styles.configTitle}>
            {arrowBack ? (
              <div className={styles.creditCardArrowBack}>
                <div className={styles.flexCenter} onClick={handleArrowBack}>
                  <ArrowBackIosNew className={styles.closeIcon} style={{ width: "18px" }} />
                </div>
                {title}
              </div>
            ) : (
              <>{title}</>
            )}
            <div className={styles.flexCenter} onClick={onClose} style={{ marginLeft: "10px" }}>
              <CloseRounded className={styles.closeIcon} />
            </div>
          </div>
          {tabView && <div style={{ width: "100%" }}>{tabView}</div>}
          <div
            className={`${styles.configContent} ${isMobile && styles.configContentMobile
              }`}
            style={{ height: notTotalHeight ? "" : "100%" }}
          >
            {isLoading ? (
              <div className={styles.loading}>
                <CircularProgress  size={16} color="inherit" />
              </div>
            ) : currentStep < totalSteps || !feedback ? (
              children ?? content
            ) : (
              feedback
            )}
          </div>
          <div
            className={`${styles.configFooter} ${showSteps &&
              (currentStep < totalSteps || !feedback) &&
              styles.configFooterSteps
              }`}
            style={continueButton && handleContinueButton ? {} : { display: "none" }}
          >
            {showSteps && (currentStep < totalSteps || !feedback) && (
              <span className={styles.footerSteps}>
                {isMobile ? "" : "Passo"} {currentStep}/{totalSteps - 1}
              </span>
            )}
            <div
              className={`${styles.configFooterButtons} ${isMobile && styles.configFooterButtonsMobile
                }`}
            >
              {cancelButton && (currentStep < totalSteps || !feedback) && (
                <button onClick={handleCustomizedCancelButton ?? onClose} className={styles.buttonMobile}>
                  {customizedCancelButton ?? "Cancelar"}
                </button>
              )}
              <button
                className={`${styles.filledButton} ${isMobile && styles.buttonMobile
                  } ${disableContinueButton && styles.buttonDisabled}`}
                onClick={
                  currentStep < totalSteps || !feedback
                    ? handleContinueButton
                    : onClose
                }
                disabled={disableContinueButton}
              >
                {isLoading ? (
                  <CircularProgress size={16} color="inherit" />
                ) : currentStep < totalSteps || !feedback ? (
                  continueButton
                ) : (
                  "Fechar"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default Sidesheet;
