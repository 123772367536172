import { IKDSService } from "modules/kds/domain/interfaces/IKDSService";
import { IPrinter } from "modules/kds/presentation/interfaces/IPrinter";

const GetPrinterListUseCase = async (service: IKDSService, localClienteId: string):Promise<IPrinter[]> => {

    const response = await service.getPrinterList(localClienteId);
    return response
}

export default GetPrinterListUseCase;



