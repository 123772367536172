import { Icon } from '@material-ui/core';
import styles from './TurnoverTableItem.module.scss';
import { IGetTurnoverTableResponse } from 'modules/generalDashboard/domain/dto/IGetTurnoverTableResponse';
import { ITurnoverTable, ITurnoverTableItem } from '../../interfaces/ITurnoverTable';
import { IParamsUseTurnover } from 'modules/generalDashboard/presentation/hooks/UseTurnoverTable';
import { UseTurnoverTableItem } from '../../UseTurnoverTableItem';
import { Skeleton } from '../../../skeleton/Skeleton';

interface ITurnoverTableItemProps {
  getTurnoverTable: (params?: IParamsUseTurnover) => Promise<IGetTurnoverTableResponse | undefined>;
  turnoverItems: ITurnoverTableItem[];
  averageTurnover: ITurnoverTable | undefined;
  isLoading: boolean | undefined;
  handleSort: (sortByTable: boolean) => Promise<void>
  sorting: boolean
}

const TurnoverTableItem: React.FC<ITurnoverTableItemProps> = ({ handleSort, turnoverItems, averageTurnover, isLoading, sorting }) => {

 
  return (
    <div id={styles.TurnoverTableItem}>
      <div className={styles.header}>
        <span>Média de giro: <span>{averageTurnover?.averageTurnaround ?? 0}</span></span>
        <button onClick={() => handleSort(true)}>Ordenar mesas <Icon>swap_vert</Icon></button>
        <button onClick={() => handleSort(false)}>Ordenar giro <Icon>swap_vert</Icon></button>
      </div>
      <div className={styles.cardsContainer}>
        {
          (isLoading || sorting) ? (
            <Skeleton
              containerStyle={styles.skeletonContainerStyle}
              cardStyle={styles.skeletonCardStyle}
            />
          ) : (
            averageTurnover?.tableToration?.length && averageTurnover?.tableToration?.length > 0 ? (
              <>
                {averageTurnover?.tableToration.map(item => (
                  <div key={item.tableNumber} className={styles.card}>
                    <div className={styles.header}>
                      {item.tableNumber}
                    </div>
                    <div className={styles.footer}>
                      <span>Giro</span>
                      <span>{item.rotation}</span>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <div className={styles.noResults}>
                Nenhum resultado encontrado
              </div>
            )
          )
        }
      </div>
    </div>
  );
};

export { TurnoverTableItem };
