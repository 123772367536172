import { FC } from 'react'
import RankingList from '../rakingList/RankingList';
import { moneyMaskNumber } from 'services/utils/Money';
import { useUi } from 'contexts/userInterface/UserInterfaceContext';
import { ITopCategories } from '../../interfaces/ISalesCategories';

export interface ISalesCategoriesProps {
   categories: ITopCategories[] | undefined
   isLoading: boolean | undefined
}

const RankingCategories: FC<ISalesCategoriesProps> = ({ 
    categories, 
    isLoading, 
}) => {

    const {isMobile} = useUi();

    return (
        <div>
            <RankingList
                list={categories}
                isLoading={isLoading}
                colunms={{
                    categoryName: { title: "Nome da categoria" },
                    // averageTicketPerPerson: { title: "Ticket médio pessoa", hide: isMobile },
                    // comandaAverageTicket: { title: "Ticket médio comanda", hide: isMobile },
                    value: { title: "Total", formatValue: (value) => moneyMaskNumber(value) },
                }}
            />
        </div>
    )
}
export default RankingCategories