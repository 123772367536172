import { INFCeResponse } from "modules/kds/domain/dto/nfe/NFCeResponse";
import React from 'react';

interface Props {
    invoice: INFCeResponse;
    width?: number;
    onLoad?: () => void;
    qrCode?: string;
}

const InvoicePrinterLayout: React.FC<Props> = ({ invoice, width, onLoad, qrCode }) => {
    // Inline styles converted from SCSS
    const styles = {
        layout: {
            maxWidth: '250px',
            minWidth: '250px',
            overflow: 'visible',
            textAlign: 'center' as 'center',
            zIndex: 1,
            backgroundColor: '#666',
            fontSize: '16px',
        },     
        marginBlock: {
        },
        qrCodeStyle: {
            position: 'relative' as 'relative',
            background: '#1ac',
        },
        separatorLine: {
            width: `${invoice.sefaz.codigo}px`,
            height: '1px',
            margin: '20px auto',
            background: '#000',
        }
    };
    

    return (
        <div style={styles.layout}>
            <b>{invoice.razaosocial}</b>
            <div>CNPJ:  <b>{invoice.cnpj}</b></div>
            <div>Razão Social: <b> {invoice.razaosocial}</b></div>
            <div>DANFE NFC-e - Documento Auxiliar da Nota Fiscal do Consumidor Eletrônica</div>
            {/* <div>VALOR: R$ {invoice.totalValue?.toFixed(2)}</div> */}
            <div style={styles.marginBlock}>
                <div><b>Produtos:</b></div>
                {invoice.itens.map(item => (
                    <div key={item.Uuid}>{item.Quantidade} x {item.ProdutoNome} {item.ValorUnitario.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</div>
                ))}
            </div>
            <div>Consumidor:<b>{invoice.cliente}</b> </div>
            <div>NFC-e: {invoice.numero} Serie: {invoice.serie}</div>
            <div>Data: {invoice.sefaz["data-emissao"]?.toLocaleString()}</div>
            <div>Consulte pela chave de acesso no SEFAZ do seu estado ou QRCode</div>
            <div style={{ marginBottom: "16px", wordBreak: 'break-word' }}>{invoice.sefaz.chave}</div>
            <img src={qrCode} alt="QRCode"  style={{ width: "120px", height: '90px' }} />
            {/* <QRCode onLoad={onLoad} renderAs="canvas" value={invoice.sefaz["url-danfe"]} size={200} style={styles.qrCodeStyle} /> */}
            <div>NOTA EM CONTIGÊNCIA</div>
            <div>Protocolo de autorização: {invoice.sefaz.protocolo}</div>
            <div style={styles.separatorLine} />
        </div>
    );
};

export default InvoicePrinterLayout;
