import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import styles from './InvoiceSupplyForm.module.scss'
import { IInvoiceSupplySchema, InvoiceSupplyValues } from 'modules/meepErp/presentation/components/invoices/IInvoiceFormValue';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box, ButtonContainer, Container, InputContainer, Row } from 'modules/meepErp/presentation/components/ui/form/FormContainers';
import { useForm } from 'react-hook-form';
import { Button, TextField } from '@material-ui/core';
import TextFieldMoney from 'modules/meepErp/presentation/components/ui/TextField/TextFieldMoney';
import { IGetSupplyListRequest } from 'modules/meepErp/application/dtos/supply/IGetSupplyListRequest';
import AutocompleteInput from 'modules/meepErp/presentation/components/autocomplete/AutocompleteInput';
import { IGetSupplyItemResponse, IGetSupplyListResponse } from 'modules/meepErp/application/dtos/supply/IGetLSupplyListResponse';
import { IGetUnitItemResponse } from 'modules/meepErp/application/dtos/unit/IGetLUnitListResponse';
import Autocomplete from '@material-ui/lab/Autocomplete'; import { moneyMaskNumber } from 'services/utils/Money';
import { IGetStorageLocalsListRequest } from 'modules/meepErp/application/dtos/storageLocals/IGetStorageLocalsListRequest';
import { IGetStorageLocalsListResponse } from 'modules/meepErp/application/dtos/storageLocals/IGetStorageLocalsListResponse';
import { IGetQuantityStockResponse } from 'modules/meepErp/application/dtos/invoice/IGetQuantityStockResponse';
import { OperationsTypes } from 'modules/meepErp/models/operations/_OperationsType';
;

export interface IInvoiceSupplyFormProps {
    //propertys
    defaultValues?: InvoiceSupplyValues;
    onSubmit: (values: InvoiceSupplyValues) => void;
    getSupplyList: (request: IGetSupplyListRequest) => Promise<IGetSupplyListResponse>
    getSupply: (supplyId: string) => Promise<IGetSupplyItemResponse>
    getSupplyStorageLocation: (request: IGetStorageLocalsListRequest) => Promise<IGetStorageLocalsListResponse>
    fees?: number;
    totalsOfOtherSupplies?: number;
    onClickCancel?: () => void;
    getQuantityStock: (storageLocationId: string, supplyId: string) => Promise<IGetQuantityStockResponse>
    operationName?: string
}
const InvoiceSupplyForm: FC<IInvoiceSupplyFormProps> = ({
    defaultValues,
    onSubmit,
    getSupplyList,
    getSupplyStorageLocation,
    getSupply,
    fees = 0,
    onClickCancel,
    getQuantityStock,
    totalsOfOtherSupplies,
    operationName
}) => {
    const { register, handleSubmit, setValue, reset, watch, formState } = useForm<InvoiceSupplyValues>({
        resolver: zodResolver(IInvoiceSupplySchema),
        defaultValues: defaultValues ?? {
            unitPrice: 0,
            quantity: 1,
            icms: 0,
            confins: 0,
            pis: 0,
            st: 0,
            ipi: 0,
            discountPercentage: 0,
            discountValue: 0,
            supplyName: '',
            measurementUnitId: '',
            measurementUnitSymbol: '',
        },
    });


    const [selectedSupply, setSelectedSupply] = useState<IGetSupplyItemResponse>();
    const [supply, setSupply] = useState<IGetSupplyItemResponse>();
    const [supplyStorageLocation, setSupplyStorageLocation] = useState<IGetStorageLocalsListResponse>();


    const getSupplyListHandler = useCallback(async (request: IGetSupplyListRequest) => {
        return await getSupplyList(request)
    }, [getSupplyList])

    const getSupplyStorageLocationHandler = useCallback(async (request: IGetStorageLocalsListRequest) => {
        return await getSupplyStorageLocation(request)
    }, [getSupplyStorageLocation])

    const getSupplyHandler = useCallback(async (supplyId: string) => {
        return await getSupply(supplyId)
    }, [getSupply])

    useEffect(() => {
        if (!selectedSupply?.id)
            return;
        getSupplyHandler(selectedSupply.id).then(setSupply)
    }, [getSupplyStorageLocationHandler, getSupplyHandler, selectedSupply])

    useEffect(() => {
        getSupplyStorageLocationHandler({ page: 1, pageSize: 30 }).then(setSupplyStorageLocation);
    }, [selectedSupply, getSupplyStorageLocationHandler])

    useEffect(() => {
        if (!defaultValues) return;
        reset({ ...defaultValues });
        setSelectedSupply({ id: defaultValues.supplyId, name: defaultValues.supplyName, code: defaultValues.supplyCode } as IGetSupplyItemResponse);
        if (defaultValues.supplyId)
            getSupplyHandler(defaultValues.supplyId).then(setSupply)
        getSupplyStorageLocationHandler({ page: 1, pageSize: 30 }).then(setSupplyStorageLocation)
    }, [defaultValues, getSupplyHandler, getSupplyStorageLocationHandler, reset])


    const supplyUnits: IGetUnitItemResponse[] = useMemo(() => {
        if (supply) {
            const mainUnit = { id: supply.measurementUnitId, symbol: supply.measurementUnitSymbol }
            const conversionUnits = supply.conversions.map(conversion => {
                return { id: conversion.measurementUnitId, symbol: conversion.measurementUnitSymbol }
            })
            return [mainUnit, ...conversionUnits].filter((unit): unit is IGetUnitItemResponse => unit !== undefined);
        }
        return [];
    }, [supply]);



    const values = watch();
    const total = useMemo(() => {
        const subtotal = values.quantity * values.unitPrice;
        const totalTaxes = values.icms + values.confins + values.pis + values.st + values.ipi;
        const totalDiscount = subtotal * values.discountPercentage / 100 + (values.discountValue ?? 1);
        return subtotal + totalTaxes - totalDiscount;
        // return subtotal + totalTaxes - totalDiscount;
    }, [values]);



    const getSupplyAutoComplete = (request: any) => {
        const keyword = request.keyword;

        // Verifica se o keyword é um número ou uma letra
        const isNumber = /^\d+$/.test(keyword);

        return getSupplyListHandler({
            ...request,
            [isNumber ? 'code' : 'name']: isNumber ? Number(keyword) : keyword,
        });
    };

    const supplyIdValue = watch('supplyId');
    const storageLocationIdValue = watch('storageLocationId');

    const getQuantity = useCallback(async () => {
        const response = await getQuantityStock(storageLocationIdValue, supplyIdValue);
        setValue('minStock', response.quantity);
    }, [getQuantityStock, setValue, storageLocationIdValue, supplyIdValue])

    useEffect(() => {
        if (supplyIdValue && storageLocationIdValue) {
            getQuantity()
        }
    }, [supplyIdValue, storageLocationIdValue, getQuantity])

    const feesValue = useMemo(() => (total + ((fees / (total + (totalsOfOtherSupplies ?? 0))) * total)), [fees, total, totalsOfOtherSupplies])
    return (<form onSubmit={handleSubmit(onSubmit)} className={styles.InvoiceSupplyForm}>
        <Container>
            <Row>
                <InputContainer label="Insumo">
                    <AutocompleteInput
                        getList={(request) => getSupplyAutoComplete(request)}
                        onSelect={(item) => {
                            if (!item)
                                return;
                            setValue("supplyId", item.id);
                            setValue("supplyName", item.name);
                            setValue("supplyCode", item.code);
                            setSelectedSupply(item)
                        }}
                        value={selectedSupply}
                        textInput={watch("supplyName")}
                        getOptionLabel={(item) => item.code}
                        getOptionDescription={(item) => item.name}
                        error={!!formState.errors.supplyId}
                        helperText={formState.errors.supplyId?.message}
                    />
                </InputContainer>
            </Row>
            <Row>
                <InputContainer label="Local de estoque">
                    <Autocomplete
                        options={supplyStorageLocation?.items ?? []}
                        getOptionLabel={(item) => item.name}
                        fullWidth

                        onChange={(ev, value) => {
                            value && setValue("storageLocationId", value?.id)
                            value && setValue("storageLocationName", value?.name)
                        }}
                        renderInput={(params) => <TextField {...params}
                            fullWidth
                            helperText={formState.errors.storageLocationId?.message}
                            error={!!formState.errors.storageLocationId}
                        />}
                        value={supplyStorageLocation?.items.find((item) => item.id === watch("storageLocationId")) ?? null}
                    />
                </InputContainer>
            </Row>
            <Row>
                <InputContainer label="Quantidade em Estoque">
                    <TextField
                        value={watch('minStock') ?? 0}
                        disabled
                    />
                </InputContainer>
                <InputContainer label="Custo Unitário">
                    <TextFieldMoney
                        value={watch("unitPrice")}
                        onChange={(ev) => {
                            setValue("unitPrice", Number(ev.target.value))
                        }}
                        helperText={formState.errors.unitPrice?.message}
                        error={!!formState.errors.unitPrice}
                    />
                </InputContainer>
            </Row>
            <Row>
                <InputContainer label="Quantidade">
                    <TextField
                        {...register("quantity", {
                            valueAsNumber: true,
                        })}
                        type="number"
                        inputProps={{ min: 0, step: 0.000001, }}
                        variant="outlined"
                        size="small"
                        fullWidth
                    />
                </InputContainer>
                <InputContainer label="Unidade de Medida">
                    <Autocomplete
                        options={supplyUnits}
                        size={'small'}
                        fullWidth
                        getOptionLabel={(item) => item.symbol}
                        onChange={(ev, value) => {
                            value && setValue("measurementUnitId", value?.id)
                            value && setValue("measurementUnitSymbol", value?.symbol)
                        }}
                        renderInput={(params) => <TextField {...params}
                            fullWidth
                            helperText={formState.errors.measurementUnitId?.message}
                            error={!!formState.errors.measurementUnitId}
                        />}
                        value={supplyUnits.find((item) => item.id === watch("measurementUnitId")) ?? null}
                    />
                </InputContainer>
            </Row>
            {(operationName !== OperationsTypes.ENTRY_REQUIREMENT && operationName !== OperationsTypes.EXIT_REQUEST) &&
                <>
                    <Row>
                        <InputContainer label="ICMS">
                            <TextFieldMoney
                                value={watch("icms")}
                                onChange={(ev) => setValue("icms", Number(ev.target.value))}
                                helperText={formState.errors.icms?.message}
                                error={!!formState.errors.icms}
                            />
                        </InputContainer>
                        <InputContainer label="COFINS">
                            <TextFieldMoney
                                value={watch("confins")}
                                onChange={(ev) => setValue("confins", Number(ev.target.value))}
                                helperText={formState.errors.confins?.message}
                                error={!!formState.errors.confins}
                            />
                        </InputContainer>
                        <InputContainer label="PIS">
                            <TextFieldMoney
                                value={watch("pis")}
                                onChange={(ev) => setValue("pis", Number(ev.target.value))}
                                helperText={formState.errors.pis?.message}
                                error={!!formState.errors.pis}
                            />
                        </InputContainer>
                    </Row>
                    <Row>
                        <InputContainer label="ST">
                            <TextFieldMoney
                                value={watch("st")}
                                onChange={(ev) => setValue("st", Number(ev.target.value))}
                                helperText={formState.errors.st?.message}
                                error={!!formState.errors.st}
                            />
                        </InputContainer>
                        <InputContainer label="IPI">
                            <TextFieldMoney
                                value={watch("ipi")}
                                onChange={(ev) => setValue("ipi", Number(ev.target.value))}
                                helperText={formState.errors.ipi?.message}
                                error={!!formState.errors.ipi}
                            />
                        </InputContainer>

                    </Row>
                    <Row>
                        <InputContainer label="Desconto (%)">
                            <TextField
                                {...register("discountPercentage", { valueAsNumber: true })}
                                type="number"
                                variant="outlined"
                                size="small"
                                fullWidth
                            />
                        </InputContainer>
                        <InputContainer label="Desconto (R$)">
                            <TextFieldMoney
                                value={watch("discountValue")}
                                onChange={(ev) => setValue("discountValue", Number(ev.target.value))}
                                helperText={formState.errors.discountValue?.message}
                                error={!!formState.errors.discountValue}
                            />
                        </InputContainer>
                    </Row>
                </>
            }
            <Row>
                <Box>
                    <div>
                        <label>Custo</label>
                        <span>{Number.isNaN(feesValue) ? "-" : moneyMaskNumber(feesValue)}</span>
                    </div>
                    <div>
                        <label>Valor Total</label>
                        <span>{Number.isNaN(total) ? "-" : moneyMaskNumber(total)}</span>
                    </div>
                </Box>
            </Row>
            <ButtonContainer >
                {onClickCancel && <Button onClick={onClickCancel} variant="outlined" color="secondary">Cancelar</Button>}
                <Button disabled={formState.isSubmitting} type="submit" variant="contained" color="primary">Confirmar</Button>
            </ButtonContainer>
        </Container>
    </form>
    )
}
export default InvoiceSupplyForm



/*
  supplyId: z.string(),
  storageLocationId: z.string(),
  inventoryQuantity: z.number(),
  unitCost: z.number(),
  quantity: z.number(),
  measurementUnitId: z.string(),
  icms: z.number(),
  confins: z.number(),
  pis: z.number(),
  st: z.number(),
  ipi: z.number(),
  discountPercentage: z.number(),
  discountValue: z.number()
*/