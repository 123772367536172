import { useUi } from 'contexts/userInterface/UserInterfaceContext';
import { FormDeviceProfileStep } from 'modules/config/deviceConfig/domain/interface/FormDeviceProfileSteps';
import { useLocal } from 'modules/local/presentation/context/LocalContext';
import { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom';
import DeviceProfilesErpService from 'modules/meepErp/services/api/DeviceProfiles/DeviceProfilesService';
import { IGetDeviceProfilesErpResponse } from 'modules/meepErp/application/dtos/deviceProfiles/IDeviceProfiles';
import { IInventoryManagementParamsValues } from '../../components/deviceProfile/profileForms/inventoryManagementParamsForm/inventoryManagementParamsForm';

const UseErpDeviceProfiles = (path: string = "/private/pdv/perfil") => {
    const { push } = useHistory();
    const { showLoading, hideLoading } = useUi();
    const { currentLocal } = useLocal();
    const [deviceProfileErpDefault, setDeviceProfileErpDefault] = useState<IGetDeviceProfilesErpResponse>()
    const service = DeviceProfilesErpService ();

    const createDeviceProfilesErp  = useCallback(async (profileId: string, values: IInventoryManagementParamsValues) => {
        try {
            if (currentLocal) {
                showLoading()
                await service.createDeviceProfilesErp(currentLocal.id, profileId, values);;
                push(`${path}/editar/${profileId}/` + FormDeviceProfileStep.OTHER);
            } else {
                console.error("local não selecionado");
            }
        } finally {
            hideLoading()
        }
    }, [currentLocal, hideLoading, path, push, showLoading])

    const getDeviceProfilesErp  = useCallback(async (profileId: string) => {
        try {
            if (currentLocal) {
                showLoading()
                const response = await service.getDeviceProfilesErp(currentLocal.id, profileId);
                setDeviceProfileErpDefault(response)
            } else {
                console.error("local não selecionado");
            }
        } finally {
            hideLoading()
        }
    }, [currentLocal, hideLoading, path, push, showLoading])

    return ({
        createDeviceProfilesErp,
        getDeviceProfilesErp,
        deviceProfileErpDefault
    })
}


export default UseErpDeviceProfiles